import { DataleanDataProviderService } from '../provider/datalean-data-provider.service';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseEditorService } from './base-editor.service';
import { ParseEntity } from '../helpers/utilities';
import { User } from '../models';

// not an injectable because it is not meant to be reused
@Injectable()
export class UserEditorService extends BaseEditorService {
  authMethodUpdated = false;

  constructor(protected dataleanDataService: DataleanDataProviderService) {
    super(dataleanDataService);
  }

  init() {
    this.objectData = {
      activationDate: null,
      authenticationMethods: null,
      avatarImageURL: '',
      communityUUIDList: [],
      email: '',
      firstName: '',
      groups: [],
      isPrivate: false,
      lastName: '',
      organization: null,
      resetPasswordToken: null,
      responsible: [],
      roles: [],
      score: [],
      status: 'ENABLED',
      systemUser: false,
      username: '',
    };
    this.objectData.uuid = this.generateUUID();
  }

  create(): Observable<any> {
    if (!this.objectData.values) {
      delete this.objectData.values;
    }
    this.objectData = ParseEntity(new User(), this.objectData);
    return this.dataleanDataService.createEntity(environment.usersUrl, this.objectData, [] /*parts*/);
  }

  update(): Observable<any> {
    if (!this.objectData.values) {
      delete this.objectData.values;
    }
    if (!this.authMethodUpdated) {
      delete this.objectData.authenticationMethods;
    }
    delete this.objectData.reports;
    this.objectData = ParseEntity(new User(), this.objectData);
    return this.dataleanDataService.updateEntity(environment.usersUrl, this.objectData, [] /* parts */);
  }
}
