import { Action, createReducer, on } from '@ngrx/store';
import { OrderTypeEnum } from 'src/app/enums/order-type.enum';
import { AuthActions, OrdersActions } from 'src/app/store/app.actions';

export const TAG = 'orders';

export interface FiltersType {
  orderCommunityUUID: string;
}

export interface TabType {
  label: string;
  filtersValue: {
    orderType: OrderTypeEnum;
    orderUserValueFilter?: string;
  };
  badge: number;
  index: number;
}

export interface OrdersState {
  data: any;
  page: any;
  sort: any;
  filters: FiltersType;
  searchInfo: any;
  tab: TabType;
}

const initialState: OrdersState = {
  data: undefined,
  sort: {
    active: 'incrementalNumber',
    direction: 'desc',
  },
  page: { pageIndex: 0, pageSize: 25, length: undefined },
  filters: {
    orderCommunityUUID: undefined,
  },
  searchInfo: {
    searchFields: 'role.firstName|role.lastName',
    searchFieldsParamName: 'searchFields',
    searchValueParamName: 'q',
  },
  tab: {
    label: 'PAGES.ORDERS.TABS.TABLE',
    filtersValue: {
      orderUserValueFilter: 'table',
      orderType: OrderTypeEnum.STANDARD,
    },
    badge: 0,
    index: 0,
  },
};

const reducer = createReducer(
  initialState,
  on(OrdersActions.setData, (state: OrdersState, { data }) => ({
    ...state,
    data,
  })),
  on(OrdersActions.setPage, (state: OrdersState, { page }) => ({
    ...state,
    page,
  })),
  on(OrdersActions.setSort, (state: OrdersState, { sort }) => ({
    ...state,
    sort,
  })),
  on(OrdersActions.setFilters, (state: OrdersState, { filters }) => ({
    ...state,
    filters: {
      ...filters,
      orderCommunityUUID: (filters as any).communityEntityUUID,
    },
  })),
  on(OrdersActions.setSearchInfo, (state: OrdersState, { searchInfo }) => ({
    ...state,
    searchInfo,
  })),
  on(OrdersActions.setTab, (state: OrdersState, { tab }) => ({
    ...state,
    tab,
  })),
  on(AuthActions.logout, () => ({ ...initialState }))
);

export function ordersReducer(state: OrdersState, action: Action) {
  return reducer(state, action);
}
