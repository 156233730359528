import { Injectable } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Parts } from '../enums/parts.enum';
import { StructureField } from '../interfaces/structure-field';
import { Structure } from '../models';
import { DataleanDataProviderService } from '../provider/datalean-data-provider.service';
import { LocalizationService } from './localization.service';
import { StructureEditorService } from './structure-editor.service';

// not an injectable because it is not meant to be reused
@Injectable()
export class UserStructureEditorService extends StructureEditorService {
  objectData: StructureField | Structure = new Structure({});

  constructor(
    protected dataleanDataService: DataleanDataProviderService,
    protected translatePipe: TranslatePipe,
    protected localizationService: LocalizationService
  ) {
    super(dataleanDataService, translatePipe, localizationService);
  }

  init(): void {
    super.init();
    delete this.objectData.type;
    delete this.objectData.desktopRatio;
    delete this.objectData.tabletRatio;
    delete this.objectData.mobileRatio;
  }

  getStructures() {
    return this.dataleanDataService.getAllEntities<Structure>(environment.userStructureUrl, [Parts.STRUCTURE_FIELDS]);
  }

  private removeIdField(structure) {
    delete structure.id;
    if (structure.structureFields) {
      structure.structureFields?.forEach((sf) => {
        this.removeIdField(sf);
      });
    } else {
      structure.fields?.forEach((sf) => {
        this.removeIdField(sf);
      });
    }
  }

  create(): Observable<any> {
    this.removeIdField(this.objectData);
    return this.dataleanDataService.createEntity(environment.userStructureUrl, this.objectData, []);
  }

  createData(data): Observable<any> {
    this.removeIdField(this.objectData);
    return this.dataleanDataService.createEntity(environment.userStructureUrl, data, [Parts.STRUCTURE_FIELDS]);
  }

  update(data): Observable<any> {
    delete data.type;
    delete data.version;
    this.removeIdField(data);
    return this.dataleanDataService.updateEntity(environment.userStructureUrl, data, [Parts.STRUCTURE_FIELDS]);
  }

  delete(uuid): Observable<any> {
    return this.dataleanDataService.deleteEntity(environment.userStructureUrl, uuid);
  }
}
