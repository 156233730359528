export class CityBuilder {

  constructor(public name: string, public cap: string[], public region: string) {
  }

  build() {
    return new City(this);
  }
}

export class City {
  static readonly NAME_FIELD_KEY = 'name';
  static readonly CAP_FIELD_KEY = 'cap';
  static readonly REGION_FIELD_KEY = 'cap';

  public name: string;
  public cap: string[];
  public region: string;

  constructor(cityBuilder: CityBuilder) {

    this.name = cityBuilder.name;
    this.cap = cityBuilder.cap;
    this.region = cityBuilder.region;
  }
}
