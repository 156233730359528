export interface Plan2D {
  x: number;
  y: number;
}

export interface Plan3D {
  x: number;
  y: number;
  z: number;
}

export class MapArea {
  shape: string;
  coords: Plan2D;
  id: string;
  extra: any;

  constructor(id: string, shape: string, coords: Plan2D) {
    this.id = id;
    this.shape = shape;
    this.coords = coords;
  }
}

export class Position3D extends MapArea {
  coords: Plan3D = { x: 0, y: 0, z: 0 };
  rotation: Plan3D;

  constructor(id: string, shape: string, coords: Plan3D, rotation: Plan3D) {
    super(id, shape, coords);
    this.rotation = rotation;
  }
}
