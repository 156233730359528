export class SearchInfo {
  query = '';
  searchFields = '';
  searchFieldsParamName = 'searchFields';
  searchValueParamName = 'q';

  constructor(searchFields: string, query: string, searchFieldsParamName?: string, searchValueParamName?: string) {
    this.query = query;
    this.searchFields = searchFields;
    if (searchFieldsParamName) {
      this.searchFieldsParamName = searchFieldsParamName;
    }
    if (searchValueParamName) {
      this.searchValueParamName = searchValueParamName;
    }
  }

}
