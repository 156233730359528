import { EntityStatuses } from '../enums/entity-statuses.enum';
import { Role } from './Role';

export class User {
  uuid = '';
  firstName = '';
  lastName = '';
  username = '';
  email = '';
  roles: Role[] = [];
  status: EntityStatuses = EntityStatuses.ENABLED;
  responsible = [];
  systemUser = false;
  id?: string;
  language?: string;
  smartCoUser?: boolean;
  activationDate?: Date = undefined;
  authenticationMethods?: [] = undefined;
  communityUUIDList?: [];
  groups?: [];
  isPrivate?: boolean;
  organization?: string;
  resetPasswordToken?: string = '';
  score?: [];

  hasPermission(searchPermission: string) {
    return (
      this.roles?.filter((role) => role?.permissions?.filter((permission) => searchPermission == permission.name).length > 0).length > 0
    );
  }
}
export class UserRecipient {
  public opened = false;
  public read = false;
  public liked = false;
  public uuid = '';
  public responsible: Array<UserResponsabile> = [];
}
export class UserResponsabile {
  public openedResp = false;
  public readResp = false;
  public likedResp = false;
  public uuid = '';
}
