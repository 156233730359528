import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { Action, createReducer, on } from '@ngrx/store';
import { FiltersType, FiltersVisibility, StructureType } from '../../../enums';
import { FeatureValue, SearchInfo, Structure } from '../../../models';
import { AuthActions, ProductsActions } from '../../../store/app.actions';

export const TAG = 'products';
export const WISHLIST_TAG = 'wishlist';

export interface ProductsState {
  filters: FiltersType;
  sort: Sort;
  page: PageEvent;
  extFilt: any;
  searchInfo: SearchInfo;
  data: any;
  customizationData: any;
  features: Array<FeatureValue>;
  structures: Array<Structure>;
  gridView: boolean;
  bulkData: any;
  bulkAction: any;
  multipleData: any;
  bulkDataCustomization: any;
  bulkActionCustomization: any;
  multipleDataCustomization: any;
  tab: any;
  activeTabIndex: number;
}

export interface WishlistState {
  filters: FiltersType;
  sort: Sort;
  page: PageEvent;
  searchInfo: SearchInfo;
  structures: Array<Structure>;
  data: any;
  bulkData: any;
  bulkAction: any;
  multipleData: any;
}

const defaultFilters = {
  'structure.type': { visibility: FiltersVisibility.HIDDEN, value: StructureType.PRODUCT },
  structureUUID: { visibility: FiltersVisibility.VISIBLE, value: undefined },
  featureValue: { visibility: FiltersVisibility.VISIBLE, value: undefined },
  communityUUID: { visibility: FiltersVisibility.VISIBLE, value: undefined },
  communityAdminUUID: { visibility: FiltersVisibility.HIDDEN, value: undefined },
  template: { visibility: FiltersVisibility.HIDDEN, value: undefined },
  active: { visibility: FiltersVisibility.VISIBLE, value: undefined },
};

const initialState: ProductsState = {
  activeTabIndex: 0,
  filters: structuredClone(defaultFilters),
  sort: { active: 'updateDate', direction: 'desc' },
  page: { pageIndex: 0, pageSize: 25, length: undefined },
  extFilt: undefined,
  searchInfo: {
    searchFields: 'name',
    searchFieldsParamName: 'searchFields',
    searchValueParamName: 'q',
  } as SearchInfo,
  data: undefined,
  customizationData: undefined,
  features: undefined,
  structures: undefined,
  gridView: false,
  bulkData: undefined,
  bulkAction: undefined,
  multipleData: undefined,
  tab: undefined,
  bulkDataCustomization: undefined,
  bulkActionCustomization: undefined,
  multipleDataCustomization: undefined,
};
const defaultFiltersWL = {
  'structure.type': { visibility: FiltersVisibility.HIDDEN, value: StructureType.WISHLIST },
  structureUUID: { visibility: FiltersVisibility.VISIBLE, value: undefined },
  featureValue: { visibility: FiltersVisibility.VISIBLE, value: undefined },
  communityUUID: { visibility: FiltersVisibility.VISIBLE, value: undefined },
  communityAdminUUID: { visibility: FiltersVisibility.HIDDEN, value: undefined },
  template: { visibility: FiltersVisibility.HIDDEN, value: undefined },
  active: { visibility: FiltersVisibility.VISIBLE, value: undefined },
};
const wishlistInitialState: WishlistState = {
  filters: structuredClone(defaultFiltersWL),
  sort: { active: 'updateDate', direction: 'desc' },
  page: { pageIndex: 0, pageSize: 25, length: undefined },
  searchInfo: {
    searchFields: 'name',
    searchFieldsParamName: 'searchFields',
    searchValueParamName: 'q',
  } as SearchInfo,
  structures: undefined,
  data: undefined,
  bulkData: undefined,
  bulkAction: undefined,
  multipleData: undefined
};

const _counterReducer = createReducer(
  initialState,
  on(ProductsActions.setFilters, (state: ProductsState, { filters }) => ({
    ...state,
    filters,
  })),
  on(ProductsActions.setFiltersValues, (state: ProductsState, { filters }) => ({
    ...state,
    filters: Object.entries(filters).reduce((acc, [key, value]) => {
      if (typeof acc[key] === 'object') {
        acc[key] = { ...acc[key], value } as FiltersType;
      } else {
        acc[key] = { value, visibility: defaultFilters?.[key]?.visibility ?? FiltersVisibility.VISIBLE };
      }
      return acc;
    }, structuredClone<FiltersType>(state.filters)),
  })),
  on(ProductsActions.setSort, (state: ProductsState, { sort }) => ({
    ...state,
    sort,
  })),
  on(ProductsActions.setPage, (state: ProductsState, { page }) => ({
    ...state,
    page,
  })),
  on(ProductsActions.setExtFilt, (state: ProductsState, { extFilt }) => ({
    ...state,
    extFilt,
  })),
  on(ProductsActions.updateExtFilt, (state: ProductsState, { extFilt }) => ({
    ...state,
    extFilt: { ...extFilt },
  })),
  on(ProductsActions.setSearchInfo, (state: ProductsState, { searchInfo }) => ({
    ...state,
    searchInfo,
  })),
  on(ProductsActions.setData, (state: ProductsState, { data }) => ({
    ...state,
    data,
  })),
  on(ProductsActions.setCustomizationData, (state: ProductsState, { customizationData }) => ({
    ...state,
    customizationData,
  })),
  on(ProductsActions.setFeatures, (state: ProductsState, { features }) => ({
    ...state,
    features,
  })),
  on(ProductsActions.setProductStructures, (state: ProductsState, { structures }) => ({
    ...state,
    structures,
  })),
  on(ProductsActions.setGridView, (state: ProductsState, { gridView }) => ({
    ...state,
    gridView,
  })),
  on(ProductsActions.setProductsBulkAction, (state: ProductsState, { bulkAction }) => ({
    ...state,
    bulkAction,
  })),
  on(ProductsActions.setProductsUUIDListBulk, (state: ProductsState, { bulkData }) => ({
    ...state,
    bulkData,
  })),
  on(ProductsActions.setMultipleUpdateResult, (state: ProductsState, { multipleData }) => ({
    ...state,
    multipleData,
  })),
  on(ProductsActions.setBulkActionCustomization, (state: ProductsState, { bulkActionCustomization }) => ({
    ...state,
    bulkActionCustomization,
  })),
  on(ProductsActions.setUUIDListBulkCustomization, (state: ProductsState, { bulkDataCustomization }) => ({
    ...state,
    bulkDataCustomization,
  })),
  on(ProductsActions.setMultipleUpdateResultCustomization, (state: ProductsState, { multipleDataCustomization }) => ({
    ...state,
    multipleDataCustomization,
  })),
  on(ProductsActions.setTab, (state: ProductsState, { tab }) => ({
    ...state,
    tab,
  })),
  on(ProductsActions.updateTabIndex, (state: ProductsState, { activeTabIndex }) => ({
    ...state,
    activeTabIndex,
  })),
  on(AuthActions.logout, () => ({ ...initialState }))
);

const _wishlistReducer = createReducer(
  wishlistInitialState,
  on(ProductsActions.setWishlistFilters, (state: WishlistState, { filters }) => ({
    ...state,
    filters: Object.entries(filters).reduce((acc, [key, value]) => {
        if (typeof acc[key] === 'object') {
          acc[key] = { ...acc[key], value } as FiltersType;
        } else {
          acc[key] = { value, visibility: defaultFilters?.[key]?.visibility ?? FiltersVisibility.VISIBLE };
        }
      return acc;
    }, structuredClone<FiltersType>(state.filters)),
  })),
  on(ProductsActions.setWishlistStructures, (state: WishlistState, { structures }) => ({
    ...state,
    structures,
  })),
  on(ProductsActions.setWishlistSort, (state: WishlistState, { sort }) => ({
    ...state,
    sort,
  })),
  on(ProductsActions.setWishlistPage, (state: WishlistState, { page }) => ({
    ...state,
    page,
  })),
  on(ProductsActions.setWishlistSearchInfo, (state: WishlistState, { searchInfo }) => ({
    ...state,
    searchInfo,
  })),
  on(ProductsActions.setWishlistData, (state: WishlistState, { data }) => ({
    ...state,
    data,
    //    page: { ...state.page, length: data.resultInfo.totalNumberOfElements }
  })),
  on(ProductsActions.setWishListsBulkAction, (state: WishlistState, { bulkAction }) => ({
    ...state,
    bulkAction,
  })),
  on(ProductsActions.setWishUUIDListBulk, (state: WishlistState, { bulkData }) => ({
    ...state,
    bulkData,
  })),
  on(AuthActions.logout, () => ({ ...wishlistInitialState }))
);

export function productsReducer(state: ProductsState, action: Action) {
  return _counterReducer(state, action);
}
export const wishlistReducer = (state: WishlistState, action: Action) => {
  return _wishlistReducer(state, action);
};
