export class GStorageNode {
  static readonly UUID_FIELD_KEY = 'uuid';
  static readonly NAME_FIELD_KEY = 'name';
  static readonly CHILDREN_KEY = 'children';
  static readonly BLOB_ID_KEY = 'blobId';
  static readonly ENTITY_TYPE = 'entityType';

  constructor(
    public uuid: string,
    public name: string,
    public children: GStorageNode,
    public blobid: string,
    public entityType?: string,
    public mimeType?: string,
    public fullPath?: string
  ) {}
}
