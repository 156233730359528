import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { Action, createReducer, on } from '@ngrx/store';
import { StructureType } from 'src/app/enums/structure-type.enum';
import { FeatureValue } from 'src/app/models/FeatureValue';
import { SearchInfo } from 'src/app/models/search-info';
import { Structure } from 'src/app/models/Structure';
import { AuthActions, BookableResourcesActions } from 'src/app/store/app.actions';

export const TAG = 'bookableResources';

export interface FiltersType {
  structureType: StructureType;
  structureUUID: string;
  featureValueUUID: string;
  communityEntityUUID: string;
  communityAdminUUID?: string;
  template: string;
  active: boolean;
}

export interface BookableResourcesState {
  filters: FiltersType;
  sort: Sort;
  page: PageEvent;
  extFilt: any;
  searchInfo: SearchInfo;
  data: any;
  customizationData: any;
  features: Array<FeatureValue>;
  structures: Array<Structure>;
  gridView: boolean;
}

const initialState: BookableResourcesState = {
  filters: {
    structureType: StructureType.BOOKING,
    structureUUID: '',
    featureValueUUID: undefined,
    communityEntityUUID: undefined,
    communityAdminUUID: undefined,
    template: undefined,
    active: true,
  },
  sort: { active: 'updateDate', direction: 'desc' },
  page: { pageIndex: 0, pageSize: 25, length: undefined },
  extFilt: undefined,
  searchInfo: {
    searchFields: 'name',
    searchFieldsParamName: 'searchFields',
    searchValueParamName: 'q',
  } as SearchInfo,
  data: undefined,
  customizationData: undefined,
  features: [],
  structures: [],
  gridView: false,
};

const _reducer = createReducer(
  initialState,
  on(BookableResourcesActions.setFilters, (state: BookableResourcesState, { filters }) => ({
    ...state,
    filters,
  })),
  on(BookableResourcesActions.setSort, (state: BookableResourcesState, { sort }) => ({
    ...state,
    sort,
  })),
  on(BookableResourcesActions.setPage, (state: BookableResourcesState, { page }) => ({
    ...state,
    page,
  })),
  on(BookableResourcesActions.setExtFilt, (state: BookableResourcesState, { extFilt }) => ({
    ...state,
    extFilt,
  })),
  on(BookableResourcesActions.updateExtFilt, (state: BookableResourcesState, { extFilt }) => ({
    ...state,
    extFilt: { ...extFilt },
  })),
  on(BookableResourcesActions.setSearchInfo, (state: BookableResourcesState, { searchInfo }) => ({
    ...state,
    searchInfo,
  })),
  on(BookableResourcesActions.setData, (state: BookableResourcesState, { data }) => ({
    ...state,
    data,
  })),
  on(BookableResourcesActions.setCustomizationData, (state: BookableResourcesState, { customizationData }) => ({
    ...state,
    customizationData,
  })),
  on(BookableResourcesActions.setFeatures, (state: BookableResourcesState, { features }) => ({
    ...state,
    features,
  })),
  on(BookableResourcesActions.setStructures, (state: BookableResourcesState, { structures }) => ({
    ...state,
    structures,
  })),
  on(BookableResourcesActions.setGridView, (state: BookableResourcesState, { gridView }) => ({
    ...state,
    gridView,
  })),
  on(AuthActions.logout, () => ({ ...initialState }))
);

export function bookableResourcesReducer(state: BookableResourcesState, action: Action) {
  return _reducer(state, action);
}
