import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot, UrlTree} from '@angular/router';
import { Observable, Subject } from 'rxjs';
import {MessageComponent} from "../components/modal/message/message.component";
import {Message} from "../models/Message";
import {ModalService} from "../components/modal/modal.service";
import {GlobalPositionStrategy} from "@angular/cdk/overlay";


export interface ComponentCanDeactivate {
  canDeactivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree;
}

export const CanDeactivateState = {
  defendAgainstBrowserBackButton: false,
};

@Injectable({
  providedIn: 'root'
})
export class CanDeactivateGuard implements CanDeactivate<ComponentCanDeactivate> {

  message: Message;
  constructor(protected modalService: ModalService,
  ) {
    this.message = new Message('MODALS.MESSAGE.PAGE_LEAVE_WITHOUT_SAVE.TITLE', 'MODALS.MESSAGE.PAGE_LEAVE_WITHOUT_SAVE.MESSAGE');
  }

  openMessageModal() {
    return this.modalService.open({
      component: MessageComponent,
      class: 'message-modal',
      position: new GlobalPositionStrategy().centerHorizontally().top(),
      data: {message: this.message.message, status: this.message.status, code: this.message.code, title: this.message.title}
    });
  }

  canDeactivate(component: ComponentCanDeactivate,
                next: ActivatedRouteSnapshot,
                state: RouterStateSnapshot,
                nextState: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (component.canDeactivate()) {
      return true;
    }

    const canLeaveSubject = new Subject<boolean>();
    this.openMessageModal().onDidClose.subscribe(result => {
      if (result.data === 'ok') {
        canLeaveSubject.next(true);
      }
      else canLeaveSubject.next(false);
    });
    return canLeaveSubject;
  }
}
