import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { Action, createReducer, on } from '@ngrx/store';
import { FiltersType, FiltersVisibility, StructureType } from 'src/app/enums';
import DeepCopy from 'src/app/helpers/deep_copy';
import { FeatureValue, SearchInfo, Structure } from 'src/app/models';
import { BulkActions } from 'src/app/models/Bulk';
import { Content } from 'src/app/models/Content';
import { AuthActions, ContentsActions } from 'src/app/store/app.actions';

export const TAG = 'contents';

export interface ContentsState {
  filters: FiltersType;
  sort: Sort;
  page: PageEvent;
  searchInfo: SearchInfo;
  data: {
    result: Content[] | { [key: string]: Content };
    paginationInfo?: PageEvent;
  };
  features: Array<FeatureValue>;
  structures: Array<Structure>;
  bulkAction: {
    bulkActionCustomization?: BulkActions;
    bulkAction?: BulkActions;
  };
  bulkData: any;
}

const defaultFilters = {
  'structure.type': { visibility: FiltersVisibility.HIDDEN, value: StructureType.CONTENT },
  structureUUID: { visibility: FiltersVisibility.VISIBLE, value: undefined },
  featureValue: { visibility: FiltersVisibility.HIDDEN, value: undefined },
  communityUUID: { visibility: FiltersVisibility.VISIBLE, value: undefined },
  communityAdminUUID: { visibility: FiltersVisibility.HIDDEN, value: undefined },
  template: { visibility: FiltersVisibility.HIDDEN, value: undefined },
  active: { visibility: FiltersVisibility.VISIBLE, value: undefined },
};

const initialState: ContentsState = {
  filters: DeepCopy(defaultFilters),
  sort: { active: 'updateDate', direction: 'desc' },
  page: { pageIndex: 0, pageSize: 25, length: undefined },
  searchInfo: {
    searchFields: 'name',
    searchFieldsParamName: 'searchFields',
    searchValueParamName: 'q',
  } as SearchInfo,
  data: undefined,
  features: undefined,
  structures: undefined,
  bulkAction: undefined,
  bulkData: undefined,
};

const _contestReducer = createReducer(
  initialState,
  on(ContentsActions.setFilters, (state: ContentsState, { filters }) => ({
    ...state,
    filters,
  })),
  on(ContentsActions.setFiltersValues, (state: ContentsState, { filters }) => ({
    ...state,
    filters: Object.entries(filters).reduce((acc, [key, value]) => {
      if (typeof acc[key] === 'object') {
        acc[key] = { ...acc[key], value } as FiltersType;
      } else {
        acc[key] = { value, visibility: defaultFilters?.[key]?.visibility ?? FiltersVisibility.VISIBLE };
      }
      return acc;
    }, DeepCopy<FiltersType>(state.filters)),
  })),
  on(ContentsActions.setSort, (state: ContentsState, { sort }) => ({
    ...state,
    sort,
  })),
  on(ContentsActions.setPage, (state: ContentsState, { page }) => ({
    ...state,
    page,
  })),
  on(ContentsActions.setSearchInfo, (state: ContentsState, { searchInfo }) => ({
    ...state,
    searchInfo,
  })),
  on(ContentsActions.setData, (state: ContentsState, { data }) => ({
    ...state,
    data,
  })),
  on(ContentsActions.setFeatures, (state: ContentsState, { features }) => ({
    ...state,
    features,
  })),
  on(ContentsActions.setContentsStructures, (state: ContentsState, { structures }) => ({
    ...state,
    structures,
  })),
  on(ContentsActions.setContentsBulkAction, (state: ContentsState, { bulkAction }) => ({
    ...state,
    bulkAction,
  })),
  on(ContentsActions.setContentsUUIDListBulk, (state: ContentsState, { bulkData }) => ({
    ...state,
    bulkData,
  })),
  on(AuthActions.logout, () => ({ ...initialState }))
);

export function contentsReducer(state: ContentsState, action: Action) {
  return _contestReducer(state, action);
}
