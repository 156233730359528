import { map, delay } from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable, of} from "rxjs";
import {Province, ProvinceBuilder} from "../models/Province";
import {City, CityBuilder} from "../models/City";

@Injectable({
  providedIn: 'root'
})
export class CivilService {

  private _jsonURL = 'assets/select-jsons/comuni.json';
  private provinces : Province[] = [];

  constructor(private http: HttpClient) {
  }

  init(): Observable<Province[]>{
    if(this.provinces.length > 0) {
      return of(this.ProvinceList).pipe(delay(250));
    } else {
      return this.mapProvinces();
    }
  }

  get ProvinceList() {
    return this.provinces;
  }

  getJSON(): Observable<any> {
    return this.http.get(this._jsonURL);
  }

  private mapProvinces(): Observable<Province[]>{
    return this.getJSON().pipe(map(data => {
      data.filter(cities => {
        const city = new CityBuilder(cities.nome,cities.cap,cities.regione.nome)
          .build();
        const province = new ProvinceBuilder(cities.provincia.nome, cities.sigla).build();
        this.addCityToProvince(city,province);
      })
      this.provinces = this.provinces.sort((a, b) => {
        // Use toUpperCase() to ignore character casing
        const bandA = a.Name.toUpperCase();
        const bandB = b.Name.toUpperCase();

        let comparison = 0;
        if (bandA > bandB) {
          comparison = 1;
        } else if (bandA < bandB) {
          comparison = -1;
        }
        return comparison;
      });
      return this.provinces
    }));

  }

  private addCityToProvince(city:City, provinceParam: Province) {
    const provinceFound = this.provinces.filter(province => province.Name == provinceParam.Name);
    if(!provinceFound || provinceFound.length == 0) {
      provinceParam.addCity(city);
      this.provinces.push(provinceParam);
    }
    else provinceFound[0].addCity(city);
  }

}
