import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { createAction, props } from '@ngrx/store';
import { SearchInfo } from 'src/app/models/search-info';
import { FiltersType } from './app-groups.reducer';

export const setAppGroupsFilters = createAction('[App Groups] set Filters', props<{ filters: FiltersType }>());
export const setAppGroupsSort = createAction('[App Groups] set Sort', props<{ sort: Sort }>());
export const setAppGroupsPage = createAction('[App Groups] set Page', props<{ page: PageEvent }>());
export const setAppGroupsSearchInfo = createAction('[App Groups] set Search Info', props<{ searchInfo: SearchInfo }>());
export const setAppGroupsData = createAction('[App Groups] set Data', props<{ data: any }>());
