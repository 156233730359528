import { Asset } from './Asset';
import { DataleanEntity } from './DataLeanEntity';
import { User } from './User';

export class Community extends DataleanEntity {
  constructor(
    public name?: string,
    public category?: string,
    public logo: string | Asset = '',
    public listAdmin: User[] = [],
    public code: string = '',
    public values: CommunityValue[] | string = [],
    public members: CommunityBaseUserInfo[] = [],
    public audit?: any, //usato lato FE
  ) {
    super()
  }
}

export class CommunityBaseUserInfo extends DataleanEntity {
  constructor(public firstName = '', public lastName = '') {
    super()
  }
}

export class CommunityValue extends DataleanEntity {

  constructor(
    public name : string = '',
    public value : string = ''
  ) {
      super();
  }
}
