import { DataleanDataProviderService } from '../provider/datalean-data-provider.service';
import { environment } from 'src/environments/environment';
import { Parts } from '../enums/parts.enum';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { BaseEditorService } from './base-editor.service';
import { LocalizationService } from './localization.service';
import { DateTimeType, TextType } from '../interfaces/survery-question';
import { SurveyQuestionType } from '../enums/survey-question-type.enum';
import { Survey } from '../interfaces/survey';
import { LocalizableField } from '../models/LocalizableField';
import { v4 as uuid } from 'uuid';
import { ParseEntity } from '../helpers/utilities';


// not singleton because it is not meant to be reused
@Injectable()
export class SurveyEditorService extends BaseEditorService {
  objectData: Survey = new Survey();
  surveyLanguages = [];
  supportsLanguages = true;

  constructor(protected dataleanDataService: DataleanDataProviderService, private localizationService: LocalizationService) {
    super(dataleanDataService);
  }

  init(data) {
    if (data) {
      this.objectData = data;
      this.objectData.name = new LocalizableField(this.objectData.name);
    } else {
      const lan = this.localizationService.getActiveEntityLanguage();
      this.objectData = new Survey(
        LocalizableField.createWithLocalizedValue({ language: lan, value: '' }),
        uuid(),
      );
    }
  }

  create(): Observable<any> {
    if (this.checkSurveyValidity()) {
      const surveyCleaned = this.clearSurvey();
      return this.dataleanDataService.createEntity(environment.surveyUrl, surveyCleaned, [Parts.ALL]);
    } else {
      return throwError('');
    }
  }

  private checkSurveyValidity() {
    let surveyIsValid = true;
    for (const question of this.objectData.questionList) {
      if (!question.text || !question.domainOfAnswers || !question.type || !question.surveyCode) {
        surveyIsValid = false;
      }
      switch (question.type) {
        case SurveyQuestionType.dateTime:
          if (!(question.dateTimeType in DateTimeType)) {
            surveyIsValid = false;
          }
          break;
        case SurveyQuestionType.multipleChoice:
        case SurveyQuestionType.singleChoice:
          if (!(question.domainOfAnswers?.length > 0)) {
            surveyIsValid = false;
          } else {
            question.domainOfAnswers?.forEach((answer) => {
              if (!answer.id || !answer.text) {
                surveyIsValid = false;
              }
            });
          }
          break;
        case SurveyQuestionType.ratingScale:
          if (!(question.domainOfAnswers?.length > 0)) {
            surveyIsValid = false;
          }
          break;
        // case SurveyQuestionType.file:
        //   break;
        case SurveyQuestionType.text:
          if (!Object.values(TextType).includes(question.textType)) {
            surveyIsValid = false;
          }
          break;

        default:
          break;
      }
      if (!surveyIsValid) {
        break;
      }
    }
    return surveyIsValid;
  }

  update(): Observable<any> {
    if (this.checkSurveyValidity()) {
      const surveyCleaned = this.clearSurvey();
      return this.dataleanDataService.updateEntity(environment.surveyUrl, surveyCleaned, []);
    } else {
      return throwError('');
    }
  }

  prepareToCloneSurvey() {
    delete this.objectData.uuid;
    delete this.objectData['_id'];
    this.objectData.questionList.forEach((question) => {
      delete question.uuid;
    });
  }

  updateQuestionOrder() {
    this.objectData.questionList.forEach((question, index) => {
      question.order = index;
    });
  }

  /**
   * metodo che elimina i campi in eccesso del survey
   * deve essere richiamato prima di inviare il dato a BE
   * così da passare il dato che il BE si aspetta di ricevere
   *
   * @returns Ritorna una copia del survey parsato
   */
  clearSurvey() : Survey {
    //ParseEntity mappa l'oggetto passato (2° parametro) in una istanza del tipo indicato (1° parametro)
    return ParseEntity(new Survey(), this.objectData);
  }
}
