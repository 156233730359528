import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { Action, createReducer, on } from '@ngrx/store';
import { StructureType } from 'src/app/enums/structure-type.enum';
import { SearchInfo } from 'src/app/models/search-info';
import {AuthActions, BookableProductsActions, ResourcesActions} from 'src/app/store/app.actions';
import {Structure} from "../../../../../models";
import {ResourcesState} from "../../resources/store/resources.reducer";

export const TAG = 'bookableProducts';

export interface FiltersType {
  structureType: StructureType;
  structureUUID: string;
  featureValueUUID: string;
  communityEntityUUID: string;
  communityAdminUUID?: string;
  template: string;
  active: boolean;
}

export interface BookableProductsState {
  filters: FiltersType;
  sort: Sort;
  page: PageEvent;
  extFilt: any;
  searchInfo: SearchInfo;
  data: any;
  customizationData: any;
  structures: Array<Structure>;
  gridView: boolean;
}

const initialState: BookableProductsState = {
  filters: {
    structureType: StructureType.BOOKABLE_PRODUCT,
    structureUUID: undefined,
    featureValueUUID: undefined,
    communityEntityUUID: undefined,
    communityAdminUUID: undefined,
    template: undefined,
    active: true
  },
  sort: { active: 'updateDate', direction: 'desc' },
  page: { pageIndex: 0, pageSize: 25, length: undefined },
  extFilt: undefined,
  searchInfo: {
    searchFields: 'name',
    searchFieldsParamName: 'searchFields',
    searchValueParamName: 'q'
  } as SearchInfo,
  data: undefined,
  customizationData: undefined,
  structures: [],
  gridView: false
};

const _reducer = createReducer(
  initialState,
  on(BookableProductsActions.setFilters, (state: BookableProductsState, { filters }) => ({
    ...state,
    filters
  })),
  on(BookableProductsActions.setSort, (state: BookableProductsState, { sort }) => ({
    ...state,
    sort
  })),
  on(BookableProductsActions.setPage, (state: BookableProductsState, { page }) => ({
    ...state,
    page
  })),
  on(BookableProductsActions.setExtFilt, (state: BookableProductsState, { extFilt }) => ({
    ...state,
    extFilt
  })),
  on(BookableProductsActions.updateExtFilt, (state: BookableProductsState, { extFilt }) => ({
    ...state,
    extFilt: { ...extFilt }
  })),
  on(BookableProductsActions.setSearchInfo, (state: BookableProductsState, { searchInfo }) => ({
    ...state,
    searchInfo
  })),
  on(BookableProductsActions.setData, (state: BookableProductsState, { data }) => ({
    ...state,
    data
  })),
  on(BookableProductsActions.setStructures, (state: BookableProductsState, { structures }) => ({
    ...state,
    structures,
  })),
  on(AuthActions.logout, () => ({ ...initialState }))
);

export function bookableProductsReducer(state: BookableProductsState, action: Action) {
  return _reducer(state, action);
}
