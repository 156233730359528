import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { createAction, props } from '@ngrx/store';
import { SearchInfo } from 'src/app/models/search-info';
import { FiltersType } from './tables.reducer';

export const setFilters = createAction('[Tables] Set Filters', props<{ filters: FiltersType }>());
export const setSort = createAction('[Tables] Set Sort', props<{ sort: Sort }>());
export const setPage = createAction('[Tables] Set Page', props<{ page: PageEvent }>());
export const setSearchInfo = createAction('[Tables] Set Search Info', props<{ searchInfo: SearchInfo }>());
export const setData = createAction('[Tables] Set Data', props<{ data: any }>());
export const setTab = createAction('[Tables] set tab', props<{ tab: any }>());
