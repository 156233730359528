import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'toStandardBoolean',
})
export class ToStandardBooleanPipe implements PipeTransform {
  transform(valueStr: any) {
    switch (valueStr) {
      case 'true':
      case 'yes':
      case '1':
      case 'on':
      case true:
        return true;
      case 'false':
      case 'no':
      case '0':
      case 'off':
      case '':
      case undefined:
      case null:
      case false:
        return false;
      default:
        return valueStr === 'true';
    }
  }
}
