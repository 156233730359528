import { createFeatureSelector, createSelector } from '@ngrx/store';
import { TablesReducers } from 'src/app/store/app.reducers';

const getTablesState = createFeatureSelector<TablesReducers.TablesState>(TablesReducers.TAG);

export const getTablesFilters = createSelector(getTablesState, (state) => state?.filters);
export const getTablesSearchInfo = createSelector(getTablesState, (state) => state?.searchInfo);
export const getTablesSort = createSelector(getTablesState, (state) => state?.sort);
export const getTablesPage = createSelector(getTablesState, (state) => state?.page);
export const getTablesData = createSelector(getTablesState, (state) => state?.data);
export const getTab = createSelector(getTablesState, (state) => state?.tab);
