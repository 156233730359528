import { AuthConfig, OAuthModuleConfig } from 'angular-oauth2-oidc';
import { environment } from 'src/environments/environment';

export const authConfig: AuthConfig = {
  oidc: true,
  issuer: environment.authenticationUrl,
  customQueryParams: {
    organizationUUID: environment.organizationUUID,
    userType: 'User',
    prompt: 'login',
  },
  requireHttps: environment.production,
  clientId: 'dataleanFeIntegration', // The "Auth Code + PKCE" client
  responseType: 'code',
  redirectUri: window.location.origin,
  logoutUrl: window.location.origin,
  scope: 'openid profile email offline_access', // Ask offline_access to support refresh token refreshes
  timeoutFactor: 0.75, // decrease this value for faster testing
  sessionChecksEnabled: false,
  showDebugInformation: !environment.production,
  clearHashAfterLogin: false, // https://github.com/manfredsteyer/angular-oauth2-oidc/issues/457#issuecomment-431807040,
  nonceStateSeparator: 'semicolon', // Real semicolon gets mangled by IdentityServer's URI encoding
  // loginUrl: environment.authenticationUrl + 'oidc/auth',
  tokenEndpoint: environment.authenticationUrl + 'oidc/token',
  // userinfoEndpoint: environment.authenticationUrl + 'oidc/me',
  // revocationEndpoint: environment.authenticationUrl + 'oidc/token/revocation',
};

export const authModuleConfig: OAuthModuleConfig = {
  resourceServer: {
    allowedUrls: [environment.communicationUrl, environment.structureUrl, environment.configurationUrl],
    sendAccessToken: true,
  },
};
