import { createFeatureSelector, createSelector } from "@ngrx/store";
import { BulkReducers } from "src/app/store/app.reducers";
import {BulkState} from "./bulk.reducer";

const BulkSelector = createFeatureSelector(BulkReducers.TAG)

export const getMultipleData = createSelector(
  BulkSelector,
  (state: BulkState) => state.multipleData
)
export const getData = createSelector(
  BulkSelector,
  (state: BulkState) => state.data
)
export const getMultipleDataCustom = createSelector(
  BulkSelector,
  (state: BulkState) => state.multipleDataCustom
)
export const getDataCustom = createSelector(
  BulkSelector,
  (state: BulkState) => state.dataCustom
)
