import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as AppContestsReducers from './app-contests.reducer';

const getAppContestsState = createFeatureSelector<AppContestsReducers.AppContestsState>(AppContestsReducers.APP_CONTESTS_TAG);

export const getAppContestsFilters = createSelector(
  getAppContestsState,
  (state: AppContestsReducers.AppContestsState) => state?.filters
);
export const getAppContestsSort = createSelector(
  getAppContestsState,
  (state: AppContestsReducers.AppContestsState) => state?.sort
);
export const getAppContestsPage = createSelector(
  getAppContestsState,
  (state: AppContestsReducers.AppContestsState) => state?.page
);
export const getAppContestsSearchInfo = createSelector(
  getAppContestsState,
  (state: AppContestsReducers.AppContestsState) => state?.searchInfo
);
export const getAppContestsData = createSelector(
  getAppContestsState,
  (state: AppContestsReducers.AppContestsState) => state?.data
);
export const getAppContestsExtFilt = createSelector(
  getAppContestsState,
  (state) => state?.extFilt
)
