import { PaginationInfo } from 'src/app/models/pagination-info';
import { Parts } from '../enums/parts.enum';
import { SearchInfo } from '../models/search-info';
import { ISortInfo } from '../models/sorting-info';

export class UrlBuilderService {
  protected requestUrl = '';

  constructor(requestUrl) {
    this.requestUrl = requestUrl;
  }

  private parts = '*';

  private additionalParameter;

  withQueryParam(queryParam: string, value: string) {
    const concatenationCharacter = this.requestUrl.includes('?') ? '&' : '?';
    this.requestUrl += concatenationCharacter + queryParam + '=' + value;
    return this;
  }

  withPaginationInfo(paginationInfo: PaginationInfo) {
    return this.withQueryParam('numberOfPage', paginationInfo.nextPage.toString()).withQueryParam(
      'numberOfPageElements',
      paginationInfo.numberOfElementsOnPage.toString()
    );
  }

  withSearchFilter(searchInfo: SearchInfo) {
    this.requestUrl +=
      '&' +
      searchInfo.searchValueParamName +
      '=' +
      encodeURIComponent(searchInfo.query) +
      '&' +
      searchInfo.searchFieldsParamName +
      '=' +
      encodeURIComponent(searchInfo.searchFields);
  }

  withStructureUUIDsFilter(structureUUIDs: string[]) {
    let structureUUIDsStringValue = '';
    structureUUIDs.forEach((structureUUID, index) => {
      structureUUIDsStringValue += structureUUID;
      if (index < structureUUIDs.length - 1) {
        structureUUIDsStringValue += ',';
      }
    });

    this.requestUrl += '&structureFieldUUID=' + structureUUIDsStringValue;
    return this;
  }

  withParts(parts: Parts[]) {
    this.parts = parts.join(',');
    return this;
  }

  withAdditionalParameter(parameter) {
    this.additionalParameter = parameter;
    for (const value of Object.keys(this.additionalParameter)) {
      this.withQueryParam(value, parameter[value]);
      // this.requestUrl += '&' + value + '=' + encodeURIComponent(parameter[value]);
    }
    return this;
  }

  withSortClause(sortingField: string, sortingDirection: string) {
    this.requestUrl += '&sortBy=' + encodeURIComponent(sortingField + '#' + sortingDirection);
    return this;
  }

  withSortInfo(sortInfo: ISortInfo) {
    this.requestUrl += '&sortBy=' + encodeURIComponent(sortInfo.getCompleteSortInfo());
    return this;
  }

  withSortClauses(sortingClauses: Map<string, string>) {
    let appendString = '&sortBy=';
    if (sortingClauses.size > 0) {
      sortingClauses.forEach((sortingDirectionValue: string, sortingFieldKey: string) => {
        this.requestUrl += appendString + encodeURIComponent(sortingFieldKey + '#' + sortingDirectionValue);
        appendString = ',';
      });
    }

    return this;
  }

  build(): string {
    if (this.parts != undefined && this.parts != '') {
      const concatenationCharacter = this.requestUrl.includes('?') ? '&' : '?';
      this.requestUrl += concatenationCharacter + 'parts=' + this.parts;
    }
    return this.requestUrl;
  }
}
