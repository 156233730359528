import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { createAction, props } from '@ngrx/store';
import { SearchInfo } from 'src/app/models/search-info';
import { FiltersType } from './app-users.reducer';

export const setAppUsersFilters = createAction('[AppUsers] set Filters', props<{ filters: FiltersType }>());
export const setAppUsersSort = createAction('[AppUsers] set Sort', props<{ sort: Sort }>());
export const setAppUsersPage = createAction('[AppUsers] set Page', props<{ page: PageEvent }>());
export const setAppUsersSearchInfo = createAction('[AppUsers] set Search Info', props<{ searchInfo: SearchInfo }>());
export const setAppUsersData = createAction('[AppUsers] set Data', props<{ data: any }>());
export const setAppUsersBulkAction = createAction('[AppUsers] toggle bulk action', props<{ bulkAction: any }>());
export const setAppUsersUUIDListBulk = createAction('[AppUsers] toggle bulk', props<{ bulkData: any }>());
export const setMultipleUpdateResult = createAction('[AppUsers] multiple update finished', props<{ multipleData: any }>());
