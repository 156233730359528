import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
})
export class IconComponent {
  @Input('class') additionalClass: string;
  @Input('color') color: string;
  @Input('name') name: string;

  getClassList() {
    let classList = 'icon';
    if (this.additionalClass) {
      classList += ' ' + this.additionalClass;
    }
    return classList;
  }
}
