import {Component, OnInit} from '@angular/core';
import {ModalRef} from "../modalRef";
import {TranslatePipe} from '@ngx-translate/core';
import { MessageTitle } from 'src/app/models/Message';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss'],
  providers: [TranslatePipe]
})
export class MessageComponent implements OnInit {

  message;
  status = "";
  code = "";
  clickedAction;
  title = "";
  statistics;
  disableSave = false;

  constructor(protected modalRef: ModalRef, protected pipe: TranslatePipe) {

  }

  ngOnInit(): void {
    if (this.modalRef.data?.message) {
      this.message = this.modalRef.data?.message;
    }
    if (this.modalRef.data?.disableSave) {
      this.disableSave = this.modalRef.data?.disableSave;
    }
    if (this.modalRef.data?.status) {
      this.status = this.modalRef.data?.status;
    }
    if (this.modalRef.data?.code) {
      this.code = this.modalRef.data?.code;
    }
    if (this.modalRef.data?.title) {
      if(this.modalRef.data.title in MessageTitle) {
        this.title += "MESSAGE.TITLE."
      }
      this.title += this.modalRef.data.title;
    }
    if (this.modalRef.data?.statistics) {
      this.statistics = this.modalRef.data?.statistics;
    }
  }

  onCancel() {
    this.clickedAction = "cancel";
    this.modalRef.close(this.clickedAction);
  }

  onSave() {
    this.clickedAction = "ok";
    this.modalRef.close(this.clickedAction);
  }
}
