import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { Permissions } from 'src/app/interfaces/permissions';
import { User } from 'src/app/models/User';

export const login = createAction('[Auth] Login', props<{ email: string; password: string }>());

export const setAuthError = createAction(
  '[Auth] Set Authentication Error',
  props<{ authError: HttpErrorResponse }>()
);

export const setUserData = createAction('[Auth] Set User Data', props<{ userData: User }>());

export const setPermissions = createAction('[Auth] Set User Permissions', props<{ userPerm: Permissions[] }>());

export const logout = createAction('[Auth] Logout');
