import { ObjectValuesType } from "../helpers/utilities";

export const Alignments = {
  HORIZONTAL: 'horizontal',
  VERTICAL: 'vertical',
  METRO: 'metro',
} as const;


export type Alignments = ObjectValuesType<typeof Alignments>;
