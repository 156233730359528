export class LocalizableField extends Array<{ language: string; value: string }> {
  constructor(localizedValuesMap: Array<{ language: string; value: string }> = []) {
    super();
    this.push(...localizedValuesMap);
  }

  static createWithLocalizedValue(localizedValue: { language: string; value: string }) {
    return new LocalizableField([localizedValue]);
  }

  get locale(): string {
    return this[0]?.language;
  }

  getLocalizedValue(locale: string, defaultLocale?: string): string {
    return (this.find((value) => value.language === locale) || this.find((value) => value.language === defaultLocale))?.value ?? '';
  }

  hasLocalizedValue(language:string):boolean{
    return !!this.getLocalizedValue(language)
  }

  getNonLocalizedValue(): string {
    return this[Object.keys(this)[0]];
  }

  areAllLocalizationWithValue(): boolean {
    const atLeastOneEmpty = !!this.find((element) => !element.value);
    return !atLeastOneEmpty;
  }

  addLocalizedValue(language: string, value: string): void {
    const existing = this.find((v) => v.language === language);
    if (existing) {
      existing.value = value;
    } else {
      this.push({ language, value });
    }
  }
}
