import { StructureType } from './structure-type.enum';

export enum FiltersVisibility {
  'VISIBLE' = 'VISIBLE',
  'HIDDEN' = 'HIDDEN',
}

export interface FiltersType {
  'structure.type': { visibility: FiltersVisibility; value: StructureType };
  structureUUID: { visibility: FiltersVisibility; value: string | undefined };
  featureValue?: { visibility: FiltersVisibility; value: string | undefined };
  communityUUID: { visibility: FiltersVisibility; value: string | undefined };
  communityAdminUUID?: { visibility: FiltersVisibility; value: string | undefined };
  communityEntityUUID?: { visibility: FiltersVisibility; value: string | undefined };
  template?: { visibility: FiltersVisibility; value: string | undefined };
  active: { visibility: FiltersVisibility; value: boolean | undefined };
  status?: { visibility: FiltersVisibility; value: string | undefined };
  timezoneOffset?: { visibility: FiltersVisibility, value: number };
  productId?: { visibility: FiltersVisibility; value: string | undefined };
  userId?: { visibility: FiltersVisibility; value: string | undefined };
}
export interface FiltersTypeValues {
  'structure.type': StructureType;
  structureUUID?: string | undefined;
  featureValue?: string | undefined;
  communityUUID: string | undefined;
  communityAdminUUID?: string | undefined;
  communityEntityUUID?: string | undefined;
  template: string | undefined;
  active?: boolean | undefined;
  status?: string | undefined;
  productId?: string | undefined;
  userId?: string | undefined
}

export interface LessonFiltersType {
  //filtro categorie
  featureValueList?: { visibility: FiltersVisibility; value: string | undefined };
}
export interface LessonFiltersTypeValues {
  //filtro categorie
  featureValueList?: string | undefined;
}
