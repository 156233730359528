
import { StructureField } from '../interfaces/structure-field';
import { FeatureValue } from './FeatureValue';
import { LocalizableField } from './LocalizableField';

export interface Product {
  value?: LocalizableField | string;
  structureUUID: string;
  containerId?: string;
  uuid: string;
  structureField?: StructureField;
  userStructureField?: StructureField;
  featureValueList?: Array<string | FeatureValue>;
  localizedValues?: Array<Partial<{ locale: string; order: number; productName?: string; name?: string }>>;
  entityVersion: number;
  status: string;
  workflowStep?: string[];
  [key: string]: any;
}
