import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { SearchInfo } from 'src/app/models/search-info';
import { Action, createReducer, on } from '@ngrx/store';
import { AppContestsActions, AuthActions } from 'src/app/store/app.actions';

export const APP_CONTESTS_TAG = 'appContests';

export interface FiltersType {
  communityUUID: string;
}

export interface AppContestsState {
  filters: FiltersType;
  sort: Sort;
  page: PageEvent;
  extFilt: any;
  searchInfo: SearchInfo;
  data: any;
}

const appContestsInitialState: AppContestsState = {
  filters: {
    communityUUID: undefined
  },
  sort: { active: 'firstName', direction: 'asc' },
  page: { pageIndex: 0, pageSize: 25, length: undefined },
  extFilt: undefined,
  searchInfo: {
    searchFields: 'name',
    searchFieldsParamName: 'searchFields',
    searchValueParamName: 'q',
  } as SearchInfo,
  data: undefined,
};

const _commReducer = createReducer(
  appContestsInitialState,
  on(AppContestsActions.setAppContestsFilters, (state: AppContestsState, { filters }) => ({
    ...state,
    filters: {
      ...filters,
      communityUUID: (filters as any).communityEntityUUID
    }
  })),
  on(AppContestsActions.setAppContestsSort, (state: AppContestsState, { sort }) => ({
    ...state,
    sort,
  })),
  on(AppContestsActions.setAppContestsPage, (state: AppContestsState, { page }) => ({
    ...state,
    page,
  })),
  on(AppContestsActions.updateExtFilt, (state: AppContestsState, { extFilt }) => ({
    ...state,
    extFilt,
  })),
  on(AppContestsActions.setAppContestsSearchInfo, (state: AppContestsState, { searchInfo }) => ({
    ...state,
    searchInfo,
  })),
  on(AppContestsActions.setAppContestsData, (state: AppContestsState, { data }) => ({
    ...state,
    data,
  })),
  on(AuthActions.logout, () => ({ ...appContestsInitialState }))
);

export const appContestsReducer = (state: AppContestsState, action: Action) => {
  return _commReducer(state, action);
};
