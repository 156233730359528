import { ObjectValuesType } from '../helpers/utilities';
export const GridOption = {
  EDIT: 'EDIT',
  DELETE: 'DELETE',
  CLONE: 'CLONE',
  OPEN: 'OPEN',
  DOWNLOAD: 'DOWNLOAD',
  ARCHIVE: 'ARCHIVE',
  DISABLE: 'DISABLE',
  ACTIVATE: 'ACTIVATE',
  DEACTIVATE: 'DEACTIVATE',
  PERMISSION: 'PERMISSION',
  ENABLE: 'ENABLE',
  DETAIL: 'DETAIL',
  MOVE: 'MOVE',
  PREVIEW: 'PREVIEW',
  ACCEPT: 'ACCEPT',
  APPROVE: 'APPROVE', // RIGUARDA GLI ORDINI
  REFUSE: 'REFUSE', // RIGUARDA GLI ORDINI
  TEMPLATE: 'TEMPLATE',
  REMOVE: 'REMOVE',
  COMPARE: 'COMPARE',
  PUBLISH: 'PUBLISH',
  REVOKE: 'REVOKE',
  DECLINE: 'DECLINE',
  CANCEL: 'CANCEL',
} as const;

export type GridOption = ObjectValuesType<typeof GridOption>;
