import { ObjectValuesType } from '../helpers/utilities';

export const CardContextType = {
  PRODUCT: 'product',
  COMMUNICATION: 'communication',
  APP_USER: 'appUser',
  INTEGRATION: 'integration',
  CONTENT: 'content',
  COCKPIT: 'cockpit',
  RESOURCE: 'resource',
  BOOKABLE_PRODUCT: 'bookableProduct',
} as const;

export type CardContextType = ObjectValuesType<typeof CardContextType>;
