import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppGroupsReducers } from 'src/app/store/app.reducers';

const getAppGroupsState = createFeatureSelector<AppGroupsReducers.AppGroupsState>(AppGroupsReducers.APP_GROUPS_TAG);

export const getAppGroupsFilters = createSelector(getAppGroupsState, (state: AppGroupsReducers.AppGroupsState) => state?.filters);
export const getAppGroupsSort = createSelector(getAppGroupsState, (state: AppGroupsReducers.AppGroupsState) => state?.sort);
export const getAppGroupsPage = createSelector(getAppGroupsState, (state: AppGroupsReducers.AppGroupsState) => state?.page);
export const getAppGroupsSearchInfo = createSelector(getAppGroupsState, (state: AppGroupsReducers.AppGroupsState) => state?.searchInfo);
export const getAppGroupsData = createSelector(getAppGroupsState, (state: AppGroupsReducers.AppGroupsState) => state?.data);
