import { createAction, props } from '@ngrx/store';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { SearchInfo } from 'src/app/models/search-info';

export const setSurveyFilters = createAction('[Survey] set Filters', props<{ filters: any }>());
export const setSurveySort = createAction('[Survey] set Sort', props<{ sort: Sort }>());
export const setSurveyPage = createAction('[Survey] set Page', props<{ page: PageEvent }>());
export const setSurveySearchInfo = createAction('[Survey] set Search Info', props<{ searchInfo: SearchInfo }>());
export const setSurveyData = createAction('[Survey] set Data', props<{ data: any }>());
export const updateExtFilt = createAction('[Survey] Update External Filters', props<{ extFilt: any }>());
