import { Action, createReducer, on } from '@ngrx/store';
import { AuthActions, BulkActions } from 'src/app/store/app.actions';

export const TAG = 'bulk';

export interface BulkState {
  multipleData: any;
  data: any;
  multipleDataCustom: any;
  dataCustom: any;
}

const initialState: BulkState = {
  multipleData: undefined,
  data: undefined,
  multipleDataCustom: undefined,
  dataCustom: undefined,
};

const _bulkReducer = createReducer(
  initialState,
  on(BulkActions.setMultipleUpdate, (state: BulkState, { multipleData }) => ({
    ...state,
    multipleData,
  })),
  on(BulkActions.setData, (state: BulkState, { data }) => ({
    ...state,
    data,
  })),
  on(BulkActions.setMultipleUpdateCustomization, (state: BulkState, { multipleDataCustom }) => ({
    ...state,
    multipleDataCustom,
  })),
  on(BulkActions.setDataCustomization, (state: BulkState, { dataCustom }) => ({
    ...state,
    dataCustom,
  })),
  on(BulkActions.reset, (state: BulkState) => ({
    ...state,
    multipleData: undefined,
    data: undefined,
    multipleDataCustom: undefined,
    dataCustom: undefined,
  })),
  on(AuthActions.logout, () => ({ ...initialState }))
);

export const BulkReducer = (state: BulkState, action: Action) => {
  return _bulkReducer(state, action);
};
