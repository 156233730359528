import { BlockScrollStrategy, Overlay, OverlayModule } from '@angular/cdk/overlay';
import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import localeIt from '@angular/common/locales/it';
import { DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule } from '@angular/core';
import { MAT_DATEPICKER_SCROLL_STRATEGY, MAT_DATE_RANGE_SELECTION_STRATEGY, MatDateRangePicker } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MAT_MENU_SCROLL_STRATEGY } from '@angular/material/menu';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { IonicModule } from '@ionic/angular';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import {
  MissingTranslationHandler,
  MissingTranslationHandlerParams,
  TranslateLoader,
  TranslateModule,
  TranslatePipe,
} from '@ngx-translate/core';
import { AuthConfig, OAuthModule, OAuthModuleConfig } from 'angular-oauth2-oidc';
import { provideLottieOptions } from 'ngx-lottie';
import { forkJoin, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppEffects, AppReducers } from './app.state';
import { JwtInterceptor } from './helpers/jwt.interceptor';
import { authConfig, authModuleConfig } from './pages/login/auth.config';
import { ToLocalizedValuePipe } from './pipes/to-localized-value/to-localized-value';
import { ToStandardBooleanPipe } from './pipes/to-standard-boolean/to-standard-boolean';
import { MatPaginatorIntlCustom } from './provider/MatPaginatorIntlCustom';
import { ScriptService } from './services/script.service';

registerLocaleData(localeIt, 'it');

export function scrollFactory(overlay: Overlay): () => BlockScrollStrategy {
  return () => overlay.scrollStrategies.block();
}

/* export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
} */
export function translateLoader(http: HttpClient) {
  return new MultiTranslateHttpLoader(http, [
    { prefix: './assets/i18n/', suffix: '.json' },
    { prefix: './profiles/default/i18n/', suffix: '.json' },
  ]);
}

export class MultiTranslateHttpLoader implements TranslateLoader {
  constructor(
    private http: HttpClient,
    public resources: { prefix: string; suffix: string }[] = [
      {
        prefix: '/assets/i18n/',
        suffix: '.json',
      },
    ],
  ) {}

  public getTranslation(lang: string): any {
    return forkJoin(
      this.resources.map((config) => {
        //se il file non è presente non deve fermarsi il forkJoin
        return this.http.get(`${config.prefix}${lang}${config.suffix}`).pipe(catchError(() => of({})));
      }),
    ).pipe(
      map((response) => {
        return this.mergeDeep(...response);
      }),
      catchError((error) => {
        return of(error);
      }),
    );
  }

  private mergeDeep(...objects) {
    const isObject = (obj) => obj && typeof obj === 'object';
    return objects.reduce((prev, obj) => {
      Object.keys(obj).forEach((key) => {
        const pVal = prev[key];
        const oVal = obj[key];
        if (Array.isArray(pVal) && Array.isArray(oVal)) {
          prev[key] = pVal.concat(...oVal);
        } else if (isObject(pVal) && isObject(oVal)) {
          prev[key] = this.mergeDeep(pVal, oVal);
        } else {
          prev[key] = oVal;
        }
      });
      return prev;
    }, {});
  }
}

export class MyMissingTranslationHandler implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams) {
    if (params.key.toLocaleLowerCase().includes('cards')) {
      return params.key.split('.').pop();
    }
  }
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    OAuthModule.forRoot(),
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    OverlayModule,
    MatSelectModule,
    MatDialogModule,
    MatPaginatorModule,
    IonicModule.forRoot(),
    EffectsModule.forRoot(AppEffects),
    StoreModule.forRoot(AppReducers),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
      name: 'Datalean',
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: translateLoader,
        deps: [HttpClient],
      },
      missingTranslationHandler: { provide: MissingTranslationHandler, useClass: MyMissingTranslationHandler },
      defaultLanguage: environment.activeLanguage,
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
  ],
  providers: [
    { provide: AuthConfig, useValue: authConfig },
    { provide: OAuthModuleConfig, useValue: authModuleConfig },
    ScriptService,
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'fill' } },
    {
      provide: MAT_DATE_RANGE_SELECTION_STRATEGY,
      useClass: MatDateRangePicker,
    },
    {
      provide: MAT_MENU_SCROLL_STRATEGY,
      useFactory: scrollFactory,
      deps: [Overlay],
    },
    {
      provide: MAT_DATEPICKER_SCROLL_STRATEGY,
      useFactory: scrollFactory,
      deps: [Overlay],
    },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: 'it' },
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'EUR' },
    // { provide: MAT_COLOR_FORMATS, useValue: CUSTOM_MAT_COLOR_FORMATS },
    // ColorAdapter,
    TranslatePipe,
    ToLocalizedValuePipe,
    ToStandardBooleanPipe,
    { provide: MatPaginatorIntl, useClass: MatPaginatorIntlCustom },
    provideLottieOptions({
      player: () => import(/* webpackChunkName: 'lottie-web' */ 'lottie-web'),
    }),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
