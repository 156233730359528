import { Injectable } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { v4 } from 'uuid';
import { FlatNode, Node, NodeType } from '../components/draggable-tree/draggable-tree.component';
import { Parts } from '../enums/parts.enum';
import { StructureFieldTypes } from '../enums/structure-field-types.enum';
import { StructureType } from '../enums/structure-type.enum';
import { DeepFind } from '../helpers/utilities';
import { StructureField } from '../interfaces/structure-field';
import { Structure } from '../models';
import { LocalizableField } from '../models/LocalizableField';
import { DataleanDataProviderService } from '../provider/datalean-data-provider.service';
import { BaseEditorService } from './base-editor.service';
import { LocalizationService } from './localization.service';

// not an injectable because it is not meant to be reused
@Injectable()
export class StructureEditorService extends BaseEditorService {
  objectData: StructureField | Structure = new Structure({});

  constructor(
    protected dataleanDataService: DataleanDataProviderService,
    protected translatePipe: TranslatePipe,
    protected localizationService: LocalizationService
  ) {
    super(dataleanDataService);
  }

  init() {
    const lan = this.localizationService.getActiveEntityLanguage();
    this.objectData = {
      uuid: v4(),
      name: this.translatePipe.transform('STRUCTURES.NEW_STRUCTURE'),
      priority: undefined,
      structureFields: [],
      type: StructureType.PRODUCT,
      mobileRatio: 100,
      tabletRatio: 50,
      desktopRatio: 33,
      description: [{ language: lan, value: '' }],
    } as Structure;
  }

  remapFieldUnivocity(fields: StructureField[]): StructureField[] {
    if (fields.length) {
      return fields.map((f) => {
        const id = v4();
         const incremental = +(f.name.split('-')?.[1] || 0) + 1;
        if (f.fields) f.fields = this.remapFieldUnivocity(f.fields);
        return {
          ...f,
          name: `${f.name.split('-')[0]}-${incremental}`,
          id,
          uuid: id,
        };
      });
    }

    return fields;
  }

  initStructureField(node: Node | FlatNode, path: Array<string>, isUserStructureField: boolean, cloneObjId?: string): StructureField {
    let incremental = 0;
    let structureField: StructureField = {
      uuid: v4(),
      name: 'field',
      card: false,
      categorizable: false,
      inputTypeRequired: '',
      enableOn: '',
      hint: new LocalizableField(),
      containerTitle: false,
      displayCategorizable: false,
      fields: [],
      fixed: false,
      label: new LocalizableField(),
      relatedStructureType: '',
      localizable: isUserStructureField ? false : true,
      orderId: 0,
      predefinedValue: new LocalizableField(),
      readOnly: false,
      repeatExtraTimes: undefined,
      repeatable: false,
      resettable: false,
      rolesReadonly: [],
      rolesCantSee: [],
      showCollapsed: false,
      type: StructureFieldTypes.TEXT,
      mobileRatio: 100,
      tabletRatio: 100,
      desktopRatio: 100,
      extra: '',
    };
    const structure = this.objectData as Structure;

    if (cloneObjId) {
      let structureFieldTop = structure?.structureFields?.find((sf) => sf.uuid === (path.length ? node.id : cloneObjId));
      if (!structureFieldTop) {
        structureFieldTop = DeepFind(structure?.structureFields, path.length ? node.id : cloneObjId, 'uuid', 'fields');
      }
      const data = structuredClone(path.length ? structureFieldTop.fields.find((f) => f.uuid === cloneObjId) : structureFieldTop);
      if (data?.uuid) {
        delete data.uuid;
      }
      const structureFieldTemp: StructureField = {
        ...structuredClone(structureField),
        ...structuredClone(data),
        id: structureField.uuid,
      };
      if (structureFieldTemp.fields.length) {
        structureFieldTemp.fields = this.remapFieldUnivocity(structureFieldTemp.fields);
      }
      structureField = structuredClone(structureFieldTemp);
    }

    if (node.nodeType === NodeType.PARENT_TYPE) {
      if (!structure.structureFields) {
        structure.structureFields = [];
      }
      structure.structureFields.push(structureField);
      incremental = structure.structureFields.length;
    }
    this.objectData = { ...this.objectData, ...structure };

    if (node.nodeType === NodeType.CHILD_INCEST_TYPE) {
      const lastNode = path.reduce((prev: Structure | StructureField, uuid) => {
        let dataset = (prev as Structure).structureFields;
        if (prev && 'fields' in prev) {
          dataset = prev.fields;
        }
        const res = DeepFind(dataset, uuid, 'uuid', 'fields');
        if (res) {
          return res;
        }
        return this.objectData;
      }, this.objectData);

      let currentField: StructureField;
      if (lastNode) {
        if ('fields' in lastNode && lastNode.fields) {
          currentField = lastNode.fields.find((sf) => node.uuid === sf.uuid);
        } else {
          currentField = (lastNode as Structure).structureFields.find((sf) => node.uuid === sf.uuid);
        }
      }
      if (currentField) {
        currentField.fields.push(structureField);
        incremental = currentField.fields.length;
        structureField.localizable = currentField.localizable;
      }
    }
    structureField.name = 'field-' + incremental;
    if (!(structureField.label instanceof LocalizableField)) {
      structureField.label = new LocalizableField(structureField.label);
    }
    structureField.label.addLocalizedValue(this.localizationService.getDefaultLang(), structureField.name);
    structureField.order = incremental;
    return structureField;
  }

  getStructures() {
    return this.dataleanDataService.getAllEntities<Structure>(environment.structureUrl, [Parts.STRUCTURE_FIELDS]);
  }

  create(): Observable<any> {
    delete this.objectData.id;
    return this.dataleanDataService.createEntity(environment.structureUrl, this.objectData, []);
  }

  createData(data): Observable<any> {
    delete this.objectData.id;
    delete data.id;
    return this.dataleanDataService.createEntity(environment.structureUrl, data, [Parts.STRUCTURE_FIELDS]);
  }

  update(data): Observable<any> {
    if (this.objectData?.id) {
      delete this.objectData.id;
    }
    delete data.id;
    return this.dataleanDataService.updateEntity(environment.structureUrl, data, [Parts.STRUCTURE_FIELDS]);
  }

  delete(uuid): Observable<any> {
    return this.dataleanDataService.deleteEntity(environment.structureUrl, uuid);
  }
}
