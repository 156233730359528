import { Action, createReducer, on } from "@ngrx/store"
import { setSaveLms } from "./lms.actions"
import { AuthActions } from "src/app/store/app.actions"
import { ObjectValuesType } from "src/app/helpers/utilities";

export const LmsTab = {
  dashboard: 'dashboard',
  course: 'course',
  lesson: 'lesson',
  lessonCategory: 'lesson-category'
} as const;
export type LmsTab = ObjectValuesType<typeof LmsTab>;


export interface LmsState {
  onSave: {
    value: boolean,
    tab: LmsTab
  }
}

const initialState : LmsState = {
  onSave: {
    value: false,
    tab: LmsTab.dashboard
  }
}

const _reducer = createReducer(
  initialState,
  on( setSaveLms, (state: LmsState, { onSave, tab }) => ({
    ...state,
    onSave: {value: onSave, tab: tab},
  })),
  on(AuthActions.logout, () => ({ ...initialState }))
)

export function lmsReducer(state: LmsState, action: Action) {
  return _reducer(state, action);
}
