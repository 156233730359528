import { EventEmitter, Injectable, Output } from '@angular/core';
import { environment } from '../../environments/environment';
import { AuthService } from '../auth.service';
import { Permission } from '../enums/permission.enum';
import { UserPermissionService } from './user-permission.service';

@Injectable({
  providedIn: 'root'
})
export class NavigationMenuService {
  possibleLinks = [
    [
      {
        label: 'NAVIGATION.DASHBOARD',
        path: '/dashboard',
        iconCls: '',
        icon: 'k',
        hideMobile: false,
        requiredPermissions: [Permission.READ_DASHBOARD.name]
      },
      {
        label: 'NAVIGATION.PRODUCTS',
        path: '/products',
        iconCls: '',
        icon: 'G',
        hideMobile: false,
        requiredPermissions: [Permission.READ_PRODUCTS.name]
      },
      {
        label: 'NAVIGATION.CONTENTS',
        path: '/contents',
        iconCls: '',
        icon: 'r',
        hideMobile: false,
        requiredPermissions: [Permission.READ_CONTENTS.name]
      },
      {
        label: 'NAVIGATION.COMMUNICATIONS',
        path: '/communications',
        iconCls: '',
        icon: 'R',
        hideMobile: true,
        requiredPermissions: [Permission.READ_COMMUNICATIONS.name]
      },
      {
        label: 'NAVIGATION.SURVEY',
        path: '/survey',
        iconCls: '',
        icon: 'W',
        hideMobile: true,
        requiredPermissions: [Permission.READ_SURVEY.name]
      },
      {
        label: 'NAVIGATION.CONTESTS',
        path: '/app-contests',
        iconCls: '',
        icon: 'i',
        hideMobile: true,
        requiredPermissions: [Permission.READ_CONTESTS.name]
      },
      {
        label: 'NAVIGATION.MEDIA_LIBRARY',
        path: '/media-library',
        iconCls: '',
        icon: 'z',
        hideMobile: false,
        requiredPermissions: [Permission.READ_MEDIA_LIBRARY.name, Permission.VIEW_COMMUNITIES.name]
      },
      {
        label: 'NAVIGATION.FEATURES',
        path: '/features',
        iconCls: '',
        icon: 'c',
        hideMobile: true,
        requiredPermissions: [Permission.READ_FEATURES.name]
      },
      {
        label: 'NAVIGATION.COMMUNITIES',
        path: '/communities',
        iconCls: '',
        icon: 'S',
        hideMobile: false,
        requiredPermissions: [Permission.VIEW_COMMUNITIES.name, Permission.VIEW_ALL_COMMUNITIES.name]
      },
      {
        label: 'NAVIGATION.APPLICATION_USERS',
        path: '/app-users',
        iconCls: '',
        icon: 'T',
        hideMobile: true,
        requiredPermissions: [Permission.READ_APPLICATION_USERS.name]
      },
      {
        label: 'NAVIGATION.GROUPS',
        path: '/app-groups',
        iconCls: '',
        icon: 'Y',
        hideMobile: true,
        requiredPermissions: [Permission.READ_APPLICATION_GROUPS.name]
      },
      {
        label: 'NAVIGATION.RESERVATIONS',
        path: '/reservations',
        iconCls: '',
        icon: '\\',
        hideMobile: true,
        requiredPermissions: [Permission.READ_RESERVATIONS.name]
      },
      {
        label: 'NAVIGATION.LMS',
        path: '/lms',
        iconCls: '',
        icon: '9',
        hideMobile: true,
        requiredPermissions: [Permission.READ_LEARNING_MANAGEMENT_SYSTEM.name]
      }
    ],
    [
      {
        label: 'NAVIGATION.ORDERS',
        path: '/orders',
        iconCls: '',
        icon: 'P',
        hideMobile: true,
        requiredPermissions: [Permission.READ_ORDERS.name]
      },
      {
        label: 'NAVIGATION.BOOKABLE_RESOURCES',
        path: '/bookableResource',
        iconCls: '',
        icon: 'P',
        hideMobile: true,
        requiredPermissions: [Permission.READ_BOOKABLE_RESOURCES.name]
      },
      {
        label: 'NAVIGATION.TABLES',
        path: '/tables',
        iconCls: '',
        icon: 'N',
        hideMobile: true,
        requiredPermissions: [Permission.READ_TABLES.name]
      }
      /*     {
          label: 'NAVIGATION.MIGRATION',
          path: '/gsMigration',
          iconCls: '',
          icon: 'K',
          hideMobile :true,
          requiredPermissions: [Permission.ANY.name]
        }, */
    ],
    [
      {
        label: 'NAVIGATION.USERS',
        path: '/users',
        iconCls: '',
        icon: 'V',
        hideMobile: true,
        requiredPermissions: [Permission.READ_DATALEAN_USERS.name]
      },
      {
        label: 'NAVIGATION.ROLES',
        path: '/roles',
        iconCls: '',
        icon: 'P',
        hideMobile: true,
        requiredPermissions: [Permission.READ_ROLES.name]
      },
      {
        label: 'NAVIGATION.STRUCTURES',
        path: '/structures',
        iconCls: '',
        icon: 'I',
        hideMobile: true,
        requiredPermissions: [Permission.READ_STRUCTURE.name]
      },
      {
        label: 'NAVIGATION.FILTERS',
        path: '/filters',
        iconCls: '',
        icon: 'q',
        hideMobile: true,
        requiredPermissions: [Permission.READ_FILTERS.name]
      },
      {
        label: 'NAVIGATION.USERS_STRUCTURES',
        path: '/users-structures',
        iconCls: '',
        icon: 'I',
        hideMobile: true,
        requiredPermissions: [Permission.READ_STRUCTURE.name]
      },
      {
        label: 'NAVIGATION.PRODUCT_QUESTION',
        path: '/product-question',
        iconCls: '',
        icon: 'I',
        hideMobile: true,
        requiredPermissions: [Permission.READ_PRODUCT_QUESTION.name]
      },
      {
        label: 'NAVIGATION.INTEGRATIONS',
        path: '/integration', // TODO: change this to right path(es. integrations)
        iconCls: '',
        icon: 'w',
        hideMobile: true,
        requiredPermissions: [Permission.READ_INTEGRATIONS.name] // TODO: change permission
      },
      {
        label: 'NAVIGATION.CONFIGURATIONS',
        path: '/configurations',
        iconCls: '',
        icon: '6',
        hideMobile: true,
        requiredPermissions: [Permission.READ_CONFIGURATION.name]
      },
      {
        label: 'NAVIGATION.PROFILE',
        path: '/profile',
        iconCls: '',
        icon: 'K',
        hideMobile: true,
        requiredPermissions: [Permission.ANY.name]
      }
    ]
  ];
  authorizedLinksList = [];

  @Output() backPressed = new EventEmitter<any>();
  @Output() showBack = new EventEmitter<any>();

  constructor(private authService: AuthService, private userPermissionService: UserPermissionService) {
    if (environment['dashboardIcons']) {
      const flatLinks = this.possibleLinks.flat();
      for (const link of environment['dashboardIcons']) {
        flatLinks.find((links) => links.path == link.path).icon = link.icon;
      }
    }
  }

  get authorizedLinks() {
    if (this.authService.hasValidToken()) {
      const tempList = [];
      for (const linkArray of this.possibleLinks) {
        const authorizedLinkArray = linkArray.filter((link) => {
          return (
            link.requiredPermissions.includes(Permission.ANY.name) ||
            link.requiredPermissions.some((permission) => this.userPermissionService.getPermissionByName(permission))
          );
        });
        tempList.push(authorizedLinkArray);
      }
      if (
        tempList.length !== this.authorizedLinksList.length ||
        this.authorizedLinksList.some((link) => !tempList.some((tempLink) => tempLink.path === link.path))
      ) {
        this.authorizedLinksList = tempList;
      }
    }
    return this.authorizedLinksList;
  }
}
