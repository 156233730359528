import {LocalizableField} from './LocalizableField';

export interface Filter {
  alignment: FilterAlignment;
  allowUncheck: boolean;
  associatedStructureFieldsRef: any[];
  backgroundColor: string;
  children?: Filter[];
  childrenRef: string[];
  collapsed: boolean;
  collapsible: boolean;
  defaultValue: string;
  displayName: LocalizableField;
  enabled: boolean;
  enabledWhen: { [filterUUID: string]: string[] };
  filterContainer: boolean;
  filterOptions: { filterOptionType: string, associatedFeatureValueRef: string, featureValueContainer: boolean }[];
  filterWidgetType: FilterWidgetType;
  iconCls: string;
  image: string;
  label: LocalizableField;
  nextStepLabel: LocalizableField;
  order: number;
  operator: FilterOperator;
  parentUUID: string;
  position: FilterPosition;
  productField: string;
  productUrlLink: string;
  propagateToProductDetail: boolean;
  showAllProductsOnEmptySearch: boolean;
  showInSelectedFiltersArea: boolean;
  sort: { ascending: boolean, caseSensitive: boolean };
  uuid: string;
  weight: number;
  widgetItemName: string;
}

export enum FilterAlignment {
  HORIZONTAL = 'horizontal',
  VERTICAL = 'vertical'
}

export enum FilterWidgetType {
  DEFAULT = 'default',
  HORIZONTAL_SCROLLING = 'horizontal_scrolling',
  METRO = 'metro',
  SLIDER = 'slider',
  COMPANY_PROFILE = 'company_profile'
}

export enum FilterPosition {
  HEADER = 'header',
  LEFT = 'left',
  TOP = 'top',
  TOP_LEFT = 'top_left',
  NONE = 'none'
}

export enum FilterOperator {
  AND_EQUAL = 'and_equal',
  OR_EQUAL = 'or_equal',
  EQUAL = 'equal',
  AND = 'and',
  OR = 'or',
  LIKE = 'like',
  BETWEEN = 'between'
}
