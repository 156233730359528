import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, tap } from 'rxjs/operators';
import { AuthService } from 'src/app/auth.service';
import { Permissions } from 'src/app/interfaces/permissions';
import { AuthActions } from 'src/app/store/app.actions';

@Injectable()
export class AuthEffects {
  constructor(private actions$: Actions, private authService: AuthService) {}

  setUserPermissions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.setUserData),
      map(({ userData }) => {
        let userPerm: Permissions[] = [];
        if (userData?.roles?.length > 0) {
          userPerm = [...userData.roles]
            .filter((role) => role.permissions?.length)
            .reduce((prec: Permissions[], role) => prec.concat(role.permissions), [])
            .filter((x, i, a) => a.indexOf(x) === i);
        }
        return AuthActions.setPermissions({ userPerm });
      })
    )
  );

  logout$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.logout),
        tap(() => this.authService.logout())
      ),
    { dispatch: false }
  );
}
