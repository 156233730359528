import { FlatNode } from 'src/app/components/draggable-tree/draggable-tree.component';
import { StructureField } from 'src/app/interfaces/structure-field';
import { Structure } from 'src/app/models/Structure';
import { ContainerType } from '../enums/structure-field-types.enum';

export const IsContainer = (type) => {
  return Object.values(ContainerType).includes(type);
};

export default class StructureUtils {
  static findRelativeNodeInStructureFields(structures: (Structure | StructureField)[], node: FlatNode) {
    const fieldType = structures?.[0]?.['structureFields'] ? 'structureFields' : 'fields';
    let parentNode;
    for (const child of structures) {
      if (parentNode) {
        break;
      }
      if (child.uuid !== node.uuid && child[fieldType]) {
        parentNode = StructureUtils.findRelativeNodeInStructureFields(child[fieldType], node);
      } else if (child.uuid === node.uuid) {
        parentNode = child;
      }
    }
    return parentNode;
  }

  static findRelativeNode(node: FlatNode, dataset) {
    let parent: FlatNode;
    dataset.forEach((parentNode) => {
      if (parentNode.id === node.parentId) {
        parent = parentNode;
      }
      if (!parent && parentNode.children) {
        parent = StructureUtils.findRelativeNode(node, parentNode.children);
      }
    });
    return parent;
  }

  static deepGetUpdatedUUIDs(structureFieldsToUpdate: Array<StructureField>): Array<string> {
    return structureFieldsToUpdate.reduce((arr, sf) => arr.concat([sf.uuid, ...StructureUtils.deepGetUpdatedUUIDs(sf.fields ?? [])]), []);
  }

  static searchDeepDuplicates(searchFields: Array<StructureField>, nodeId: string, fieldValue: string) {
    return !!searchFields?.find((field) => {
      const found = field.uuid !== nodeId && field.name === fieldValue;
      return found || this.searchDeepDuplicates(field.fields, nodeId, fieldValue);
    });
  }

  static findNodeInStructureFields(structure, node: FlatNode) {
    return structure.find((child) => {
      const fieldType = 'structureFields' in child ? 'structureFields' : 'fields';
      if (child.uuid !== node.uuid && child[fieldType]) {
        return StructureUtils.findNodeInStructureFields(child[fieldType], node);
      } else {
        return child.uuid === node.uuid;
      }
    });
  }

  static findStructureFieldByUUID(uuid: string, startingObject?: (Structure | StructureField)[]): StructureField | undefined {
    let result = undefined;

    for (const structureField of startingObject ?? []) {
      if (!result) {
        const fieldType = 'structureFields' in structureField ? 'structureFields' : 'fields';

        if (structureField.uuid === uuid) {
          result = structureField;
        } else {
          result = StructureUtils.findStructureFieldByUUID(uuid, structureField[fieldType]);
        }
      }
    }

    return result;
  }
}
