import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { Action, createReducer, on } from '@ngrx/store';
import { SearchInfo } from 'src/app/models/search-info';
import { AppGroupsActions, AuthActions } from 'src/app/store/app.actions';

export const APP_GROUPS_TAG = 'appGroups';

export interface FiltersType {
  communityUUIDList: string;
}

export interface AppGroupsState {
  filters: FiltersType;
  sort: Sort;
  page: PageEvent;
  searchInfo: SearchInfo;
  data: any;
}

const appGroupsInitialState: AppGroupsState = {
  filters: {
    communityUUIDList: undefined,
  },
  sort: { active: 'publicationDate', direction: 'desc' },
  page: { pageIndex: 0, pageSize: 25, length: undefined },
  searchInfo: {
    searchFields: 'name',
    searchFieldsParamName: 'searchFields',
    searchValueParamName: 'q',
  } as SearchInfo,
  data: undefined,
};

const _commReducer = createReducer(
  appGroupsInitialState,
  on(AppGroupsActions.setAppGroupsFilters, (state: AppGroupsState, { filters }) => ({
    ...state,
    filters: {
      ...filters,
      communityUUIDList: (filters as any).communityEntityUUID,
    },
  })),
  on(AppGroupsActions.setAppGroupsSort, (state: AppGroupsState, { sort }) => ({
    ...state,
    sort,
  })),
  on(AppGroupsActions.setAppGroupsPage, (state: AppGroupsState, { page }) => ({
    ...state,
    page,
  })),
  on(AppGroupsActions.setAppGroupsSearchInfo, (state: AppGroupsState, { searchInfo }) => ({
    ...state,
    searchInfo,
  })),
  on(AppGroupsActions.setAppGroupsData, (state: AppGroupsState, { data }) => ({
    ...state,
    data,
  })),
  on(AuthActions.logout, () => ({ ...appGroupsInitialState }))
);

export const appGroupsReducer = (state: AppGroupsState, action: Action) => {
  return _commReducer(state, action);
};
