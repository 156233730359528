import { HttpErrorResponse } from '@angular/common/http';
import { Action, createReducer, on } from '@ngrx/store';
import { Permissions } from 'src/app/interfaces/permissions';
import { User } from 'src/app/models/User';
import { AuthActions } from 'src/app/store/app.actions';

export const TAG = 'auth';

export interface AuthState {
  userData: User;
  authError: HttpErrorResponse;
  userPerm: Permissions[];
}

const initialState: AuthState = {
  userData: undefined,
  authError: undefined,
  userPerm: undefined,
};

const _authReducer = createReducer(
  initialState,
  on(AuthActions.setUserData, (state: AuthState, { userData }) => ({
    ...state,
    userData,
  })),
  on(AuthActions.setPermissions, (state: AuthState, { userPerm }) => ({
    ...state,
    userPerm,
  })),
  on(AuthActions.setAuthError, (state: AuthState, { authError }) => ({
    ...state,
    authError,
    authenticationToken: undefined,
    userData: undefined,
    userPerm: undefined,
  })),
  on(AuthActions.logout, () => ({ ...initialState }))
);

export const AuthReducer = (state: AuthState, action: Action) => {
  return _authReducer(state, action);
};
