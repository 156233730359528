export class FeaturesCloneMoveDetailBuilder {
  private delete: boolean;
  // private actionType: string;

  constructor(private uuidList: any) {}

  get UUIDList() {
    return this.uuidList;
  }

  // get ActionType() {
  //   return this.actionType;
  // }

  get isDelete() {
    return this.delete;
  }

  withDelete(deleteOption: boolean): FeaturesCloneMoveDetailBuilder {
    this.delete = deleteOption;
    return this;
  }

  // withActionType(actionType: string): FeaturesCloneMoveDetailBuilder {
  //   this.actionType = actionType;
  //   return this;
  // }

  build() {
    return this;
  }
}

export class FeaturesCloneMoveDetail {
  static readonly PRODUCT_UUID_LIST_FIELD_KEY = 'uuidList';
  static readonly ARCHIVED_FIELD_KEY = 'archived';
  static readonly ACTIVE_FIELD_KEY = 'active';
  static readonly DELETE_FIELD_KEY = 'delete';
  static readonly ACTION_TYPE_FIELD_KEY = 'actionType';

  private readonly delete: boolean;
  // private readonly actionType: string;
  private readonly uuidList: any;

  constructor(productCloneMoveDetailBuilder: FeaturesCloneMoveDetailBuilder) {
    this.delete = productCloneMoveDetailBuilder.isDelete;
    // this.actionType = productCloneMoveDetailBuilder.ActionType;
    this.uuidList = productCloneMoveDetailBuilder.UUIDList;
  }

  get UUIDList() {
    return this.uuidList;
  }

  // get ActionType() {
  //   return this.actionType;
  // }

  get isDelete() {
    return this.delete;
  }
}
