import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ResourcesReducers } from 'src/app/store/app.reducers';

const getResourcesState = createFeatureSelector<ResourcesReducers.ResourcesState>(ResourcesReducers.TAG);

export const getResourcesFilters = createSelector(getResourcesState, (state) => state?.filters);

export const getResourcesSort = createSelector(getResourcesState, (state) => state?.sort);

export const getResourcesPage = createSelector(getResourcesState, (state) => state?.page);

export const getResourcesExtFilt = createSelector(getResourcesState, (state) => state?.extFilt);

export const getResourcesSearchInfo = createSelector(getResourcesState, (state) => state?.searchInfo);

export const getResourcesData = createSelector(getResourcesState, (state) => state?.data);

export const getCustomizationData = createSelector(getResourcesState, (state) => state?.customizationData);

export const getResourcesStructures = createSelector(getResourcesState, (state) => state?.structures);

export const getGridView = createSelector(getResourcesState, (state) => state?.gridView);
