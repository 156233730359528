import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { Action, createReducer, on } from '@ngrx/store';
import { FiltersConstants } from 'src/app/enums/filters-constants.enum';
import { SearchInfo } from 'src/app/models/search-info';
import { AuthActions, TablesActions } from 'src/app/store/app.actions';

export const TAG = 'tables';

export interface FiltersType {
  isPrivate: true;
  entityFields: string;
  values: string;
  communityUUIDList: string;
}

export interface TablesState {
  filters: FiltersType;
  sort: Sort;
  page: PageEvent;
  extFilt: any;
  searchInfo: SearchInfo;
  data: any;
  tab: any;
}

const initialState: TablesState = {
  filters: {
    isPrivate: true,
    entityFields: 'type',
    values: 'table',
    communityUUIDList: undefined,
  },
  sort: { active: 'status', direction: 'desc' },
  page: { pageIndex: 0, pageSize: 25, length: undefined },
  extFilt: undefined,
  searchInfo: {
    searchFields: 'firstName|lastName',
    searchFieldsParamName: 'searchFields',
    searchValueParamName: 'q',
  } as SearchInfo,
  data: undefined,
  tab: undefined,
};

const _counterReducer = createReducer(
  initialState,
  on(TablesActions.setFilters, (state: TablesState, { filters }) => ({
    ...state,
    filters: {
      ...filters,
      communityUUIDList: filters['communityEntityUUID'] || FiltersConstants.NOT_IN_COMMUNITIES_FILTER_VALUE,
    },
  })),
  on(TablesActions.setSort, (state: TablesState, { sort }) => ({
    ...state,
    sort,
  })),
  on(TablesActions.setPage, (state: TablesState, { page }) => ({
    ...state,
    page,
  })),
  on(TablesActions.setSearchInfo, (state: TablesState, { searchInfo }) => ({
    ...state,
    searchInfo,
  })),
  on(TablesActions.setData, (state: TablesState, { data }) => ({
    ...state,
    data,
  })),
  on(TablesActions.setTab, (state: TablesState, { tab }) => ({
    ...state,
    tab,
  })),
  on(AuthActions.logout, () => ({ ...initialState }))
);

export function tablesReducer(state: TablesState, action: Action) {
  return _counterReducer(state, action);
}
