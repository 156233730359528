import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class EventsEmitterService {
  productsFiltersValue: any;

  readonly productsFiltersChangedEventEmitter: EventEmitter<any> = new EventEmitter<any>();

  emitProductsFiltersChangedEvent(data: any) {
    this.productsFiltersValue = data;
    this.productsFiltersChangedEventEmitter.emit(data);
  }

  getProductsFiltersChangedEventEmitter(): EventEmitter<any> {
    return this.productsFiltersChangedEventEmitter;
  }

  get ProductsFiltersValue() {
    return this.productsFiltersValue;
  }
}
