import { Type } from '../enums';

export class Group {
  constructor(
    public name = '',
    public uuid = '',
    public hiddenProductsByDefault = false,
    public admin = undefined,
    public members = [],
    public attachments = [],
    public communityUUIDList = [],
    public communityUUID = '',
  ) {}
}

export class SmartGroup extends Group {
  constructor(
    public name = '',
    public uuid = '',
    public hiddenProductsByDefault = false,
    public admin = undefined,
    public smartGroupCriteria = undefined,
    public members = [],
    public attachments = [],
    public communityUUIDList = [],
    public communityUUID = '',
  ) {
    super(name, uuid, hiddenProductsByDefault, admin, members, attachments, communityUUIDList, communityUUID);
  }
}

export class GroupRecipient {
  public openedCount = 0;
  public readCount = 0;
  public likesCount = 0;
  public feedbackUser = [];
  public members = [];
  public membersCount = 0;
  public uuid = '';
}
