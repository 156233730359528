import { createAction, props } from '@ngrx/store';
import { SearchInfo } from 'src/app/models/search-info';
import { Sort } from '@angular/material/sort';
import { PageEvent } from '@angular/material/paginator';
import { FiltersType } from './communities.reducer';

export const setData = createAction('[Communities] Set Data', props<{ data: object }>());
export const setSearchInfo = createAction('[Communities] Set search info', props<{ searchInfo: SearchInfo }>());
export const setSort = createAction('[Communities] Set sort', props<{ sort: Sort }>());
export const setPage = createAction('[Communities] Set page', props<{ page: PageEvent }>());
export const setFilters = createAction('[Communities] Set Filters', props<{ filters: FiltersType }>());
export const setUUIDListBulk = createAction('[Communities] toggle bulk', props<{ bulkData: any }>());
export const setBulkAction = createAction('[Communities] toggle bulk action', props<{ bulkAction: any }>());
export const setMultipleUpdateResult = createAction('[Communities] multiple update finished', props<{ multipleData: any }>());
