import { v4 as uuid } from 'uuid';
import { DataleanDataProviderService } from '../provider/datalean-data-provider.service';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { BaseEditorService } from './base-editor.service';
import { concat, Observable, Subject } from 'rxjs';
import { Parts } from '../enums/parts.enum';
import { map, skip } from 'rxjs/operators';
import { OrganizationService } from './organization.service';
import { CommunitiesService } from './communities.service';
import { Community, CommunityValue } from '../models/Community';
import DeepCopy from '../helpers/deep_copy';
import {ParseEntity} from "../helpers/utilities";
import {Asset} from "../models/Asset";

// not an injectable because it is not meant to be reused
@Injectable()
export class CommunityEditorService extends BaseEditorService {
  newFilesAdded = [];
  extraRequiredFields = {};
  extraDisabledFields = {};
  communityEditorSettings;
  config;
  objectData: Community = Object.assign(new Community(),{uuid: uuid(), active: true});

  constructor(
    protected dataleanDataService: DataleanDataProviderService,
    private communitiesService: CommunitiesService,
    private organizationService: OrganizationService
  ) {
    super(dataleanDataService);
    const editorSettingsConfig = this.organizationService.getConfigValue('editorSettings');
    if (editorSettingsConfig?.community) {
      this.communityEditorSettings = editorSettingsConfig.community;
    }
    this.config = this.organizationService.getConfigValue('communityFields');
  }

  get ExtraRequiredFields() {
    return this.extraRequiredFields;
  }

  init() {
    this.objectData = new Community()
    this.objectData.uuid = uuid()
    this.objectData.active = true

    this.newFilesAdded = [];
  }

  create(): Observable<any> {
    const entityParsed = this.clearCommunity()
    if (typeof entityParsed.logo !== 'string') {
      entityParsed.logo = entityParsed.logo.uuid;
    }


    if (!this.newFilesAdded?.length) {
      return this.dataleanDataService.createCommunityWithoutUUID(environment.communityUrl, entityParsed, [
        Parts.ADMIN,
        Parts.VALUES,
        Parts.MEMBERS,
      ]);
    } else {
      const saveCommunitySubject = new Subject();
      this.dataleanDataService
        .createCommunityWithoutUUID(environment.communityUrl, entityParsed, [Parts.ADMIN, Parts.VALUES, Parts.MEMBERS])
        .subscribe({
          next: (data) => {
            const concatNewFilesAdded = [];
            this.communitiesService.dataList.push(data);
            for (const newFileAdded of this.newFilesAdded) {
              const parsedFileAdded = ParseEntity(new Asset(), newFileAdded);
              concatNewFilesAdded.push(this.dataleanDataService.updateEntity(environment.mediaLibraryUrl, parsedFileAdded, [Parts.EMPTY]));
            }
            concat(...concatNewFilesAdded)
              .pipe(skip(concatNewFilesAdded.length - 1))
              .subscribe(
                () => {
                  saveCommunitySubject.next(true);
                },
                (error) => {
                  saveCommunitySubject.error(error);
                }
              );
          },
          error: (error) => {
            saveCommunitySubject.error(error);
          },
        });
      return saveCommunitySubject.asObservable();
    }
  }

  getAvailableCode(code): Observable<any> {
    return this.dataleanDataService.getAvailableCode(code);
  }

  update(): Observable<any> {
    const entityParsed = this.clearCommunity()
    if (typeof entityParsed.logo !== 'string') {
      entityParsed.logo = entityParsed.logo.uuid;
    }

    // entity.members = entity.members?.map((member) => member.uuid);
    return this.dataleanDataService
      .updateEntity(environment.communityUrl, entityParsed, [Parts.ALL])
      .pipe(
        map(
          (data) =>
            (this.communitiesService.dataList[this.communitiesService.dataList.findIndex((community) => community.uuid == data.uuid)] =
              DeepCopy(data) as Community)
        )
      );
  }

  removeUser(uuid: any | string) {
    return this.dataleanDataService.deleteEntity(environment.communityUrl + '/' + this.objectData.uuid + '/member/' + uuid, uuid);
  }

  findExtraField(fieldName, communityFields) {
    let result = null;
    communityFields?.forEach((tab) => {
      tab.fields.find((field) => field.name == fieldName) ? (result = tab.fields.find((field) => field.name == fieldName)) : false;
    });
    return result;
  }

  initExtraFields(communityFields) {
    this.extraRequiredFields = {};
    this.extraDisabledFields = {};
    communityFields.forEach((tab) => {
      tab.fields.forEach((field) => {
        if (field.required) {
          this.extraRequiredFields[field.name] = field;
        }
        if (field.requiredFields && Array.isArray(this.objectData?.values)) {
          const found = this.objectData?.values.find((value) => value.name === field.name);
          if (found) {
            if (!this.notIsValorized(found) && ((field.value && found.value.toString().indexOf(field.value) > -1) || !field.value)) {
              field.requiredFields.forEach((field2) => {
                const requiredField = this.findExtraField(field2, communityFields);
                if (requiredField) {
                  this.extraRequiredFields[requiredField.name] = requiredField;
                }
              });
            }
          }
        }
        if (field.enableFields && Array.isArray(this.objectData?.values)) {
          const found = this.objectData?.values.find((value) => value.name === field.name);
          if (found) {
            if (!(!this.notIsValorized(found) && ((field.value && found.value.toString().indexOf(field.value) > -1) || !field.value))) {
              field.enableFields.forEach((field2) => {
                const enableField = this.findExtraField(field2, communityFields);
                if (enableField) {
                  this.extraDisabledFields[enableField.name] = enableField;
                }
              });
            }
          } else {
            field.enableFields.forEach((field2) => {
              const enableField = this.findExtraField(field2, communityFields);
              if (enableField) {
                this.extraDisabledFields[enableField.name] = enableField;
              }
            });
          }
        }
      });
    });
  }

  notIsValorized(found) {
    return (
      (found &&
        this.dataleanDataService.hasJsonStructure(found.value.toString()) &&
        (Object.keys(JSON.parse(found.value)).length == 0 || JSON.parse(found.value).length == 0)) ||
      (found && found.value.toString().trim().length == 0) ||
      (found && typeof found.value == 'boolean' && !found.value)
    );
  }

  checkIfIsOkExtraFields(fieldName) {
    let communityFieldsError = false;
    let found : CommunityValue | undefined;
    if(Array.isArray(this.objectData?.values))
      found = this.objectData.values.find((value) => value.name === fieldName);

    if (found) {
      this.extraRequiredFields[fieldName].error = this.notIsValorized(found);
    } else {
      this.extraRequiredFields[fieldName].error = true;
    }
    if (this.extraRequiredFields[fieldName].error) {
      communityFieldsError = true;
    } else {
      if (this.extraRequiredFields[fieldName].requiredFields) {
        this.extraRequiredFields[fieldName].requiredFields.forEach((subFieldName) => {
          if (
            !this.notIsValorized(found) &&
            ((this.extraRequiredFields[fieldName].value &&
              found.value.toString().indexOf(this.extraRequiredFields[fieldName].value) > -1) ||
              !this.extraRequiredFields[fieldName].value)
          )
            return this.checkIfIsOkExtraFields(subFieldName);
        });
      }
    }
    return communityFieldsError;
  }

  checkIfIsRequired(name: any) {
    return !!this.extraRequiredFields[name];
  }

  checkIfIsEnabled(name: any) {
    return !this.extraDisabledFields[name];
  }

  checkIfHasError(fieldName) {
    return this.extraRequiredFields[fieldName].error;
  }

  setFieldError(fieldName, value) {
    this.extraRequiredFields[fieldName].error = value;
  }

  clearCommunity(): Community{
    const entityParsed = ParseEntity(new Community(),this.objectData)
    delete entityParsed.audit //campo non mappato a BE
    return ParseEntity(new Community(),this.objectData)
  }
}
