import { createAction, props } from '@ngrx/store';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { SearchInfo } from 'src/app/models/search-info';
import { FiltersType } from './app-contests.reducer';

export const setAppContestsFilters = createAction('[App Contests] set Filters', props<{ filters: FiltersType }>());
export const setAppContestsSort = createAction('[App Contests] set Sort', props<{ sort: Sort }>());
export const setAppContestsPage = createAction('[App Contests] set Page', props<{ page: PageEvent }>());
export const setAppContestsSearchInfo = createAction('[App Contests] set Search Info', props<{ searchInfo: SearchInfo }>());
export const setAppContestsData = createAction('[App Contests] set Data', props<{ data: any }>());
export const updateExtFilt = createAction('[App Contests] Update External Filters', props<{ extFilt: any }>());
