import { Component } from '@angular/core';
import { ModalRef } from '../modalRef';
import { UploadService } from 'src/app/services/upload.service';
@Component({
  selector: 'app-upload-progress',
  templateUrl: './upload-progress.component.html',
  styleUrls: ['./upload-progress.component.scss'],
})
export class UploadProgressComponent {
  constructor(private modalRef: ModalRef, public uploadService: UploadService) {
    this.uploadService.reset();
  }

  close(data?) {
    this.modalRef.close(data);
  }
}
