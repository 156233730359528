import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { User } from 'src/app/models/User';
import { AuthState } from '../pages/login/store/auth.reducer';
import { AuthSelectors } from '../store/app.selectors';
import { ParseEntity } from '../helpers/utilities';
import { Subject, takeUntil } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserSettingsService implements OnDestroy {
  private authenticatedUser: User;
  private isDestroyed$ = new Subject();

  constructor(private store: Store<AuthState>) {
    this.store
      .select(AuthSelectors.getAuthenticatedUserData)
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe((authenticatedUser) => this.buildUser(authenticatedUser));
  }

  ngOnDestroy(): void {
    this.isDestroyed$.next(true);
    this.isDestroyed$.complete();
  }

  private buildUser(storedAuthenticatedUser) {
    let authenticatedUser = storedAuthenticatedUser;
    if (storedAuthenticatedUser) {
      authenticatedUser = ParseEntity(new User(), storedAuthenticatedUser);
    }

    this.authenticatedUser = authenticatedUser;
  }

  get AuthenticatedUser(): User {
    return this.authenticatedUser;
  }

  getUserUUID(): string {
    return this.authenticatedUser?.uuid;
  }

  getUserRolesNames(): string[] {
    return [...(this.authenticatedUser?.roles ?? [])].map((role) => role.name);
  }

  getUserRolesUUID(): string[] {
    return [...(this.authenticatedUser?.roles ?? [])].map((role) => role.uuid);
  }

  getStoredAuthenticatedUserData() {
    return this.authenticatedUser;
  }

  hasAvailableLanguages(searchAvailableLanguages: string) {
    return (
      this.authenticatedUser?.roles.filter(
        (role) =>
          role?.availableLanguages?.filter((availableLanguages) => searchAvailableLanguages == availableLanguages['value']).length > 0,
      ).length > 0
    );
  }
}
