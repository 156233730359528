import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { Action, createReducer, on } from '@ngrx/store';
import { SearchInfo } from 'src/app/models/search-info';
import { MediaLibraryModalActions } from 'src/app/store/app.actions';

export const TAG = 'mediaLibraryModal';

export interface FiltersType {
  parentUUID: string;
  communityUUID: string;
}

export interface MediaLibraryState {
  filters: FiltersType;
  sort: Sort;
  page: PageEvent;
  searchInfo: SearchInfo;
  data: any;
  gridView: boolean;
  breadcrumbs: {
    label: string;
    parentUUID: string;
  }[];
  lastOpenedField: string;
}

export const initialState: MediaLibraryState = {
  filters: {
    parentUUID: undefined,
    communityUUID: undefined,
  },
  sort: { active: 'name', direction: 'asc' },
  page: { pageIndex: 0, pageSize: 15, length: undefined },
  searchInfo: new SearchInfo('name,alias', '', 'searchFields', 'q'),
  data: undefined,
  gridView: true,
  breadcrumbs: [
    {
      label: 'Home',
      parentUUID: undefined,
    },
  ],
  lastOpenedField: undefined,
};

const _counterReducer = createReducer(
  initialState,
  on(MediaLibraryModalActions.updateFilters, (state: MediaLibraryState, { filters }) => ({
    ...state,
    filters: {
      ...filters,
    },
  })),
  on(MediaLibraryModalActions.updateParentFilter, (state: MediaLibraryState, { parentUUID }) => ({
    ...state,
    filters: {
      ...state.filters,
      parentUUID,
    },
  })),
  on(MediaLibraryModalActions.setSort, (state: MediaLibraryState, { sort }) => ({
    ...state,
    sort,
  })),
  on(MediaLibraryModalActions.setPage, (state: MediaLibraryState, { page }) => ({
    ...state,
    page,
  })),
  on(MediaLibraryModalActions.updatePageIndex, (state: MediaLibraryState, { index }) => ({
    ...state,
    page: {
      ...state.page,
      pageIndex: index,
    },
  })),
  on(MediaLibraryModalActions.updateSearchInfo, (state: MediaLibraryState, { searchValue }) => ({
    ...state,
    searchInfo: new SearchInfo(
      state.searchInfo.searchFields,
      searchValue,
      state.searchInfo.searchFieldsParamName,
      state.searchInfo.searchValueParamName
    ),
  })),
  on(MediaLibraryModalActions.setSearchInfo, (state: MediaLibraryState, { searchInfo }) => ({
    ...state,
    searchInfo,
  })),
  on(MediaLibraryModalActions.setData, (state: MediaLibraryState, { data }) => ({
    ...state,
    data,
  })),
  on(MediaLibraryModalActions.setGridView, (state: MediaLibraryState, { gridView }) => ({
    ...state,
    gridView,
  })),
  on(MediaLibraryModalActions.setBreadcrumbs, (state: MediaLibraryState, { breadcrumbs }) => ({
    ...state,
    breadcrumbs,
  })),
  on(MediaLibraryModalActions.addBreadcrumbItem, (state: MediaLibraryState, { breadcrumbItem }) => ({
    ...state,
    breadcrumbs: [...state.breadcrumbs, breadcrumbItem],
  })),
  on(MediaLibraryModalActions.resetState, (state: MediaLibraryState) => ({
    ...initialState,
  }))
);

export function mediaLibraryReducer(state: MediaLibraryState, action: Action) {
  return _counterReducer(state, action);
}
