import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ReservationsReducers } from 'src/app/store/app.reducers';

const getReservationsState = createFeatureSelector<ReservationsReducers.ReservationsState>(ReservationsReducers.TAG);

export const getReservationsFilters = createSelector(getReservationsState, (state) => state?.filters);
export const getReservationsShowFilters = createSelector(getReservationsState, (state) => state?.showFilters);

export const getReservationsSort = createSelector(getReservationsState, (state) => state?.sort);

export const getReservationsPage = createSelector(getReservationsState, (state) => state?.page);

export const getReservationsExtFilt = createSelector(getReservationsState, (state) => state?.extFilt);

export const getReservationsSearchInfo = createSelector(getReservationsState, (state) => state?.searchInfo);

export const getReservationsData = createSelector(getReservationsState, (state) => state?.data);

export const getReservationEventsData = createSelector(getReservationsState, (state) => state?.events);

export const getCustomizationData = createSelector(getReservationsState, (state) => state?.customizationData);

export const getGridView = createSelector(getReservationsState, (state) => state?.gridView);
