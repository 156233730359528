import { Injectable } from '@angular/core';
import { GridOption } from '../enums/grid-option.enum';
import { Permission } from '../enums/permission.enum';
import { ToStandardBooleanPipe } from '../pipes/to-standard-boolean/to-standard-boolean';
import { OrganizationService } from './organization.service';
import { UserPermissionService } from './user-permission.service';
import { EntityStatuses } from '../enums/entity-statuses.enum';

@Injectable({
  providedIn: 'root',
})
export class OptionsService {
  canEditOrderStatus = false;
  disableUpdateForPublished = false;

  constructor(
    private userPermissionService: UserPermissionService,
    private toBooleanPipe: ToStandardBooleanPipe,
    private organizationService: OrganizationService
  ) {
    this.canEditOrderStatus = !!this.userPermissionService.getPermissionByName(Permission.EDIT_ORDER_STATUS.name);
    this.disableUpdateForPublished = this.toBooleanPipe.transform(
      this.organizationService.getConfig('disableUpdateForPublishedCommunication')?.value
    );
  }

  optionPresenceCondition(option, data: { status: EntityStatuses; [key: string]: unknown }) {
    const statusesToEnable: EntityStatuses[] = [EntityStatuses.ARCHIVED, EntityStatuses.DRAFT];
    switch (option) {
      case GridOption.DISABLE:
      case GridOption.REVOKE:
      case GridOption.DEACTIVATE:
        return data.active;
      case GridOption.ENABLE:
      case GridOption.ACTIVATE:
        return !data.active;
      case GridOption.EDIT: {
        if (typeof data?.publicationDate === 'string' && this.disableUpdateForPublished) {
          // data REPRESENTS COMMUNICATION
          const currentData = new Date();
          const communicationPublicationDate = new Date(data?.publicationDate);
          if (currentData > communicationPublicationDate) {
            // COMMUNICATION ALREADY PUBLISHED
            return false;
          }

          return true;
        }
        return true;
      }
      case GridOption.PUBLISH:
        return statusesToEnable.includes(data.status);
      default:
        return true;
    }
  }
}
