import { v4 } from 'uuid';

export class ApplicationUser {
  uuid = v4();
  activationDate = null;
  authenticationMethods = [];
  communityUUIDList = [];
  email = '';
  telephone = null;
  firstName = '';
  isPrivate = false;
  lastName = '';
  resetPasswordToken = null;
  responsible = [];
  roles = [];
  latestLogin = '';
  latestAccess = '';
  status = 'ENABLED';
  systemUser = false;
  username = '';
  structureUUID = '';
  organizationUUID = '';
  smartCoUser = false;
  cataleanUser = false;
  configuratorUser = false;
  [key: string]: any;
}
