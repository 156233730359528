import { Injectable } from '@angular/core';
import { Community } from '../models/Community';
import { DataleanDataProviderService } from '../provider/datalean-data-provider.service';
import { CommunitiesService } from './communities.service';
import { OrganizationService } from './organization.service';

// not an injectable because it is not meant to be reused
@Injectable()
export class CommunityDataManager {
  public readonly PAYMENT_METHOD_PICK_UP_AT_RESTAURANT_FIELD_NAME = 'paymentMethodPickUpAtRestaurant';
  public readonly PAYMENT_METHOD_DELIVERY_TO_ADDRESS_FIELD_NAME = 'paymentMethodDeliveryToAddress';
  public readonly PAYMENT_METHOD_DELIVERY_EASY_ORDER_FIELD_NAME = 'paymentMethodDeliveryEasyOrder';

  public readonly PAYMENT_METHOD_PICK_UP_AT_RESTAURANT_DOMAIN_VALUE_KEY = 'paymentMethodsPickUpAtRestaurant';
  public readonly PAYMENT_METHOD_DELIVERY_TO_ADDRESS_DOMAIN_VALUE_KEY = 'paymentMethodsDeliveryToAddress';
  public readonly PAYMENT_METHOD_DELIVERY_EASY_ORDER_DOMAIN_VALUE_KEY = 'paymentMethodsDeliveryEasyOrder';

  paymentMethodsPickUpAtRestaurantChipsDS = [];
  paymentMethodsDeliveryToAddressChipsDS = [];
  paymentMethodsDeliveryEasyOrderChipsDS = [];
  extraRequiredFields = {};
  extraDisabledFields = {};
  communityEditorSettings: unknown;
  config: unknown;
  objectData: Community;

  constructor(
    protected dataleanDataService: DataleanDataProviderService,
    private communitiesService: CommunitiesService,
    private organizationService: OrganizationService
  ) {
    const editorSettingsConfig = this.organizationService.getConfigValue('editorSettings');
    if (editorSettingsConfig?.community) {
      this.communityEditorSettings = editorSettingsConfig.community;
    }
    this.config = this.organizationService.getConfigValue('communityFields');
  }

  init(communityUUID: string) {
    this.objectData = this.communitiesService.getCommunityData(communityUUID);
  }

  initPaymentMethods() {
    const values = this.objectData.values as { name: string; value?: string }[] | undefined;
    const paymentMethodPickUpAtRestaurant = values?.find((value) => value.name === this.PAYMENT_METHOD_PICK_UP_AT_RESTAURANT_FIELD_NAME);
    if (paymentMethodPickUpAtRestaurant) {
      this.updatePaymentMethodsDataSource(
        this.PAYMENT_METHOD_PICK_UP_AT_RESTAURANT_FIELD_NAME,
        JSON.parse(paymentMethodPickUpAtRestaurant.value)
      );
    }
    const paymentMethodDeliveryToAddress = values?.find((value) => value.name === this.PAYMENT_METHOD_DELIVERY_TO_ADDRESS_FIELD_NAME);
    if (paymentMethodDeliveryToAddress) {
      this.updatePaymentMethodsDataSource(
        this.PAYMENT_METHOD_DELIVERY_TO_ADDRESS_FIELD_NAME,
        JSON.parse(paymentMethodDeliveryToAddress.value)
      );
    }
    const paymentMethodDeliveryEasyOrder = values?.find((value) => value.name === this.PAYMENT_METHOD_DELIVERY_EASY_ORDER_FIELD_NAME);
    if (paymentMethodDeliveryEasyOrder) {
      this.updatePaymentMethodsDataSource(
        this.PAYMENT_METHOD_DELIVERY_EASY_ORDER_FIELD_NAME,
        JSON.parse(paymentMethodDeliveryEasyOrder.value)
      );
    }
  }

  updatePaymentMethodsDataSource(paymentMethodFieldName: string, paymentMethodsDataSource: any[]) {
    if (paymentMethodFieldName) {
      if (paymentMethodFieldName === this.PAYMENT_METHOD_PICK_UP_AT_RESTAURANT_FIELD_NAME) {
        this.paymentMethodsPickUpAtRestaurantChipsDS = paymentMethodsDataSource;
      } else if (paymentMethodFieldName === this.PAYMENT_METHOD_DELIVERY_TO_ADDRESS_FIELD_NAME) {
        this.paymentMethodsDeliveryToAddressChipsDS = paymentMethodsDataSource;
      } else if (paymentMethodFieldName === this.PAYMENT_METHOD_DELIVERY_EASY_ORDER_FIELD_NAME) {
        this.paymentMethodsDeliveryEasyOrderChipsDS = paymentMethodsDataSource;
      }
    }
  }

  getPaymentMethodsDataSource(paymentMethodFieldName: string): any[] {
    let paymentMethodsDataSource = [];
    if (paymentMethodFieldName) {
      if (paymentMethodFieldName === this.PAYMENT_METHOD_PICK_UP_AT_RESTAURANT_FIELD_NAME) {
        paymentMethodsDataSource = this.paymentMethodsPickUpAtRestaurantChipsDS;
      } else if (paymentMethodFieldName === this.PAYMENT_METHOD_DELIVERY_TO_ADDRESS_FIELD_NAME) {
        paymentMethodsDataSource = this.paymentMethodsDeliveryToAddressChipsDS;
      } else if (paymentMethodFieldName === this.PAYMENT_METHOD_DELIVERY_EASY_ORDER_FIELD_NAME) {
        paymentMethodsDataSource = this.paymentMethodsDeliveryEasyOrderChipsDS;
      }
    }

    return paymentMethodsDataSource;
  }

  public getDomainValuesKeyForField(paymentMethodFieldName: string) {
    let domainValuesKeyForField: string;
    if (paymentMethodFieldName) {
      if (paymentMethodFieldName === this.PAYMENT_METHOD_PICK_UP_AT_RESTAURANT_FIELD_NAME) {
        domainValuesKeyForField = this.PAYMENT_METHOD_PICK_UP_AT_RESTAURANT_DOMAIN_VALUE_KEY;
      } else if (paymentMethodFieldName === this.PAYMENT_METHOD_DELIVERY_TO_ADDRESS_FIELD_NAME) {
        domainValuesKeyForField = this.PAYMENT_METHOD_DELIVERY_TO_ADDRESS_DOMAIN_VALUE_KEY;
      } else if (paymentMethodFieldName === this.PAYMENT_METHOD_DELIVERY_EASY_ORDER_FIELD_NAME) {
        domainValuesKeyForField = this.PAYMENT_METHOD_DELIVERY_EASY_ORDER_DOMAIN_VALUE_KEY;
      }
    }

    return domainValuesKeyForField;
  }
}
