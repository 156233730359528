import { ObjectValuesType } from '../helpers/utilities';

export const StructureType = {
  PRODUCT: 'product',
  COMMUNICATION: 'communication',
  COCKPIT: 'cockpit',
  BOOKING: 'booking',
  WISHLIST: 'wishlist',
  CONTENT: 'content',
  RESOURCE: 'resource',
  BOOKABLE_PRODUCT: 'bookable_product',
  RESERVATION: 'reservation'
} as const;

export type StructureType = ObjectValuesType<typeof StructureType>;

export const StructurePerm = {
  PRODUCT: 'read_products',
  COMMUNICATION: 'read_communications',
  COCKPIT: 'read_cockpit_communications',
  BOOKING: 'read_orders',
  WISHLIST: 'read_wishlists',
  CONTENT: 'read_contents',
  RESOURCE: 'read_resources',
  BOOKABLE_PRODUCT: 'read_bookable_products',
  RESERVATION: 'read_reservations'
} as const;

export type StructurePerm = ObjectValuesType<typeof StructurePerm>;
