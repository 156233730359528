import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { Action, createReducer, on } from '@ngrx/store';
import { SearchInfo } from 'src/app/models/search-info';
import { AppUsersActions, AuthActions } from 'src/app/store/app.actions';

export const APP_USERS_TAG = 'appUsers';

export interface FiltersType {
  communityUUIDList: string;
  communityUUID?: string;
  entityFields: string;
  values: string;
}

export interface AppUsersState {
  filters: FiltersType;
  sort: Sort;
  page: PageEvent;
  searchInfo: SearchInfo;
  data: any;
  bulkData: any;
  bulkAction: any;
  multipleData: any;
}

const appUsersInitialState: AppUsersState = {
  filters: {
    communityUUIDList: undefined,
    entityFields: encodeURIComponent('type#not'),
    values: 'table',
  },
  sort: { active: 'firstName', direction: 'asc' },
  page: { pageIndex: 0, pageSize: 25, length: undefined },
  searchInfo: {
    searchFields: 'firstName,lastName,email,username',
    searchFieldsParamName: 'searchFields',
    searchValueParamName: 'q',
  } as SearchInfo,
  data: undefined,
  bulkData: undefined,
  bulkAction: undefined,
  multipleData: undefined,
};

const _commReducer = createReducer(
  appUsersInitialState,
  on(AppUsersActions.setAppUsersFilters, (state: AppUsersState, { filters }) => ({
    ...state,
    filters: {
      ...filters,
      communityUUIDList: (filters as any).communityEntityUUID,
    },
  })),
  on(AppUsersActions.setAppUsersSort, (state: AppUsersState, { sort }) => ({
    ...state,
    sort,
  })),
  on(AppUsersActions.setAppUsersPage, (state: AppUsersState, { page }) => ({
    ...state,
    page,
  })),
  on(AppUsersActions.setAppUsersSearchInfo, (state: AppUsersState, { searchInfo }) => ({
    ...state,
    searchInfo,
  })),
  on(AppUsersActions.setAppUsersData, (state: AppUsersState, { data }) => ({
    ...state,
    data,
    // page: { ...state.page, length: data.resultInfo.totalNumberOfElements }
  })),
  on(AppUsersActions.setAppUsersUUIDListBulk, (state: AppUsersState, { bulkData }) => ({
    ...state,
    bulkData,
  })),
  on(AppUsersActions.setAppUsersBulkAction, (state: AppUsersState, { bulkAction }) => ({
    ...state,
    bulkAction,
  })),
  on(AppUsersActions.setMultipleUpdateResult, (state: AppUsersState, { multipleData }) => ({
    ...state,
    multipleData,
  })),
  on(AuthActions.logout, () => ({ ...appUsersInitialState })),
);

export const appUsersReducer = (state: AppUsersState, action: Action) => {
  return _commReducer(state, action);
};
