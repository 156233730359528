import { Injectable } from '@angular/core';
import { map, switchMap } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { MediaLibraryModalActions } from 'src/app/store/app.actions';

@Injectable()
export class MediaLibraryModalEffects {
  constructor(private actions$: Actions) {}

  setFilters$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MediaLibraryModalActions.setFilters),
      map(({ filters }) =>
        MediaLibraryModalActions.updateFilters({
          filters: {
            ...filters,
            communityUUID: (filters as any).communityEntityUUID,
          },
        })
      )
    )
  );

  resetPaginationAndSearchInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MediaLibraryModalActions.updateFilters, MediaLibraryModalActions.updateParentFilter, MediaLibraryModalActions.setSearchInfo),
      switchMap((action: any) => {
        const returnArray: any[] = [MediaLibraryModalActions.updatePageIndex({ index: 0 })];
        if (!action.searchInfo) {
          returnArray.push(MediaLibraryModalActions.updateSearchInfo({ searchValue: '' }));
        }
        return returnArray;
      })
    )
  );

  setParenUUIDafterChangingFolder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MediaLibraryModalActions.addBreadcrumbItem, MediaLibraryModalActions.setBreadcrumbs),
      map((action: any) => {
        if (action.breadcrumbs) {
          return MediaLibraryModalActions.updateParentFilter({
            parentUUID: action.breadcrumbs[action.breadcrumbs?.length - 1]?.parentUUID,
          });
        }
        if (action.breadcrumbItem) {
          return MediaLibraryModalActions.updateParentFilter({ parentUUID: action.breadcrumbItem.parentUUID });
        }
      })
    )
  );
}
