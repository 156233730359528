import { createFeatureSelector, createSelector } from '@ngrx/store';
import { FiltersTypeValues } from '../../../enums';
import { CockpitState, COCKPIT_TAG } from './cockpit.reducer';

const getState = createFeatureSelector<CockpitState>(COCKPIT_TAG);

export const getFilters = createSelector(getState, (state) => state?.filters);
export const getFiltersValue = createSelector(getState, (state) =>
  Object.entries(state?.filters).reduce((acc, [key, value]) => ({ ...acc, [key]: value?.value }), {} as FiltersTypeValues)
);
export const getSort = createSelector(getState, (state) => state?.sort);
export const getPage = createSelector(getState, (state) => state?.page);
export const getSearchInfo = createSelector(getState, (state) => state?.searchInfo);
export const getData = createSelector(getState, (state) => state?.data);
export const getStructures = createSelector(getState, (state) => state?.structures);

export const getLoading = createSelector(getState, (state) => state.loading);
