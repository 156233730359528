import { createFeatureSelector, createSelector } from "@ngrx/store";
import { LmsState } from "./lms.reducer";

const Tag = 'lms'

const getLmsState = createFeatureSelector<LmsState>(Tag);

export const getLmsOnSave = createSelector(
  getLmsState,
  (state) => state?.onSave
);
