import { Pipe, PipeTransform } from '@angular/core';
import { LocalizableField } from 'src/app/models/LocalizableField';
import { LocalizationService } from 'src/app/services/localization.service';
@Pipe({
  name: 'toLocalizedValuePipe',
  pure: false,
})
export class ToLocalizedValuePipe implements PipeTransform {
  constructor(private localizationService: LocalizationService) {}

  transform(value: any, forcedLocale?: string) {
    if (value === undefined || value === null) {
      return '';
    }
    const defaultLan = this.localizationService.getDefaultLang();
    const activeLan = forcedLocale ?? this.localizationService.getActiveEntityLanguage();

    if (value instanceof LocalizableField) {
      return value.getLocalizedValue(activeLan, defaultLan);
    } else if (
      typeof value === 'string' ||
      value instanceof String ||
      typeof value === 'number' ||
      value instanceof Number ||
      typeof value === 'boolean' ||
      value.assetType
    ) {
      return value;
    } else if (Array.isArray(value)) {
      const activeLanValue = value.find((v) => v.language === activeLan)?.value;
      return (activeLanValue ?? value.find((v) => v.language === defaultLan)?.value) || '';
    } else {
      return value?.value ?? value?.[activeLan] ?? value?.[defaultLan] ?? '';
    }
  }
}
