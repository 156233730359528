import { PageEvent } from "@angular/material/paginator";

export class DataleanEntity {
  constructor(
    public uuid?: string,
    public active?: boolean,
    public organizationUUID?: string,
    public localizedValues?: { locale: string }[],
    public structureUUID?: string,
    public updateDate?: string | Date,
    public createDate?: string | Date
  ) {}
}

export interface IPaginationData {
  totalCount: number;
  pageSize: number;
  pageIndex: number;
}
export interface DataleanPaginatedEntity<TEntity> {
  result: TEntity[] | { [key: string]: TEntity };
  paginationInfo?: PageEvent;
}
