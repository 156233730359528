import { Action, createReducer, on } from '@ngrx/store';
import { WorkFlowConfig, WorkFlowEntity } from 'src/app/models/WorkFlow';
import { AuthActions, WorkFlowActions } from 'src/app/store/app.actions';

export const TAG = 'workflow';

export interface WorkFlowState {
  workFlowConfig: WorkFlowConfig[];
  workFlowInstance?: WorkFlowEntity[];
}

const initialState: WorkFlowState = {
  workFlowConfig: [],
  workFlowInstance: undefined,
};

const reducer = createReducer(
  initialState,
  on(WorkFlowActions.setWorkFlowConfig, (state: WorkFlowState, { workFlowConfig = [] }) => ({
    ...state,
    workFlowConfig,
  })),
  on(WorkFlowActions.setWorkFlowInstance, (state: WorkFlowState, { workFlowInstance = [] }) => ({
    ...state,
    workFlowInstance,
  })),
  on(WorkFlowActions.resetWorkFlow, () => structuredClone({ ...initialState })),
  on(AuthActions.logout, () => structuredClone({ ...initialState })),
);

export function WorkFlowReducer(state: WorkFlowState, action: Action) {
  return reducer(state, action);
}
