import { GlobalPositionStrategy } from '@angular/cdk/overlay';
import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { Store } from '@ngrx/store';
import { TranslatePipe } from '@ngx-translate/core';
import { of } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { theme } from '../profiles/default/theme.custom';
import { defaultTheme } from '../profiles/default/theme.default';
import { AuthService } from './auth.service';
import { MessageComponent } from './components/modal/message/message.component';
import { ModalService } from './components/modal/modal.service';
import { CanDeactivateState } from './guards/can-deactivate.guard';
import { LocalizationService } from './services/localization.service';
import { UserSettingsService } from './services/user-settings-service.service';
import { AuthSelectors, BulkSelectors } from './store/app.selectors';
import { Theme } from './theme/theme';
import { ThemeService } from './theme/theme.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'datalean-fe';

  constructor(
    private titleService: Title,
    private translatePipe: TranslatePipe,
    private modalService: ModalService,
    private themeService: ThemeService,
    private swUpdate: SwUpdate,
    private authService: AuthService,
    private store: Store,
    private languageService: LocalizationService,
    private userService: UserSettingsService,
    router: Router
  ) {
    if (theme) {
      this.themeService.setTheme(theme as Theme);
    } else {
      this.themeService.setTheme(defaultTheme);
    }
    this.titleService.setTitle(theme?.name || defaultTheme.name);

    //this part of code is a chicca to manage browser navigation with back button
    window.onpopstate = () => (CanDeactivateState.defendAgainstBrowserBackButton = true);

    // Upon successful navigation, ensure that the CanDeactivateGuard no longer defends against back button clicks
    router.events
      .pipe(
        filter((e) => e instanceof NavigationEnd),
        tap(() => (CanDeactivateState.defendAgainstBrowserBackButton = false))
      )
      .subscribe();

    if (this.swUpdate.isEnabled) {
      this.swUpdate.versionUpdates.subscribe((versionEvent) => {
        if (versionEvent.type !== 'NO_NEW_VERSION_DETECTED') {
          this.modalService
            .open({
              component: MessageComponent,
              canExitThroughBackdropClick: true,
              class: 'select-template-container',
              data: {
                message: this.translatePipe.transform('PWA.UPDATE_AVAILABLE.MESSAGE'),
                title: this.translatePipe.transform('PWA.UPDATE_AVAILABLE.TITLE'),
              },
              position: new GlobalPositionStrategy().centerHorizontally().centerVertically(),
            })
            .onDidClose.subscribe((result) => {
              if (result.data === 'ok') {
                window.location.reload();
              }
            });
        }
      });
    }

    this.store.select(BulkSelectors.getData).subscribe((result) => {
      if (result && Array.isArray(result) && Object.keys(result[1]).length) {
        const count = Object.keys(result[1]).filter((uuid) => uuid.trim().length).length;
        this.modalService.open({
          component: MessageComponent,
          canExitThroughBackdropClick: true,
          class: 'select-template-container',
          data: {
            message: result[0],
            title: this.translatePipe.transform('MODALS.BULK_ACTIONS.MESSAGE.STATISTICS'),
            // code: JSON.stringify(result.result,undefined, 2)
            code:
              this.translatePipe.transform('MODALS.BULK_ACTIONS.MESSAGE.ELEMENTS_MODIFIED') +
              ' ' +
              count +
              '<br>' +
              this.translatePipe.transform('MODALS.BULK_ACTIONS.MESSAGE.ELEMENTS_COUNT') +
              ' ' +
              Object.keys(result[1]).length,
          },
          position: new GlobalPositionStrategy().centerHorizontally().centerVertically(),
        });
      }
    });

    this.authService.isAuthenticated$
      .pipe(
        switchMap((isAuthenticated) => {
          if (!isAuthenticated) {
            return of();
          }
          return this.store.select(AuthSelectors.getAuthenticatedUserData);
        }),
        filter((user) => !!user),
        map(() => this.userService.AuthenticatedUser),
        take(1)
      )
      .subscribe((user) => {
        //per evitare di fare il set di un dato che potremmo non avere, che scatena il set dell'inglese come fallback
        //https://github.com/ngx-translate/core/pull/336
        if (user.language) this.languageService.setActiveAppLanguage(user.language);
      });
  }
}
