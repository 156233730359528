import { Component, OnInit, ViewChild } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { switchMap, zip } from 'rxjs';
import { SubjectType } from 'src/app/enums';
import { Parts } from 'src/app/enums/parts.enum';
import { Card } from 'src/app/interfaces/card';
import { ExceptionObject } from 'src/app/interfaces/exception';
import { DataleanDataProviderService } from 'src/app/provider/datalean-data-provider.service';
import { LocalizationService } from 'src/app/services/localization.service';
import { environment } from 'src/environments/environment';
import { ModalRef } from '../modalRef';

@Component({
  selector: 'app-view-permission',
  templateUrl: './view-permission.component.html',
  styleUrls: ['./view-permission.component.scss'],
})
export class ViewPermissionComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;

  readonly cards = [
    { title: 'GROUPS', order: 0, fields: [], icon: 'u' },
    { title: 'SMART_GROUPS', order: 1, fields: [], icon: 'u' },
  ];

  private subjectType: SubjectType = SubjectType.APPLICATION_GROUP;
  private getUrl = environment.groupsUrl;

  exceptionList: { [subject: string]: Array<ExceptionObject> } = {};
  tableDataSource = new MatTableDataSource();
  isLoading = true;
  EXCEPTION_URL: string;
  entityType: string;
  entityUrl: string;
  entityUUID: string;
  selectedTab: number;

  constructor(
    private modalRef: ModalRef,
    private dataleanDataService: DataleanDataProviderService,
    private localizationService: LocalizationService
  ) {
    this.entityUrl = this.modalRef.data.entityUrl;
    this.entityUUID = this.modalRef.data.entityUUID;
    const splitted = this.entityUrl.split('/');
    this.entityType = splitted[splitted.length - 2];
  }

  ngOnInit(): void {
    this.EXCEPTION_URL = this.entityUrl + this.entityUUID + '/permission';
    zip(
      this.dataleanDataService.getDataForList(environment.applicationUsersUrl, undefined, [Parts.EMPTY]),
      this.dataleanDataService.getAllEntities<ExceptionObject & { _id?: string }>(this.EXCEPTION_URL, [Parts.ALL], {
        subjectType: SubjectType.APPLICATION_SMART_GROUP,
        locale: this.localizationService.getActiveAppLanguage(),
      })
    )
      .pipe(
        switchMap((resultArray) => {
          const exceptions = resultArray[1].result.map((r) => {
            delete r._id;
            return r;
          });

          if (!this.exceptionList[SubjectType.APPLICATION_SMART_GROUP]) {
            this.exceptionList[SubjectType.APPLICATION_SMART_GROUP] = [];
          }

          this.exceptionList[SubjectType.APPLICATION_SMART_GROUP] = exceptions
            .filter((f) => !this.exceptionList[SubjectType.APPLICATION_SMART_GROUP].some((s) => s.subjectUUID === f.subjectUUID))
            .concat(this.exceptionList[SubjectType.APPLICATION_SMART_GROUP]);

          return zip(
            this.dataleanDataService.getDataForList(environment.groupsUrl, undefined, [Parts.EMPTY]),
            this.dataleanDataService.getAllEntities<ExceptionObject & { _id?: string }>(this.EXCEPTION_URL, [Parts.ALL], {
              subjectType: SubjectType.APPLICATION_GROUP,
              locale: this.localizationService.getActiveAppLanguage(),
            })
          );
        })
      )
      .subscribe({
        next: (resultArray) => {
          const exceptions = resultArray[1].result.map((r) => {
            delete r._id;
            return r;
          });

          if (!this.exceptionList[SubjectType.APPLICATION_GROUP]) {
            this.exceptionList[SubjectType.APPLICATION_GROUP] = [];
          }

          this.exceptionList[SubjectType.APPLICATION_GROUP] = exceptions
            .filter((f) => !this.exceptionList[SubjectType.APPLICATION_GROUP].some((s) => s.subjectUUID === f.subjectUUID))
            .concat(this.exceptionList[SubjectType.APPLICATION_GROUP]);

          const groups = resultArray[0].result as Array<Record<string, string>>;
          groups.sort((a, b) => (a.name < b.name ? -1 : 1));

          this.tableDataSource = new MatTableDataSource(groups);
          this.tableDataSource.paginator = this.paginator;
          this.tableDataSource._updateChangeSubscription();
          this.isLoading = false;
        },
        error: (err) => {
          console.error(err);
          this.isLoading = false;
        },
      });
  }

  updateList() {
    this.isLoading = true;

    zip(
      this.dataleanDataService.getDataForList(this.getUrl, undefined, [Parts.EMPTY]),
      this.dataleanDataService.getAllEntities<ExceptionObject & { _id?: string }>(this.EXCEPTION_URL, [Parts.ALL], {
        subjectType: this.subjectType,
        locale: this.localizationService.getActiveAppLanguage(),
      })
    ).subscribe({
      next: (resultArray) => {
        const exceptions = resultArray[1].result.map((r) => {
          delete r._id;
          return r;
        });

        if (!this.exceptionList[this.subjectType]) {
          this.exceptionList[this.subjectType] = [];
        }

        this.exceptionList[this.subjectType] = exceptions
          .filter((f) => !this.exceptionList[this.subjectType].some((s) => s.subjectUUID === f.subjectUUID))
          .concat(this.exceptionList[this.subjectType]);

        const groups = resultArray[0].result as Array<Record<string, string>>;
        groups.sort((a, b) => (a.name < b.name ? -1 : 1));

        this.tableDataSource = new MatTableDataSource(groups);
        this.tableDataSource.paginator = this.paginator;
        this.tableDataSource._updateChangeSubscription();
        this.isLoading = false;
      },
      error: (err) => {
        console.error(err);
        this.isLoading = false;
      },
    });
  }

  onCancel() {
    this.modalRef.close();
  }

  onSave() {
    this.isLoading = true;

    this.dataleanDataService
      .createEntity(
        this.EXCEPTION_URL,
        Object.values(this.exceptionList).flatMap((v) => v),
        [Parts.ALL]
      )
      .subscribe({
        next: () => {
          this.modalRef.close();
        },
        error: (err) => {
          console.error(err);
        },
        complete: () => {
          this.isLoading = false;
        },
      });
  }

  updateExceptionList(checkboxEvent: MatCheckboxChange, uuid: string) {
    const list = this.exceptionList[this.subjectType];
    const exception = list.find((f) => f.entityUUID === this.entityUUID && f.subjectUUID === uuid);
    if (exception) {
      exception.canView = checkboxEvent.checked;
    } else {
      list.push({
        entityUUID: this.entityUUID,
        entityType: this.entityType,
        canView: checkboxEvent.checked,
        subjectUUID: uuid,
        subjectType: this.subjectType,
      });
    }
  }

  isChecked(uuid: string) {
    const exception = this.exceptionList[this.subjectType].find((f) => f.entityUUID === this.entityUUID && f.subjectUUID === uuid);
    if (!exception) {
      return true;
    }
    return exception.canView;
  }

  trackByFn<T extends Card>(index: number, item: T) {
    return item?.title;
  }

  changeTab($event: MatTabChangeEvent) {
    if ($event.index === 0) {
      this.subjectType = SubjectType.APPLICATION_GROUP;
      this.getUrl = environment.groupsUrl;
    } else {
      this.subjectType = SubjectType.APPLICATION_SMART_GROUP;
      this.getUrl = environment.smartGroupsUrl;
    }
    this.updateList();
  }
}
