import { Injectable } from '@angular/core';
import { catchError, map, switchMap, take } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { CommunitySelectActions } from 'src/app/store/app.actions';
import { Store } from '@ngrx/store';
import { CommunitySelectSelectors } from '../app.selectors';
import { FiltersConstants } from 'src/app/enums/filters-constants.enum';
import { of } from 'rxjs';

@Injectable()
export class CommunitySelectEffects {
  constructor(private store: Store, private actions$: Actions) { }

  setShowNoCommunityChoice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CommunitySelectActions.setShowNoCommunityChoice),
      switchMap((showNoCommunityChoice) =>
        this.store.select(CommunitySelectSelectors.getLastCommunitySelected).pipe(
          take(1),
          catchError(() => of()),
          map((lastCommunitySelected) => {
            if (lastCommunitySelected === undefined && showNoCommunityChoice) {
              this.store.dispatch(CommunitySelectActions.setLastCommunitySelected({
                  lastCommunitySelected: FiltersConstants.NO_COMMUNITY_SELECT_VALUE,
                })
              );
            }
            return showNoCommunityChoice;
          })
        )
      ),
      map(({ showNoCommunityChoice }) =>
        CommunitySelectActions.updateShowNoCommunityChoice({
          showNoCommunityChoice,
        })
      )
    )
  );
}
