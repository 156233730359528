import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CommunitySelectReducers } from '../app.reducers';

const getCommunitySelectorState = createFeatureSelector<CommunitySelectReducers.CommunitySelectState>(CommunitySelectReducers.TAG);

export const getFilters = createSelector(getCommunitySelectorState, (state) => state?.filters);

export const getLastCommunitySelected = createSelector(getCommunitySelectorState, (state) => state?.lastCommunitySelected);

export const getShowNoCommunityChoice = createSelector(getCommunitySelectorState, (state) => state?.showNoCommunityChoice);

export const getData = createSelector(getCommunitySelectorState, (state) => state?.data);

export const getStatus = createSelector(getCommunitySelectorState, (state) => state?.status);
