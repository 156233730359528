import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { createAction, props } from '@ngrx/store';
import { FiltersType, FiltersTypeValues } from '../../../enums';
import { FeatureValue, SearchInfo, Structure } from '../../../models';

export const setFilters = createAction('[Products] Set Filters', props<{ filters: FiltersType }>());
export const setFiltersValues = createAction('[Products] Set Filters', props<{ filters: FiltersTypeValues }>());
export const setSort = createAction('[Products] Set Sort', props<{ sort: Sort }>());
export const setPage = createAction('[Products] Set Page', props<{ page: PageEvent }>());
export const setExtFilt = createAction('[Products] Set External Filters', props<{ extFilt: any }>());
export const updateExtFilt = createAction('[Products] Update External Filters', props<{ extFilt: any }>());
export const setSearchInfo = createAction('[Products] Set Search Info', props<{ searchInfo: SearchInfo }>());
export const setData = createAction('[Products] Set Products Data', props<{ data: object }>());
export const setCustomizationData = createAction('[Products] Set Customization Data', props<{ customizationData: object }>());
export const setFeatures = createAction('[Products] Set Products Features', props<{ features: Array<FeatureValue> }>());
export const setProductStructures = createAction('[Products] Set Products Structures', props<{ structures: Array<Structure> }>());
export const setGridView = createAction('[Products] Set Products Grid View', props<{ gridView: boolean }>());
export const setTab = createAction('[Products] set tab', props<{ tab: any }>());
export const setMultipleUpdateResult = createAction('[Products] multiple update finished', props<{ multipleData: any }>());
export const setProductsBulkAction = createAction('[Products] toggle bulk action', props<{ bulkAction: any }>());
export const setProductsUUIDListBulk = createAction('[Products] toggle bulk', props<{ bulkData: any }>());
export const setMultipleUpdateResultCustomization = createAction(
  '[Products] multiple update finished customization',
  props<{ multipleDataCustomization: any }>()
);
export const setBulkActionCustomization = createAction(
  '[Products] toggle bulk action customization',
  props<{ bulkActionCustomization: any }>()
);
export const setUUIDListBulkCustomization = createAction('[Products] toggle bulk customization', props<{ bulkDataCustomization: any }>());

export const setWishlistData = createAction('[Wishlist] set Wishlist Data', props<{ data: any }>());
export const setWishlistPage = createAction('[Wishlist] set Wishlist Page', props<{ page: PageEvent }>());
export const setWishlistSort = createAction('[Wishlist] Set Wishlist Sort', props<{ sort: Sort }>());
export const setWishlistFilters = createAction('[Wishlist] Set Wishlist Filters', props<{ filters: FiltersTypeValues }>());
export const setWishlistSearchInfo = createAction('[Wishlist] Set Wishlist Search Info', props<{ searchInfo: SearchInfo }>());
export const setWishlistStructures = createAction('[Wishlist] Set Wishlist Structures', props<{ structures: Array<Structure> }>());
export const setWishListsBulkAction = createAction('[Wishlist] toggle bulk action', props<{ bulkAction: any }>());
export const setWishUUIDListBulk = createAction('[Wishlist] toggle bulk', props<{ bulkData: any }>());

export const updateTabIndex = createAction('[Products] Update Tab Index', props<{ activeTabIndex: number }>());
