export interface RelatedPositionValue {
  relatedProductUUID?: string;
  fileField?: string;
  positionX?: string | number;
  positionY?: string | number;
  positionZ?: string | number;
  rotationX?: string | number;
  rotationY?: string | number;
  rotationZ?: string | number;
}
