import { SurveyQuestion } from './survery-question';
import {LocalizableField} from '../models/LocalizableField';


export class Survey {

  //mantenere le proprietà all'interno del costruttore (altrimenti ParseEntity non è in grado di utilizzare correttamente la struttura della classe)
  constructor(
    public name: LocalizableField = new LocalizableField(),
    public uuid?: string,
    public questionList: SurveyQuestion[] = [],
    public communityUUID: string | null = null
  ){}
}
