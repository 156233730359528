import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SmartHttpModuleService {

  constructor(private http: HttpClient) {
  }

  get(url: string, options?): Observable<any> {
    if (options) {
      return  this.http.get(url, options);
    }
    return  this.http.get(url);
  }

  post(url: string, data: any, options?): Observable<any> {
    if (options){
      return this.http.post<any>(url, data, options);
    }
    return this.http.post<any>(url, data);
  }

  put(url: string, data: any, options?): Observable<any> {
    if (options){
      return this.http.put<any>(url, data, options);
    }
    return this.http.put<any>(url, data);
  }

  patch(url: string, data: any, options?): Observable<any> {
    if (options){
      return this.http.patch<any>(url, data, options);
    }
    return this.http.patch<any>(url, data);
  }

  delete(url: string): Observable<any> {
    return this.http.delete<any>(url);
  }
}
