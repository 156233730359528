import { environment } from 'src/environments/environment';
import { v4 } from 'uuid';
import { StructureFieldTypes } from '../enums/structure-field-types.enum';
import { LocalizableField } from './LocalizableField';

export enum ProductQuestionType {
  QUESTION = 'QUESTION', // Domanda singola, la risposta è la risposta alle domande successive
  QUESTIONS = 'QUESTIONS', // Serie di domande, la risposta è l'array di risposta alle domande
  ANSWER = 'ANSWER', // Risposta singola, in array si usa OR
  ANSWERS = 'ANSWERS', // Risposte multiple, in array si usa AND
}

export enum ProductQuestionInterface {
  QUESTION = 'Single Question', // Domanda singola, la risposta è la risposta alle domande successive
  QUESTIONS = 'Multiple Question', // Serie di domande, la risposta è l'array di risposta alle domande
  ANSWER = 'Single Answer', // Risposta singola, in array si usa OR
  ANSWERS = 'Multiple Answer', // Risposte multiple, in array si usa AND
}

interface BaseQuestion {
  uuid: string;
  label: LocalizableField;
  value: string;
  type: ProductQuestionType;
  order: number;
  organizationUUID: string;
}

export class ProductQuestion implements BaseQuestion {
  uuid: string;
  label: LocalizableField;
  value: string;
  type: ProductQuestionType;
  answers: Array<ProductAnswer>;
  order: number;
  organizationUUID: string;

  constructor() {
    this.uuid = v4();
    this.label = LocalizableField.createWithLocalizedValue({ language: environment.activeLanguage, value: 'new' });
    this.value = '';
    this.type = ProductQuestionType.QUESTION;
    this.answers = new Array<ProductAnswer>();
    this.order = 0;
    this.organizationUUID = environment.organizationUUID;
  }
}

export class ProductAnswer extends ProductQuestion {
  isRelated = false;
  isSectionController = false;
  relatedSection?: string = undefined;
  fieldType: StructureFieldTypes = StructureFieldTypes.TEXT;
  validatorList: Record<string, string> = {};

  constructor(public parentUUID?: string) {
    super();
    this.type = ProductQuestionType.ANSWER;
  }
}
