import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SurveyReducers } from 'src/app/store/app.reducers';

const getSurveyState = createFeatureSelector<SurveyReducers.SurveyState>(SurveyReducers.SURVEY_TAG);

export const getSurveyFilters = createSelector(getSurveyState, (state) => state?.filters);
export const getSurveySort = createSelector(getSurveyState, (state) => state?.sort);
export const getSurveyPage = createSelector(getSurveyState, (state) => state?.page);
export const getSurveySearchInfo = createSelector(getSurveyState, (state) => state?.searchInfo);
export const getSurveyData = createSelector(getSurveyState, (state) => state?.data);
export const getSurveyExtFilt = createSelector(getSurveyState, (state) => state?.extFilt);
