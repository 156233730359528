import { createFeatureSelector, createSelector } from '@ngrx/store';
import { VersionSelectionModalReducers } from 'src/app/store/app.reducers';

const getVersionSelectionState = createFeatureSelector<VersionSelectionModalReducers.VersionSelectionState>(VersionSelectionModalReducers.TAG);

export const getVersionSelectionSort = createSelector(getVersionSelectionState, (state) => state?.sort);
export const getVersionSelectionPage = createSelector(getVersionSelectionState, (state) => state?.page);
export const getVersionSelectionData = createSelector(getVersionSelectionState, (state) => state?.data);
export const getVersionSelectionLoading = createSelector(getVersionSelectionState, (state) => state?.isLoading);

