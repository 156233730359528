import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { createAction, props } from '@ngrx/store';
import { SearchInfo } from 'src/app/models/search-info';
import { FiltersType } from './users.reducer';

export const setFilters = createAction('[Users] Set Filters', props<{ filters: FiltersType }>());
export const setSort = createAction('[Users] Set Sort', props<{ sort: Sort }>());
export const setPage = createAction('[Users] Set Page', props<{ page: PageEvent }>());
export const setExtFilt = createAction('[Users] Set External Filters', props<{ extFilt: any }>());
export const updateExtFilt = createAction('[Users] Update External Filters', props<{ extFilt: any }>());
export const setSearchInfo = createAction('[Users] Set Search Info', props<{ searchInfo: SearchInfo }>());
export const setData = createAction('[Users] Set Data', props<{ data: object }>());
export const setTab = createAction('[Users] set tab', props<{ tab: any }>());
