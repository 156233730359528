import { LocalizableField } from './LocalizableField';
import { FeatureType } from '../enums';
import { NodeType } from '../components/draggable-tree/draggable-tree.component';
import { Alignments } from '../enums';
export class FeatureValue {
  constructor(
    public name: string = '',
    public label: LocalizableField = new LocalizableField(),
    public uuid?: string,
    public featureValues?: FeatureValue[],
    public order?: number,
    public codeName?: string,

    //ex feature
    public onlyAdminEditable?: boolean,
    public parentUUID?: string,
    public key?: string,
    public clonable?: boolean,
    public editable?: boolean,
    public global?: boolean,
    public gStorageEntityURL?: LocalizableField,
    public iconURL?: LocalizableField,
    public communityUUID?: string | null,
    public nodeType?: NodeType,
    public localizationEnabled?: LocalizableField,
    public type?: FeatureType[],
    public filterOptions?: FilterOption
  ) {}
}

export interface FilterOption {
  align: Alignments;
  operator: 'or' | 'or_equal';
  collapsed: boolean;
  collapsable: boolean;
  enabled: boolean;
  isRoot: boolean;
  defaultValue: string;
  filters?: Array<ExtraFilter>;
  extra: string;
}

export interface ExtraFilter {
  feature: FeatureValue;
  beetwen: {
    field: string;
    min: number;
    max: number;
    step: number;
    collapsed: boolean;
    collapsable: boolean;
    enabled: boolean;
  };
}
