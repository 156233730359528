import { createFeatureSelector, createSelector } from '@ngrx/store';
import { OrdersReducers } from 'src/app/store/app.reducers';

const getOrdersState = createFeatureSelector<OrdersReducers.OrdersState>(OrdersReducers.TAG);

export const getOrdersTab = createSelector(getOrdersState, (state) => state.tab);
export const getOrdersFilt = createSelector(getOrdersState, (state) => state.filters);
export const getOrdersSearchInfo = createSelector(getOrdersState, (state) => state.searchInfo);
export const getOrdersPage = createSelector(getOrdersState, (state) => state.page);
export const getOrdersSort = createSelector(getOrdersState, (state) => state.sort);
export const getOrdersData = createSelector(getOrdersState, (state) => state.data);
