import { Action, createReducer, on } from '@ngrx/store';
import { SearchInfo } from 'src/app/models/search-info';
import { Sort } from '@angular/material/sort';
import { AuthActions, CommunitiesActions } from 'src/app/store/app.actions';
import { PageEvent } from '@angular/material/paginator';

export const TAG = 'communities';

export interface FiltersType {
  categoryValue: string;
  communityListAdminUUID: string;
}

export interface CommunitiesState {
  data: any;
  searchInfo: SearchInfo;
  filters: FiltersType;
  sort: Sort;
  page: PageEvent;
  bulkData: any;
  bulkAction: any;
  multipleData: any;
}

const initialState: CommunitiesState = {
  data: undefined,
  searchInfo: {
    searchFields: 'name',
    searchFieldsParamName: 'searchFields',
    searchValueParamName: 'q',
  } as SearchInfo,
  filters: {
    categoryValue: undefined,
    communityListAdminUUID: undefined,
  },
  bulkData: undefined,
  bulkAction: undefined,
  multipleData: undefined,
  sort: { active: 'name', direction: 'asc' },
  page: { pageIndex: 0, pageSize: 25, length: undefined },
};

const reducer = createReducer(
  initialState,
  on(CommunitiesActions.setFilters, (state: CommunitiesState, { filters }) => ({
    ...state,
    filters: {
      ...filters,
    },
  })),
  on(CommunitiesActions.setData, (state: CommunitiesState, { data }) => ({
    ...state,
    data,
  })),
  on(CommunitiesActions.setSearchInfo, (state: CommunitiesState, { searchInfo }) => ({
    ...state,
    searchInfo,
  })),
  on(CommunitiesActions.setSort, (state: CommunitiesState, { sort }) => ({
    ...state,
    sort,
  })),
  on(CommunitiesActions.setPage, (state: CommunitiesState, { page }) => ({
    ...state,
    page,
  })),
  on(CommunitiesActions.setUUIDListBulk, (state: CommunitiesState, { bulkData }) => ({
    ...state,
    bulkData,
  })),
  on(CommunitiesActions.setBulkAction, (state: CommunitiesState, { bulkAction }) => ({
    ...state,
    bulkAction,
  })),
  on(CommunitiesActions.setMultipleUpdateResult, (state: CommunitiesState, { multipleData }) => ({
    ...state,
    multipleData,
  })),
  on(AuthActions.logout, () => ({ ...initialState }))
);

export function communitiesReducer(state: CommunitiesState, action: Action) {
  return reducer(state, action);
}
