import { Injectable } from '@angular/core';
import {} from 'googlemaps';
import { Subject } from 'rxjs';

declare let google: any;

@Injectable({
  providedIn: 'root',
})
export class GmapsService {
  geocodeAddress(address) {
    const subject = new Subject();
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({ address: address }, (results, status) => {
      if (status === 'OK') {
        subject.next(results);
      } else {
        subject.error(status);
      }
    });
    return subject.asObservable();
  }
}
