import { createFeatureSelector, createSelector } from '@ngrx/store';
import { LessonsState } from './lessons-list.state';
import { LessonFiltersTypeValues } from '../../../../enums/filter-type.enum';

export const TAG = 'lessons';

const getLessonsState = createFeatureSelector<LessonsState>(TAG);
export const getLessonsData = createSelector(getLessonsState, (state) => state?.data);
export const getLessonsFilters = createSelector(getLessonsState, (state) => state?.filters);
export const getLessonsFiltersValues = createSelector(getLessonsState, (state) =>
  Object.entries(state?.filters).reduce((acc, [key, value]) => ({ ...acc, [key]: value?.value }), {} as LessonFiltersTypeValues)
);
export const getLessonsPage = createSelector(getLessonsState, (state) => state?.page);
export const getLessonsSort = createSelector(getLessonsState, (state) => state?.sort);
export const getLessonsSearchInfo = createSelector(getLessonsState, (state) => state?.searchInfo);
export const getLessonsFeatures = createSelector(getLessonsState, (state) => state?.features);
