import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { createAction, props } from '@ngrx/store';
import { SearchInfo } from 'src/app/models/search-info';
import { Structure } from 'src/app/models/Structure';
import { FiltersType } from './bookable-resources.reducer';
import { FeatureValue } from 'src/app/models/FeatureValue';

export const setFilters = createAction(
  '[BookableResources] Set Filters',
  props<{ filters: FiltersType }>()
);

export const setSort = createAction(
  '[BookableResources] Set Sort',
  props<{ sort: Sort }>()
);

export const setPage = createAction(
  '[BookableResources] Set Page',
  props<{ page: PageEvent }>()
);

export const setExtFilt = createAction(
  '[BookableResources] Set External Filters',
  props<{ extFilt: any }>()
);

export const updateExtFilt = createAction(
  '[BookableResources] Update External Filters',
  props<{ extFilt: any }>()
);

export const setSearchInfo = createAction(
  '[BookableResources] Set Search Info',
  props<{ searchInfo: SearchInfo }>()
);

export const setData = createAction(
  '[BookableResources] Set BookableResources Data',
  props<{ data: object }>()
);

export const setCustomizationData = createAction(
  '[BookableResources] Set Customization Data',
  props<{ customizationData: object }>()
);

export const setFeatures = createAction(
  '[BookableResources] Set BookableResources Features',
  props<{ features: Array<FeatureValue> }>()
);

export const setStructures = createAction(
  '[BookableResources] Set BookableResources Structures',
  props<{ structures: Array<Structure> }>()
);

export const setGridView = createAction(
  '[BookableResources] Set BookableResources Grid View',
  props<{ gridView: boolean }>()
);
