import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { createAction, props } from '@ngrx/store';
import { SearchInfo } from 'src/app/models/search-info';
import { Structure } from 'src/app/models/Structure';
import { FiltersType } from './resources.reducer';

export const setFilters = createAction('[Resources] Set Filters', props<{ filters: FiltersType }>());

export const setSort = createAction('[Resources] Set Sort', props<{ sort: Sort }>());

export const setPage = createAction('[Resources] Set Page', props<{ page: PageEvent }>());

export const setExtFilt = createAction('[Resources] Set External Filters', props<{ extFilt: any }>());

export const updateExtFilt = createAction('[Resources] Update External Filters', props<{ extFilt: any }>());

export const setSearchInfo = createAction('[Resources] Set Search Info', props<{ searchInfo: SearchInfo }>());

export const setData = createAction('[Resources] Set Resources Data', props<{ data: object }>());

export const setStructures = createAction('[Resources] Set Resources Structures', props<{ structures: Array<Structure> }>());
