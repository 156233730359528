import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { createAction, props } from '@ngrx/store';
import { SearchInfo } from 'src/app/models/search-info';
import { FiltersType } from './media-library-modal.reducer';

export const setFilters = createAction('[Media Library Modal] Set Filters', props<{ filters: FiltersType }>());
export const updateFilters = createAction('[Media Library Modal] update Filters', props<{ filters: FiltersType }>());
export const updateParentFilter = createAction('[Media Library Modal] Update Parent Filter', props<{ parentUUID: string }>());
export const setSort = createAction('[Media Library Modal] Set Sort', props<{ sort: Sort }>());
export const setPage = createAction('[Media Library Modal] Set Page', props<{ page: PageEvent }>());
export const updatePageIndex = createAction('[Media Library Modal] Update Page Index', props<{ index: number }>());
export const setSearchInfo = createAction('[Media Library Modal] Set Search Info', props<{ searchInfo: SearchInfo }>());
export const updateSearchInfo = createAction('[Media Library Modal] Update Search Info', props<{ searchValue: string }>());
export const setData = createAction('[Media Library Modal] Set Media Library Modal Data', props<{ data: any }>());
export const setGridView = createAction('[Media Library Modal] Set Media Library Modal Grid View', props<{ gridView: boolean }>());
export const addBreadcrumbItem = createAction(
  '[Media Library Modal] Open Folder',
  props<{ breadcrumbItem: { label: string; parentUUID: string } }>()
);
export const setBreadcrumbs = createAction(
  '[Media Library Modal] Set Breadcrumbs',
  props<{ breadcrumbs: { label: string; parentUUID: string }[] }>()
);
export const resetState = createAction('[Media Library Modal] Reset State');
