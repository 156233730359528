import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { createAction, props } from '@ngrx/store';
import { SearchInfo } from 'src/app/models/search-info';
import { FiltersType } from './media-library.reducer';

export const setFilters = createAction('[Media Library] Set Filters', props<{ filters: FiltersType }>());
export const updateParentFilter = createAction('[Media Library] Update Parent Filter', props<{ parentUUID: string }>());
export const setSort = createAction('[Media Library] Set Sort', props<{ sort: Sort }>());
export const resetPaging = createAction('[Media Library] Reset paging');
export const setPage = createAction('[Media Library] Set Page', props<{ page: PageEvent }>());
export const updatePageIndex = createAction('[Media Library] Update Page Index', props<{ index: number }>());
export const setSearchInfo = createAction('[Media Library] Set Search Info', props<{ searchInfo: SearchInfo }>());
export const updateSearchInfo = createAction('[Media Library] Update Search Info', props<{ searchValue: string }>());
export const setData = createAction('[Media Library] Set Media Library Data', props<{ data: any }>());
export const setGridView = createAction('[Media Library] Set Media Library Grid View', props<{ gridView: boolean }>());
export const addBreadcrumbItem = createAction(
  '[Media Library] Open Folder',
  props<{ breadcrumbItem: { label: string; parentUUID: string } }>()
);
export const setBreadcrumbs = createAction(
  '[Media Library] Set Breadcrumbs',
  props<{ breadcrumbs: { label: string; parentUUID: string }[] }>()
);
export const setMultipleUpdateResult = createAction('[Media Library] multiple update finished', props<{ multipleData: any }>());
export const setBulkAction = createAction('[Media Library] toggle bulk action', props<{ bulkAction: any }>());
export const resetBulkAction = createAction('[Media Library] reset bulk action');
export const setUUIDListBulk = createAction('[Media Library] toggle bulk', props<{ bulkData: any }>());
export const resetBreadcrumbs = createAction('[Media Library] Reset Breadcrumbs');
export const setLoading = createAction('[Media Library] Set loading', props<{ loading: boolean }>());
