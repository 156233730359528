import { Action, createReducer, on } from '@ngrx/store';
import { AuthActions, AvailabilityRulesActions } from 'src/app/store/app.actions';

export const TAG = 'availabilityRules';

export interface AvailabilityRulesState {
  data: any;
}

const initialState: AvailabilityRulesState = {
  data: undefined
};

const reducer = createReducer(
  initialState,
  on(AvailabilityRulesActions.setData, (state: AvailabilityRulesState, { data }) => {
    return {
      ...state,
      data
    };
  }),
  on(AuthActions.logout, () => ({ ...initialState }))
);

export function availabilityRulesReducer(state: AvailabilityRulesState, action: Action) {
  return reducer(state, action);
}
