import { Action, createReducer, on } from '@ngrx/store';
import { AuthActions, RolesActions } from 'src/app/store/app.actions';
import { SearchInfo } from 'src/app/models/search-info';
import { Sort } from '@angular/material/sort';
import { PageEvent } from '@angular/material/paginator';

export const TAG = 'roles';

export interface RolesState {
  data: any;
  searchInfo: SearchInfo;
  sort: Sort;
  page: PageEvent;
}

const initialState: RolesState = {
  data: undefined,
  searchInfo: {
    searchFields: 'name',
    searchFieldsParamName: 'searchFields',
    searchValueParamName: 'q',
  } as SearchInfo,
  sort: { active: 'name', direction: 'asc' },
  page: { pageIndex: 0, pageSize: 25, length: undefined },
};

const reducer = createReducer(
  initialState,
  on(RolesActions.setData, (state: RolesState, { data }) => ({
    ...state,
    data,
  })),
  on(RolesActions.setSearchInfo, (state: RolesState, { searchInfo }) => ({
    ...state,
    searchInfo,
  })),
  on(RolesActions.setSort, (state: RolesState, { sort }) => ({
    ...state,
    sort,
  })),
  on(RolesActions.setPage, (state: RolesState, { page }) => ({
    ...state,
    page,
  })),
  on(AuthActions.logout, () => ({ ...initialState }))
);

export function rolesReducer(state: RolesState, action: Action) {
  return reducer(state, action);
}
