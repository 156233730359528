import { Action, createReducer, on } from '@ngrx/store';
import { AuthActions, IntegrationSelectActions } from 'src/app/store/app.actions';
import { FiltersConstants } from 'src/app/enums/filters-constants.enum';
import { Integration } from 'src/app/models/Integration';

export const TAG = 'integrationSelect';

export interface IntegrationSelectState {
  lastIntegrationSelected: FiltersConstants | string;
  data: Array<Integration>;
}

const initialState: IntegrationSelectState = {
  lastIntegrationSelected: FiltersConstants.ALL_INTEGRATION_SELECT_VALUE,
  data: [],
};

const reducer = createReducer(
  initialState,
  on(IntegrationSelectActions.setLastIntegrationSelected, (state: IntegrationSelectState, { lastIntegrationSelected }) => ({
    ...state,
    lastIntegrationSelected,
  })),
  on(IntegrationSelectActions.setData, (state: IntegrationSelectState, { data }) => ({
    ...state,
    data,
  })),
  on(AuthActions.logout, () => ({ ...initialState }))
);

export function IntegrationSelectReducer(state: IntegrationSelectState, action: Action) {
  return reducer(state, action);
}
