import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { Action, createReducer, on } from '@ngrx/store';
import { SearchInfo } from 'src/app/models/search-info';
import { AuthActions, FeedbackActions } from 'src/app/store/app.actions';

export const FEEDBACK_TAG = 'feedback';

export interface FiltersType {
  communityUUID: string;
  entityFields: string;
  values: string;
}

export interface FeedbackState {
  filters: FiltersType;
  sort: Sort;
  page: PageEvent;
  searchInfo: SearchInfo;
  data: any;
  multipleData: any;
  monthsNumber: number;
}

const feedbackInitialState: FeedbackState = {
  filters: {
    communityUUID: undefined,
    entityFields: encodeURIComponent('type#not'),
    values: 'table',
  },
  sort: { active: 'firstName', direction: 'asc' },
  page: { pageIndex: 0, pageSize: 25, length: undefined },
  searchInfo: {
    searchFields: 'firstName,lastName,email,username',
    searchFieldsParamName: 'searchFields',
    searchValueParamName: 'q',
  } as SearchInfo,
  data: undefined,
  multipleData: undefined,
  monthsNumber: 3,
};

const _commReducer = createReducer(
  feedbackInitialState,
  on(FeedbackActions.setFeedbackFilters, (state: FeedbackState, { filters }) => ({
    ...state,
    filters: {
      ...filters,
    },
  })),
  on(FeedbackActions.setFeedbackSort, (state: FeedbackState, { sort }) => ({
    ...state,
    sort,
  })),
  on(FeedbackActions.setFeedbackPage, (state: FeedbackState, { page }) => ({
    ...state,
    page,
  })),
  on(FeedbackActions.setFeedbackSearchInfo, (state: FeedbackState, { searchInfo }) => ({
    ...state,
    searchInfo,
  })),
  on(FeedbackActions.setFeedbackData, (state: FeedbackState, { data }) => ({
    ...state,
    data,
    // page: { ...state.page, length: data.resultInfo.totalNumberOfElements }
  })),
  on(FeedbackActions.setMultipleUpdateResult, (state: FeedbackState, { multipleData }) => ({
    ...state,
    multipleData,
  })),
  on(FeedbackActions.setFeedbackMonthsNumber, (state: FeedbackState, { monthsNumber }) => ({
    ...state,
    monthsNumber,
  })),
  on(AuthActions.logout, () => ({ ...feedbackInitialState }))
);

export const feedbackReducer = (state: FeedbackState, action: Action) => {
  return _commReducer(state, action);
};
