import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { ScriptStore } from './script.store';

declare let document: any;

@Injectable()
export class ScriptService {
  private scripts: any = {};

  readonly env = environment;

  constructor() {
    this.init();
  }

  resolveScripts() {
    for (const script of ScriptStore) {
      if (script.name === 'maps') {
        const apiKey = environment.googleAPIKey;
        if (apiKey) {
          script.src = script.src.replace('$CLIENT_KEY$', apiKey);
        } else {
          script.src = '';
        }
      }

      this.scripts[script.name] = {
        loaded: false,
        src: script.src,
      };
    }
  }

  load(...scripts: string[]) {
    return Promise.all(scripts.map((script) => this.loadScript(script)));
  }

  loadScript(name: string) {
    return new Promise((resolve) => {
      //resolve if already loaded
      if (this.scripts[name].loaded) {
        resolve({ script: name, loaded: true, status: 'Already Loaded' });
      } else {
        //load script
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = this.scripts[name].src;
        if (script.readyState) {
          //IE
          script.onreadystatechange = () => {
            if (script.readyState === 'loaded' || script.readyState === 'complete') {
              script.onreadystatechange = null;
              this.scripts[name].loaded = true;
              resolve({ script: name, loaded: true, status: 'Loaded' });
            }
          };
        } else {
          //Others
          script.onload = () => {
            this.scripts[name].loaded = true;
            resolve({ script: name, loaded: true, status: 'Loaded' });
          };
        }
        script.onerror = () => resolve({ script: name, loaded: false, status: 'Loaded' });
        document.getElementsByTagName('head')[0].appendChild(script);
      }

      this.initThemePWA();
    });
  }

  initThemePWA() {
    const meta = document.createElement('meta');
    meta.name = 'theme-color';
    meta.content = environment.pwaThemeColor;
    document.getElementsByTagName('head')[0].appendChild(meta);
  }

  init() {
    this.resolveScripts();

    if (this.env.googleAPIKey && this.env.googleAPIKey.trim().length > 0) {
      return this.load('maps');
    }

    // console.log('ScriptService initialized');
  }
}
