import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { v4 } from 'uuid';
import { Parts, StructureFieldTypes } from '../enums';
import { ParseEntity } from '../helpers/utilities';
import { StructureField } from '../interfaces/structure-field';
import { Structure } from '../models';
import { ApplicationUser } from '../models/ApplicationUser';
import { DataleanDataProviderService } from '../provider/datalean-data-provider.service';
import { BaseEditorService } from './base-editor.service';
import { CheckRequiredFieldSummary } from '../models/CheckRequiredFieldSummary';

@Injectable()
export class AppUserEditorService extends BaseEditorService {
  constructor(protected dataleanDataService: DataleanDataProviderService) {
    super(dataleanDataService);
  }

  structure: Structure | undefined;
  supportsLanguages = false;
  authMethodUpdated = false;
  resetDeviceIdentifier = false;

  init(structure?: Structure) {
    if (!this.objectData) {
      this.objectData = {
        uuid: v4(),
        activationDate: null,
        authenticationMethods: null,
        communityUUIDList: [],
        email: '',
        firstName: '',
        isPrivate: false,
        lastName: '',
        resetPasswordToken: null,
        responsible: [],
        roles: [],
        status: 'ENABLED',
        systemUser: false,
        username: '',
      };
      if (structure) {
        this.objectData.structureUUID = structure.uuid;
      }
    }
    if (structure) {
      this.structure = structure;
    }
    if (this.objectData.authenticationMethods && this.objectData.authenticationMethods.length > 0) {
      this.objectData.authenticationMethods[0].username = this.objectData.username;
    }
  }

  initializeUserValues(structureField, containerId?) {
    let value = '';
    if (structureField.type === StructureFieldTypes.CONTAINER || structureField.type === StructureFieldTypes.ATTACHMENT) {
      value = v4();
    }
    const addedProductValue = this.addUserValueIfNeeded(value, structureField, containerId);
    if (
      (structureField.type === StructureFieldTypes.CONTAINER || structureField.type === StructureFieldTypes.ATTACHMENT) &&
      addedProductValue
    ) {
      structureField?.fields?.forEach((innerStructureField) => {
        if (innerStructureField.type !== 'calculated') {
          this.initializeUserValues(innerStructureField, value);
        }
      });
    }
  }

  addUserValueIfNeeded(value: any, structureField: StructureField, containerId = '') {
    // checking if it is already present
    const foundPV = this.objectData?.values?.find((value) => {
      if (value.structureFieldUUID === structureField.uuid) {
        return true;
      }
      return false;
    });

    if (!foundPV) {
      return this.addValue(value, structureField, containerId);
    }
  }

  addValue(value: any, userStructureField: any, containerId = ''): any {
    if (!this.objectData?.values || !Array.isArray(this.objectData?.values)) {
      this.objectData.values = [];
    }
    const newUUID = v4();
    const obj = {
      uuid: newUUID,
      value,
      structureFieldUUID: userStructureField.uuid,
      structureFieldName: userStructureField.name,
      structureFieldType: userStructureField.type,
      userStructureField,
      containerId,
      organizationUUID: userStructureField.organizationUUID,
    };

    if (containerId && containerId.length > 0) obj['containerId'] = containerId;

    this.objectData.values.push(obj);
    return obj;
  }

  create(): Observable<any> {
    if (!this.objectData.values) {
      delete this.objectData.values;
    }
    const parts: Parts[] = [Parts.VALUES, Parts.RESPONSIBLE];
    if (this.authMethodUpdated) {
      parts.push(Parts.AUTHENTICATION_METHODS);
    }
    if (this.objectData.communityUUIDList && this.objectData.communityUUIDList.length > 0) {
      parts.push(Parts.COMMUNITY);
    }
    this.prepareToSend();

    const url = environment.applicationUsersUrl;
    return this.dataleanDataService.createEntity(url, this.objectData, parts);
  }

  update(): Observable<any> {
    if (!this.objectData.values) {
      delete this.objectData.values;
    }
    delete this.objectData.reports;

    const parts: Parts[] = [Parts.VALUES, Parts.RESPONSIBLE];
    if (this.authMethodUpdated) {
      parts.push(Parts.AUTHENTICATION_METHODS);
    } else {
      delete this.objectData.authenticationMethods;
    }
    if (this.resetDeviceIdentifier) {
      parts.push(Parts.RESET_DEVICE_IDENTIFIER);
    }

    this.prepareToSend();
    const url = environment.applicationUsersUrl;
    return this.dataleanDataService.updateEntity(url, this.objectData, parts);
  }

  private prepareToSend() {
    // console.log('prepareToSend', structuredClone(this.objectData));
    const parsedUser = ParseEntity(new ApplicationUser(), this.objectData);
    const structureFields = this.structure?.structureFields;

    if (structureFields && structureFields.length > 0) {
      for (const structureField of structureFields) {
        if (this.objectData[structureField.name] !== undefined) {
          parsedUser[structureField.name] = this.objectData[structureField.name];
        }
      }
    }

    this.objectData = parsedUser;
    delete this.objectData.latestLogin;
    delete this.objectData.latestAccess;
    if (this.objectData.values) {
      this.objectData.values = this.objectData.values?.filter((value) => {
        if (value.userStructureField.type === 'calculated') {
          return false;
        } else {
          delete value.structureFieldName;
          delete value.lastUpdate;
          delete value.organizationUUID;
          delete value.orderId;
          if (value.containerId != null && value.containerId.trim().length === 0) {
            delete value.containerId;
          }
          return true;
        }
      });
    }
  }

  deleteUserValue(valueUUID?: string) {
    if (this.objectData?.values?.length > 0) {
      let valueIndex = -1;
      if (valueUUID) {
        valueIndex = (this.objectData.values as any[]).findIndex((el) => {
          return el.uuid === valueUUID;
        });
      }
      if (valueIndex !== -1) {
        this.objectData.values.splice(valueIndex, 1);
      }
    }
  }

  addEmptyUserValuesToContainer(containerId, structureField) {
    let value: string | object = '';
    if (structureField.type === StructureFieldTypes.CONTAINER || structureField.type === StructureFieldTypes.ATTACHMENT) {
      value = v4();
    }
    this.addValue(value, structureField, containerId);
    if (structureField.type === StructureFieldTypes.CONTAINER || structureField.type === StructureFieldTypes.ATTACHMENT) {
      structureField.fields.forEach((field) => {
        this.addEmptyUserValuesToContainer(value, field);
      });
    }
  }

  checkRequiredFields(): Array<CheckRequiredFieldSummary> {
    const errorFields = [];

    const rootRequiredFields = (this.structure?.structureFields ?? []).filter(
      (field) => field.isRequired || this.hasRequiredChild(field.fields ?? []),
    );

    errorFields.push(...this.getErrorRequiredFields(rootRequiredFields, this.objectData));

    return errorFields;
  }

  private hasRequiredChild(fields: StructureField[]) {
    const hasRequired = fields.some((field) => field.isRequired);

    return hasRequired ?? fields.some((field) => field.fields?.length && this.hasRequiredChild(field.fields));
  }

  private getErrorRequiredFields(
    structureFields: StructureField[],
    objectToCheck: object,
    locale?: string,
  ): Array<CheckRequiredFieldSummary> {
    // REQUIRED REPEATABLE TEXT FIELDS ARE INTENDED AS VALID IF ASSOCIATED ARRAY IS NOT EMPTY
    const errorFields: CheckRequiredFieldSummary[] = structureFields
      .filter(
        (field) =>
          field.isRequired &&
          (!objectToCheck ||
            (field.type !== StructureFieldTypes.BOOLEAN && !objectToCheck[field.name]) ||
            (Array.isArray(objectToCheck[field.name]) && objectToCheck[field.name].length === 0)),
      )
      .map((errorField) => ({
        name: errorField.name,
        label: errorField.label,
        locale: locale,
      }));

    const fieldsWithChildrenExcludingErrors = structureFields.filter(
      (field) => !errorFields.some((sf) => sf.name === field.name) && field.fields?.length > 0,
    );

    for (const fieldWithChildren of fieldsWithChildrenExcludingErrors) {
      if (/*fieldWithChildren.repeatable && */ objectToCheck && Array.isArray(objectToCheck[fieldWithChildren.name])) {
        for (const singleObjectToCheckValue of objectToCheck[fieldWithChildren.name]) {
          errorFields.push(...this.getErrorRequiredFields(fieldWithChildren.fields, singleObjectToCheckValue, locale));
        }
      } else {
        errorFields.push(
          ...this.getErrorRequiredFields(
            fieldWithChildren.fields,
            objectToCheck ? objectToCheck[fieldWithChildren.name] : undefined,
            locale,
          ),
        );
      }
    }

    return errorFields;
  }
}
