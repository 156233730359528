import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class IconResolverService {
  private mimeTypeMap = {
    // pdf
    pdf: 'application/pdf',
    // xls
    xls: 'application/vnd.ms-excel',
    xlm: 'application/vnd.ms-excel',
    xla: 'application/vnd.ms-excel',
    xlc: 'application/vnd.ms-excel',
    xlt: 'application/vnd.ms-excel',
    xlw: 'application/vnd.ms-excel',
    xlam: 'application/vnd.ms-excel.addin.macroenabled.12',
    xlsb: 'application/vnd.ms-excel.sheet.binary.macroenabled.12',
    xlsm: 'application/vnd.ms-excel.sheet.macroenabled.12',
    xltm: 'application/vnd.ms-excel.template.macroenabled.12',
    xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    // ppt
    ppt: 'application/vnd.ms-powerpoint',
    pps: 'application/vnd.ms-powerpoint',
    pot: 'application/vnd.ms-powerpoint',
    ppam: 'application/vnd.ms-powerpoint.addin.macroenabled.12',
    pptm: 'application/vnd.ms-powerpoint.presentation.macroenabled.12',
    sldm: 'application/vnd.ms-powerpoint.slide.macroenabled.12',
    ppsm: 'application/vnd.ms-powerpoint.slideshow.macroenabled.12',
    potm: 'application/vnd.ms-powerpoint.template.macroenabled.12',
    pptx: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    // doc
    doc: 'application/msword',
    dot: 'application/msword',
    docm: 'application/vnd.ms-word.document.macroenabled.12',
    dotm: 'application/vnd.ms-word.document.macroenabled.12',
    docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    dotx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
    // odt
    odc: 'application/vnd.oasis.opendocument.chart',
    otc: 'application/vnd.oasis.opendocument.chart-template',
    odb: 'application/vnd.oasis.opendocument.database',
    odf: 'application/vnd.oasis.opendocument.formula',
    odft: 'application/vnd.oasis.opendocument.formula-template',
    odg: 'application/vnd.oasis.opendocument.graphics',
    otg: 'application/vnd.oasis.opendocument.graphics-template',
    odi: 'application/vnd.oasis.opendocument.image',
    oti: 'application/vnd.oasis.opendocument.image-template',
    odp: 'application/vnd.oasis.opendocument.presentation',
    otp: 'application/vnd.oasis.opendocument.presentation-template',
    ods: 'application/vnd.oasis.opendocument.spreadsheet',
    ots: 'application/vnd.oasis.opendocument.spreadsheet-template',
    odt: 'application/vnd.oasis.opendocument.text',
    odm: 'application/vnd.oasis.opendocument.text-master',
    ott: 'application/vnd.oasis.opendocument.text-template',
    oth: 'application/vnd.oasis.opendocument.text-web',
    // 3gp
    '3gp': 'video/3gpp',
    'video/3gpp2': '3g2',
    // h
    h261: 'video/h261',
    h263: 'video/h263',
    h264: 'video/h264',
    // jpeg
    jpgv: 'video/jpeg',
    jpeg: 'image/jpeg',
    jpg: 'image/jpeg',
    webp: 'image/webp',
    // gif
    gif: 'image/gif',
    // tiff
    tiff: 'image/tiff',
    // png
    png: 'image/png',
    // svg
    svg: 'image/svg+xml',
    // mp4
    mp4: 'video/mp4',
    //mp3
    mp3: 'audio/mp3',
    // mpeg
    mpeg: 'video/mpeg',
    mpg: 'video/mpeg',
    mpe: 'video/mpeg',
    m1v: 'video/mpeg',
    m2v: 'video/mpeg',
    // ogg
    ogv: 'video/ogg',
    // quicktime
    qt: 'video/quicktime',
    mov: 'video/quicktime',
    // audio
    wave: 'audio/wave',
    wav: 'audio/wav',
    xwav: 'audio/x-wav',
    webm: 'audio/webm',
    ogg: 'audio/ogg',
    vorbis: 'audio/vorbis',

    // zip
    zip: 'application/zip',
  };

  tryTogetIconByExtension(fileName: string) {
    if (!fileName) return fileName;
    return typeof fileName === 'string'
      ? this.getIcon(this.mimeTypeMap[fileName.substring(fileName.lastIndexOf('.') + 1).toLowerCase()]?.toLowerCase()) || fileName
      : '';
  }

  getIcon(mimeType) {
    switch (mimeType) {
      case 'audio/mpeg':
      case 'audio/wave':
      case 'audio/wav':
      case 'audio/x-wav':
      case 'audio/x-pn-wav':
      case 'audio/webm':
      case 'audio/ogg':
      case 'audio/vorbis': {
        return './assets/images/media-library/icn-audio.png';
      }
      case 'application/pdf': {
        return './assets/images/media-library/icn-pdf.png';
      }
      case 'application/zip': {
        return './assets/images/media-library/icn-zip.png';
      }
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      case 'application/vnd.ms-excel':
      case 'application/msexcel':
      case 'application/x-msexcel':
      case 'application/x-ms-excel':
      case 'application/x-excel':
      case 'application/x-dos_ms_excel':
      case 'application/xls':
      case 'application/x-xls': {
        return './assets/images/media-library/icn-xls.png';
      }
      case 'video/mp4':
      case 'video/quicktime':
      case 'video/mpeg':
      case 'video/ogg':
      case 'video/webm': {
        return './assets/images/media-library/icn-video.png';
      }
      case 'application/vnd.ms-powerpoint':
      case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
      case 'application/vnd.openxmlformats-officedocument.presentationml.template':
      case 'application/vnd.ms-powerpoint.addin.macroEnabled.12':
      case 'application/vnd.ms-powerpoint.presentation.macroEnabled.12':
      case 'application/vnd.openxmlformats-officedocument.presentationml.slideshow':
      case 'application/vnd.ms-powerpoint.template.macroEnabled.12':
      case 'application/vnd.ms-powerpoint.slideshow.macroEnabled.12': {
        return './assets/images/media-library/icn-ppt.png';
      }
      case 'application/msword':
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.template':
      case 'application/vnd.ms-word.document.macroEnabled.12':
      case 'application/vnd.ms-word.template.macroEnabled.12':
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document': {
        return './assets/images/media-library/icn-doc.png';
      }
      default: {
        return !mimeType || (mimeType && mimeType.includes('image')) ? '' : './assets/images/media-library/icn-code.png';
      }
    }
  }

  isImage(mimeType) {
    switch (mimeType) {
      case 'image/jpeg':
      case 'image/jpg':
      case 'image/apng':
      case 'image/bmp':
      case 'image/gif':
      case 'image/x-icon':
      case 'image/png':
      case 'image/svg+xml':
      case 'image/tiff':
      case 'image/webp': {
        return true;
      }
      default: {
        return false;
      }
    }
  }

  isVideo(mimeType) {
    switch (mimeType) {
      case 'video/mp4':
      case 'video/webm':
      case 'video/quicktime':
      case 'video/mpeg':
      case 'video/ogg': {
        return true;
      }

      default: {
        return false;
      }
    }
  }

  isAudio(mimeType) {
    switch (mimeType) {
      case 'audio/mpeg':
      case 'audio/wave':
      case 'audio/wav':
      case 'audio/x-wav':
      case 'audio/x-pn-wav':
      case 'audio/webm':
      case 'audio/ogg':
      case 'audio/vorbis': {
        return true;
      }

      default: {
        return false;
      }
    }
  }

  mapPossibilities(assetType: string, formats: string[]) {
    return formats.reduce((p: string[], c: string) => {
      const mimeType = `${assetType}/${c}`;
      if (Object.values(this.mimeTypeMap).some((v) => v === mimeType) || c === '*') p.push(mimeType);
      return p;
    }, []);
  }

  createMimeTypeLists(assetRequired: string, formatRequired: string) {
    const formats = formatRequired.length ? formatRequired.split(',').filter((s: string) => s.length) : ['*'];
    const mimeTypes = assetRequired.split(',').reduce((prev, cur, index, array) => {
      const prevMapped = index && index < array.length ? `${prev},` : '';
      return `${prevMapped}${this.mapPossibilities(cur, formats)}`;
    }, '');
    return mimeTypes;
  }
}
