import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { Action, createReducer, on } from '@ngrx/store';
import DeepCopy from 'src/app/helpers/deep_copy';
import { AuthActions, CommunicationsActions } from 'src/app/store/app.actions';
import { FiltersType, FiltersVisibility, StructureType } from '../../../enums';
import { SearchInfo, Structure } from '../../../models';

export const COMMUNICATION_TAG = 'communications';

export interface CommunicationsState {
  filters: FiltersType;
  sort: Sort;
  page: PageEvent;
  searchInfo: SearchInfo;
  data: any;
  structures: Array<Structure>;
  loading: boolean;
  activeTabIndex: number;
}

const communicationInitialState: CommunicationsState = {
  activeTabIndex: 0,
  filters: {
    'structure.type': { visibility: FiltersVisibility.HIDDEN, value: StructureType.COMMUNICATION },
    structureUUID: { visibility: FiltersVisibility.VISIBLE, value: '' },
    communityUUID: { visibility: FiltersVisibility.HIDDEN, value: undefined },
    communityAdminUUID: { visibility: FiltersVisibility.HIDDEN, value: undefined },
    active: { visibility: FiltersVisibility.VISIBLE, value: undefined },
  },
  sort: {
    active: 'publicationDate',
    direction: 'desc',
  },
  page: { pageIndex: 0, pageSize: 25, length: undefined },
  searchInfo: {
    searchFields: 'name',
    searchFieldsParamName: 'searchFields',
    searchValueParamName: 'q',
  } as SearchInfo,
  data: undefined,
  structures: [],
  loading: false,
};

const _commReducer = createReducer(
  communicationInitialState,
  on(CommunicationsActions.setFilters, (state: CommunicationsState, { filters }) => ({
    ...state,
    filters,
  })),
  on(CommunicationsActions.setFiltersValues, (state: CommunicationsState, { filters }) => ({
    ...state,
    filters: Object.entries(filters).reduce((acc, [key, value]) => {
      if (typeof acc[key] === 'object') {
        acc[key] = { ...acc[key], value } as FiltersType;
      } else {
        acc[key] = { value, visibility: acc[key] ?? FiltersVisibility.VISIBLE };
      }
      return acc;
    }, DeepCopy<FiltersType>(state.filters)),
  })),
  on(CommunicationsActions.updateFilters, (state: CommunicationsState, { extFilt }) => ({
    ...state,
    filters: {
      ...state.filters,
      ...extFilt,
    },
  })),
  on(CommunicationsActions.setSort, (state: CommunicationsState, { sort }) => ({
    ...state,
    sort,
  })),
  on(CommunicationsActions.setPage, (state: CommunicationsState, { page }) => ({
    ...state,
    page,
  })),
  on(CommunicationsActions.setSearchInfo, (state: CommunicationsState, { searchInfo }) => ({
    ...state,
    searchInfo,
  })),
  on(CommunicationsActions.setData, (state: CommunicationsState, { data, loadingComplete }) => ({
    ...state,
    data,
    loading: loadingComplete === true ? false : state.loading,
  })),
  on(CommunicationsActions.setStructures, (state: CommunicationsState, { structures }) => ({
    ...state,
    structures,
  })),
  on(CommunicationsActions.updateTabIndex, (state: CommunicationsState, { activeTabIndex }) => ({
    ...state,
    activeTabIndex,
  })),
  on(CommunicationsActions.setLoading, (state, { loading }) => ({
    ...state,
    loading,
  })),
  on(AuthActions.logout, () => ({ ...communicationInitialState }))
);

export const communicationsReducer = (state: CommunicationsState, action: Action) => {
  return _commReducer(state, action);
};
