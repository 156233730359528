import { Action, createReducer, on } from '@ngrx/store';
import { AuthActions, CommunitySelectActions } from 'src/app/store/app.actions';
import { Community } from 'src/app/models/Community';
import { FiltersConstants } from 'src/app/enums/filters-constants.enum';

export const TAG = 'communitySelect';

export interface FiltersType {
  communityListAdminUUID?: string;
}
export interface CommunitySelectState {
  data: Array<Community>;
  filters: FiltersType;
  lastCommunitySelected: FiltersConstants | string;
  showNoCommunityChoice: boolean;
  status: 'loaded' | 'uninitialized';
}

const initialState: CommunitySelectState = {
  data: undefined,
  filters: undefined,
  lastCommunitySelected: undefined,
  showNoCommunityChoice: false,
  status: 'uninitialized',
};

const reducer = createReducer(
  initialState,
  on(CommunitySelectActions.setFilters, (state: CommunitySelectState, { filters }) => ({
    ...state,
    filters,
  })),
  on(CommunitySelectActions.updateShowNoCommunityChoice, (state: CommunitySelectState, { showNoCommunityChoice }) => ({
    ...state,
    showNoCommunityChoice,
  })),
  on(CommunitySelectActions.setLastCommunitySelected, (state: CommunitySelectState, { lastCommunitySelected }) => ({
    ...state,
    lastCommunitySelected,
  })),
  on(CommunitySelectActions.setData, (state: CommunitySelectState, { data }) => ({
    ...state,
    data,
    status: 'loaded',
  })),
  on(AuthActions.logout, () => ({ ...initialState })),
);

export function communitySelectReducer(state: CommunitySelectState, action: Action) {
  return reducer(state, action);
}
