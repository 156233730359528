import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { createAction, props } from '@ngrx/store';

export const setSort = createAction('[Version Selection Modal] Set Sort', props<{ sort: Sort }>());
export const setPage = createAction('[Version Selection Modal] Set Page', props<{ page: PageEvent }>());
export const updatePageIndex = createAction('[Version Selection Modal] Update Page Index', props<{ index: number }>());
export const setData = createAction('[Version Selection Modal] Set Version Selection Modal Data', props<{ data: any }>());
export const setLoading = createAction('[Version Selection Modal] Set Loading Modal Data', props<{ isLoading: boolean }>());


export const resetState = createAction('[Version Selection Modal] Reset State');
