import { DataleanDataProviderService } from '../provider/datalean-data-provider.service';
import { environment } from 'src/environments/environment';

import { Injectable } from '@angular/core';

import { BaseEditorService } from './base-editor.service';
import { Observable, of, Subject, zip } from 'rxjs';
import { Parts } from '../enums/parts.enum';
import { catchError, map } from 'rxjs/operators';
import { OrganizationService } from './organization.service';
import { UserPermissionService } from './user-permission.service';
import { UserSettingsService } from './user-settings-service.service';
import { ToLocalizedValuePipe } from '../pipes/to-localized-value/to-localized-value';
import { ContestConfigPeriodicityEnum } from '../enums/contest-config-periodicity.enum';
import { LocalizationService } from './localization.service';
import { Type } from '../enums';
import { ParseEntity } from '../helpers/utilities';
import { Contest } from '../models/Contest';

// not an injectable because it is not meant to be reused
@Injectable()
export class ContestEditorService extends BaseEditorService {
  availableConfigurations = [
    {
      name: 'LOGIN',
      score: 0,
      periodicity: ContestConfigPeriodicityEnum.ONCE,
      threshold: null,
      maxActions: null,
      order: 0,
    },
    // {
    //   "name": "COCKPIT_CLICK",
    //   "score": 0,
    //   "periodicity": "ONCE",
    //   "threshold": null,
    //   "order": 0
    // },
    // {
    //   "name": "PHONE_CALL",
    //   "score": 0,
    //   "periodicity": "ONCE",
    //   "threshold": null,
    //   "order": 0
    // },
    // {
    //   "name": "EMAIL_BOOKING_SENDING",
    //   "score": 0,
    //   "periodicity": "ONCE",
    //   "threshold": null,
    //   "order": 0
    // },
    {
      name: 'COMMUNICATION_OPENING',
      score: 0,
      periodicity: ContestConfigPeriodicityEnum.ONCE,
      threshold: null,
      maxActions: null,
      order: 0,
    },
    {
      name: 'COMMUNICATION_CONFIRM',
      score: 0,
      periodicity: ContestConfigPeriodicityEnum.ONCE,
      threshold: null,
      maxActions: null,
      order: 0,
    },
    {
      name: 'USER_PHOTO_ENTRY',
      score: 0,
      periodicity: ContestConfigPeriodicityEnum.ONCE,
      threshold: null,
      maxActions: null,
      order: 0,
    },
    // {
    //   "name": "PROMO_USE",
    //   "score": 0,
    //   "periodicity": "ONCE",
    //   "threshold": null,
    //   "order": 0
    // },
    // {
    //   "name": "REGISTRATION",
    //   "score": 0,
    //   "periodicity": "ONCE",
    //   "threshold": null,
    //   "order": 0
    // },
    // {
    //   "name": "CUSTOM_ACTION",
    //   "score": 0,
    //   "periodicity": "ONCE",
    //   "threshold": null,
    //   "order": 0
    // },
    {
      name: 'SURVEY_SUBMITTED',
      score: 0,
      periodicity: ContestConfigPeriodicityEnum.ONCE,
      threshold: null,
      maxActions: null,
      order: 0,
    },
  ];
  targets;
  scores;

  constructor(
    protected dataleanDataService: DataleanDataProviderService,
    private organizationService: OrganizationService,
    private userPermissionService: UserPermissionService,
    private toLocalizedValuePipe: ToLocalizedValuePipe,
    private localizationService: LocalizationService,
    private userSettingsService: UserSettingsService
  ) {
    super(dataleanDataService);
  }

  init(data?) {
    const subject = new Subject();
    if (data) {
      const recipientUrl = environment.applicationUsersUrl,
        recipientUrlGroup = environment.groupsUrl,
        recipientUrlSmartGroup = environment.smartGroupsUrl,
        additionalParamsUser = {
          /*TODO: communityUUID?*/
        },
        additionalParamsGroup = {
          /*TODO: communityUUID?*/
        },
        additionalParamsSmartGroup = {
          /*TODO: communityUUID?*/
        };
      if (this.userPermissionService.isCommunityAdmin()) {
        // ONLY FOR SECURITY REASONS
        additionalParamsUser['requesterUserUUID'] = this.userSettingsService.getUserUUID();
        additionalParamsGroup['communityAdminUUID'] = this.userSettingsService.getUserUUID();
      }
      this.objectData = data;
      zip(
        this.dataleanDataService.getDataForList(recipientUrl, additionalParamsUser, [Parts.EMPTY]).pipe(
          catchError(() => of({ result: [] })),
          map((data: any) => data.result)
        ),
        this.dataleanDataService.getDataForList(recipientUrlGroup, additionalParamsGroup, [Parts.EMPTY]).pipe(
          catchError(() => of({ result: [] })),
          map((data: any) =>
            data.result?.map((result) => {
              return { ...result, type: Type.CLASSIC };
            })
          )
        ),
        this.dataleanDataService.getDataForList(recipientUrlSmartGroup, additionalParamsSmartGroup, [Parts.EMPTY]).pipe(
          catchError(() => of({ result: [] })),
          map((data: any) =>
            data.result?.map((result) => {
              return { ...result, type: Type.SMART };
            })
          )
        ),
        this.dataleanDataService
          .getDataForList(environment.contestUrl + data.uuid + '/score', { sortBy: encodeURIComponent('totalScore#DESC') }, [Parts.ALL])
          .pipe(
            catchError(() => of({ result: [] })),
            map((data: any) => data.result)
          )
      ).subscribe({
        next: ([users, groups, smartGroups, scores]) => {
          this.targets = users
            .filter((user) => !!this.objectData.targets.find((target) => target.entityUUID === user.uuid))
            .concat(groups.filter((group) => !!this.objectData.targets.find((target) => target.entityUUID === group.uuid)))
            .concat(smartGroups.filter((smartGroup) => !!this.objectData.targets.find((target) => target.entityUUID === smartGroup.uuid)));
          this.scores = scores;
          /*this.scores?.forEach((score) => {
            score?.scoreActions?.forEach((action) => {
              if (action.actionName != 'LOGIN' && action.actionName != 'COCKPIT_CLICK') {
                this.dataleanDataService
                  .getEntity(environment.communicationUrl, action.extra, [Parts.EMPTY], {locale: this.localizationService.getActiveEntityLanguage()})
                  .subscribe((data) => {
                    action.extra = (data).name;
                  });
              }
            });
          });*/
          subject.next(true);
        },
      });
    } else {
      this.objectData = {
        name: {},
        organizationUUID: environment.organizationUUID,
        description: {},
        limitScore: 0,
        configurations: [],
        targets: [],
        participantsCount: 0,
      };
      this.targets = [];
      this.scores = [];
      subject.next(true);
    }
    return subject.asObservable();
  }

  create(): Observable<any> {
    const contestCleared = this.clearContest()
    return this.dataleanDataService.createEntity(environment.contestUrl, contestCleared, [Parts.ALL], {});
  }

  update(): Observable<any> {
    const contestCleared = this.clearContest()
    return this.dataleanDataService.updateEntity(environment.contestUrl, contestCleared, [Parts.ALL]);
  }

  clearContest(): Contest{
    return ParseEntity(new Contest(), this.objectData)
  }
}


