import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { Action, createReducer, on } from '@ngrx/store';
import { SearchInfo } from 'src/app/models/search-info';
import { AuthActions, MediaLibraryActions } from 'src/app/store/app.actions';

export const TAG = 'mediaLibrary';

export interface FiltersType {
  parentUUID: string;
  communityUUID: string;
  communityEntityUUID: string;
}

export interface MediaLibraryState {
  loading: boolean;
  filters: FiltersType;
  sort: Sort;
  page: PageEvent;
  searchInfo: SearchInfo;
  data: any;
  gridView: boolean;
  bulkData: any;
  bulkAction: any;
  multipleData: any;
  breadcrumbs: {
    label: string;
    parentUUID: string;
  }[];
}
const defaultPage = { pageIndex: 0, pageSize: 25, length: undefined };
const initialState: MediaLibraryState = {
  loading: false,
  filters: {
    parentUUID: undefined,
    communityUUID: undefined,
    communityEntityUUID: undefined,
  },
  sort: { active: 'name', direction: 'asc' },
  page: { ...defaultPage },
  searchInfo: new SearchInfo('name', '', 'searchFields', 'q'),
  data: undefined,
  gridView: true,
  bulkData: undefined,
  bulkAction: undefined,
  multipleData: undefined,
  breadcrumbs: [
    {
      label: 'Home',
      parentUUID: undefined,
    },
  ],
};

const _counterReducer = createReducer(
  initialState,
  on(MediaLibraryActions.setFilters, (state: MediaLibraryState, { filters }) => ({
    ...state,
    filters
  })),
  on(MediaLibraryActions.updateParentFilter, (state: MediaLibraryState, { parentUUID }) => ({
    ...state,
    filters: {
      ...state.filters,
      parentUUID,
    },
  })),
  on(MediaLibraryActions.setSort, (state: MediaLibraryState, { sort }) => ({
    ...state,
    sort,
  })),
  on(MediaLibraryActions.setPage, (state: MediaLibraryState, { page }) => ({
    ...state,
    page,
  })),
  on(MediaLibraryActions.resetPaging, (state: MediaLibraryState) => ({
    ...state,
    page: { ...defaultPage },
  })),
  on(MediaLibraryActions.updatePageIndex, (state: MediaLibraryState, { index }) => ({
    ...state,
    page: {
      ...state.page,
      pageIndex: index,
      length: undefined,
    },
  })),
  on(MediaLibraryActions.updateSearchInfo, (state: MediaLibraryState, { searchValue }) => ({
    ...state,
    searchInfo: new SearchInfo(
      state.searchInfo.searchFields,
      searchValue,
      state.searchInfo.searchFieldsParamName,
      state.searchInfo.searchValueParamName
    ),
  })),
  on(MediaLibraryActions.setSearchInfo, (state: MediaLibraryState, { searchInfo }) => ({
    ...state,
    searchInfo,
  })),
  on(MediaLibraryActions.setData, (state: MediaLibraryState, { data }) => ({
    ...state,
    data,
  })),
  on(MediaLibraryActions.setGridView, (state: MediaLibraryState, { gridView }) => ({
    ...state,
    gridView,
  })),
  on(MediaLibraryActions.setBreadcrumbs, (state: MediaLibraryState, { breadcrumbs }) => ({
    ...state,
    breadcrumbs,
  })),
  on(MediaLibraryActions.addBreadcrumbItem, (state: MediaLibraryState, { breadcrumbItem }) => ({
    ...state,
    breadcrumbs: [...state.breadcrumbs, breadcrumbItem],
  })),
  on(MediaLibraryActions.resetBreadcrumbs, (state: MediaLibraryState) => ({
    ...state,
    breadcrumbs: initialState.breadcrumbs,
    filters: {
      ...state.filters,
      parentUUID: undefined,
    },
  })),
  on(MediaLibraryActions.setBulkAction, (state: MediaLibraryState, { bulkAction }) => ({
    ...state,
    bulkAction,
  })),
  on(MediaLibraryActions.setUUIDListBulk, (state: MediaLibraryState, { bulkData }) => ({
    ...state,
    bulkData,
  })),
  on(MediaLibraryActions.setMultipleUpdateResult, (state: MediaLibraryState, { multipleData }) => ({
    ...state,
    multipleData,
  })),
  on(AuthActions.logout, () => ({ ...initialState })),
  on(MediaLibraryActions.setLoading, (state: MediaLibraryState, { loading }) => ({
    ...state,
    loading,
  })),
  on(MediaLibraryActions.resetBulkAction, (state: MediaLibraryState) => ({
    ...state,
    bulkAction: undefined,
  }))
);

export function mediaLibraryReducer(state: MediaLibraryState, action: Action) {
  return _counterReducer(state, action);
}
