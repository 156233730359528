export class ProductCloneMoveDetailBuilder {
  private archived: boolean;
  private active: boolean;
  private delete: boolean;
  private actionType: string;

  constructor(private uuidList: any) {
  }

  get ProductUUIDList() {
    return this.uuidList;
  }

  get isActive() {
    return this.active;
  }

  get ActionType() {
    return this.actionType;
  }

  get isArchived() {
    return this.archived;
  }

  get isDelete() {
    return this.delete;
  }

  withActive(active: boolean): ProductCloneMoveDetailBuilder {
    this.active = active;
    return this;
  }

  withArchived(archived: boolean): ProductCloneMoveDetailBuilder {
    this.archived = archived;
    return this;
  }

  withDelete(deleteOption: boolean): ProductCloneMoveDetailBuilder {
    this.delete = deleteOption;
    return this;
  }

  withActionType(actionType: string): ProductCloneMoveDetailBuilder {
    this.actionType = actionType;
    return this;
  }

  build() {
    return new ProductCloneMoveDetail(this);
  }
}

export class ProductCloneMoveDetail {
  static readonly PRODUCT_UUID_LIST_FIELD_KEY = 'uuidList';
  static readonly ARCHIVED_FIELD_KEY = 'archived';
  static readonly ACTIVE_FIELD_KEY = 'active';
  static readonly DELETE_FIELD_KEY = 'delete';
  static readonly ACTION_TYPE_FIELD_KEY = 'actionType';

  private readonly archived: boolean;
  private readonly active: boolean;
  private readonly delete: boolean;
  private readonly actionType: string;
  private readonly uuidList: any;

  constructor(productCloneMoveDetailBuilder: ProductCloneMoveDetailBuilder) {
    this.archived = productCloneMoveDetailBuilder.isArchived;
    this.active = productCloneMoveDetailBuilder.isActive;
    this.delete = productCloneMoveDetailBuilder.isDelete;
    this.actionType = productCloneMoveDetailBuilder.ActionType;
    this.uuidList = productCloneMoveDetailBuilder.ProductUUIDList;
  }

  get ProductUUIDList() {
    return this.uuidList;
  }

  get ActionType() {
    return this.actionType;
  }

  get isActive() {
    return this.active;
  }

  get isArchived() {
    return this.archived;
  }

  get isDelete() {
    return this.delete;
  }
}
