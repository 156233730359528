import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UsersReducers } from 'src/app/store/app.reducers';

const getUsersSelector = createFeatureSelector<UsersReducers.UsersState>(UsersReducers.TAG);

export const getUsersFilters = createSelector(getUsersSelector, (state) => state?.filters);
export const getUsersSort = createSelector(getUsersSelector, (state) => state?.sort);
export const getUsersPage = createSelector(getUsersSelector, (state) => state?.page);
export const getUsersExtFilt = createSelector(getUsersSelector, (state) => state?.extFilt);
export const getUsersSearchInfo = createSelector(getUsersSelector, (state) => state?.searchInfo);
export const getUsersData = createSelector(getUsersSelector, (state) => state?.data);
export const getTab = createSelector(getUsersSelector, (state) => state?.tab);
