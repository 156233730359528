import { Injectable, OnDestroy } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Permission } from '../enums/permission.enum';
import { Permissions } from '../interfaces/permissions';
import { AuthState } from '../pages/login/store/auth.reducer';
import { AuthSelectors } from '../store/app.selectors';

@Injectable({
  providedIn: 'root',
})
export class UserPermissionService implements OnDestroy {
  userPermissions: Permissions[] = [];
  private isDestroyed = new Subject();

  constructor(private store: Store<AuthState>) {
    this.store
      .select(AuthSelectors.getAuthPermissions)
      .pipe(takeUntil(this.isDestroyed))
      .subscribe((perm) => (this.userPermissions = perm || []));
  }

  ngOnDestroy() {
    this.isDestroyed.next(true);
    this.isDestroyed.complete();
  }

  getPermissionByUUID(uuid: string) {
    return this.userPermissions.find((permission) => permission.uuid === uuid);
  }

  hasPermissionByUUID(uuid: string) {
    return !!this.getPermissionByUUID(uuid);
  }

  getPermissionByName(name: string) {
    return this.userPermissions.find((permission) => permission.name === name);
  }

  hasPermissionByName(name: string) {
    return !!this.getPermissionByName(name);
  }

  isCommunityAdmin() {
    return (
      this.getPermissionByName(Permission.ADMINISTER_COMMUNITY.name) && !this.getPermissionByName(Permission.VIEW_ALL_COMMUNITIES.name)
    );
  }
}
