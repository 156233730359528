import { StructureType } from '../enums/structure-type.enum';
import { Attachment } from '../interfaces/attachment';
import { StructureField } from '../interfaces/structure-field';
import { LocalizableField } from './LocalizableField';

export const StructureFieldsEnum = {
  UUID_FIELD_KEY: 'uuid',
  NAME_FIELD_KEY: 'name',
  LABEL_FIELD_KEY: 'label',
  DESCRIPTION_FIELD_KEY: 'description',
  FIELDS_KEY: 'structureFields',
  PRIORITY_STRUCTURE_FIELD_KEY: 'priority',
  STRUCTURE_FIELD_PREDEFINED_VALUE_KEY: 'predefinedValue',
  STRUCTURE_FIELDS_FIELD_KEY: 'structureFields',
  TYPE_FIELD_KEY: 'type',
  MOBILE_RATIO_KEY: 'mobileRatio',
  DESKTOP_RATIO_KEY: 'desktopRatio',
  TABLET_RATIO_KEY: 'tabletRatio',
  ORDER_KEY: 'order',
} as const;

export class Structure {
  public uuid: string;
  public name: string;
  public label: LocalizableField;
  public description: LocalizableField;
  public priority: number;
  public structureFields: StructureField[];
  public type: StructureType;
  public mobileRatio: number;
  public tabletRatio: number;
  public desktopRatio: number;
  public order = 0;
  public attachment?: Attachment[];
  id?: string;

  constructor(payload: Partial<Structure>) {
    for (const key of Object.values(StructureFieldsEnum)) {
      this[key] = payload[key];
    }
  }
}
