import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { first, skipWhile, switchMap, tap } from 'rxjs/operators';
import { AuthService } from '../auth.service';
import { OrganizationService } from '../services';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router, private organizationService: OrganizationService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    // console.log('AuthenticationGuard.canActivate() ', state.url);

    return this.authService.isDoneLoading$.pipe(
      tap((doneLoading) => {
        // console.log('AuthenticationGuard.canActivate() doneLoading', doneLoading);
        if (!doneLoading) {
          this.authService.runInitialLoginSequence(state.url);
        }
      }),
      skipWhile((x) => x !== true),
      switchMap(() => {
        // console.log('You tried to go to ' + state.url + ' and AuthenticationGuard said ' + this.authService.hasValidToken());
        if (!this.authService.hasValidToken()) {
          return of(false);
        }
        this.organizationService.init();
        return this.organizationService.isReady.pipe(skipWhile((x) => x !== true));
      }),
      first()
    );
  }
}
