import { v4 as uuid } from 'uuid';
import { DataleanDataProviderService } from '../provider/datalean-data-provider.service';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { BaseEditorService } from './base-editor.service';
import { Observable } from 'rxjs';
import { TranslatePipe } from '@ngx-translate/core';
import { Parts } from '../enums/parts.enum';
import { map } from 'rxjs/operators';
import { Filter, FilterAlignment, FilterOperator, FilterPosition, FilterWidgetType } from '../models/Filter';
import { LocalizableField } from '../models/LocalizableField';

// not an injectable because it is not meant to be reused
@Injectable()
export class FilterEditorService extends BaseEditorService {

  constructor(protected dataleanDataService: DataleanDataProviderService, private translatePipe: TranslatePipe) {
    super(dataleanDataService);
  }

  createNewFilter(parentUUID?:string): Filter {
    return {
      alignment: FilterAlignment.HORIZONTAL,
      allowUncheck: false,
      associatedStructureFieldsRef: [],
      backgroundColor: '',
      childrenRef: [],
      collapsed: false,
      collapsible: false,
      defaultValue: '',
      displayName: new LocalizableField(),
      enabled: false,
      enabledWhen: {},
      filterContainer: false,
      filterOptions: [],
      filterWidgetType: FilterWidgetType.DEFAULT,
      iconCls: '',
      image: '',
      label: new LocalizableField(),
      nextStepLabel: null,
      order: 0,
      operator: FilterOperator.AND,
      parentUUID: parentUUID,
      position: FilterPosition.NONE,
      productField: '',
      productUrlLink: '',
      propagateToProductDetail: false,
      showAllProductsOnEmptySearch: false,
      showInSelectedFiltersArea: false,
      sort: {
        ascending: false,
        caseSensitive: false
      },
      uuid: uuid(),
      weight: 0,
      widgetItemName: ''
    }
  }
  init() {
    this.objectData = this.createNewFilter();
  }

  getFeatures(communityUUID?: string) {
    const params = {};
    if (communityUUID) {
      params['featureValueCommunityUUID'] = communityUUID;
    }
    return this.dataleanDataService.getDataForList(environment.filterUrl, params, [Parts.FILTER_OPTIONS]).pipe(map(data=>this.makeTree(data.result as any[])));
  }

  create(): Observable<any> {
    return this.dataleanDataService.createEntity(environment.filterUrl, this.objectData, []);
  }

  createData(data): Observable<any> {
    delete data.children
    delete data.id

    return this.dataleanDataService.createEntity(environment.filterUrl, data, []);
  }

  update(data, communityUUID?: string): Observable<any> {
    delete data.id
    delete data.children

    if (!communityUUID || communityUUID === 'all') {
      return this.dataleanDataService.updateEntity(environment.filterUrl, data, []);
    } else {
      return this.dataleanDataService.updateEntity(environment.filterUrl, data, [], undefined, { communityUUID });
    }
  }

  delete(uuid): Observable<any> {
    return this.dataleanDataService.deleteEntity(environment.filterUrl, uuid);
  }

  private makeTree(arr: any[]) {
    const lut = {};
    let sorted = [];

    function sort(a) {
        const len = a.length;
        let fix = -1;
        for (let i = 0; i < len; i++) {
            while (!!~(fix = a.findIndex((e) => a[i].parentUUID == e.uuid)) && fix > i) {
                [a[i], a[fix]] = [a[fix], a[i]]; // ES6 swap
            }
            lut[a[i].uuid] = i;
        }
        return a;
    }
    sorted = sort([...arr]); // don't modify things that don't belong to you :)
    for (let i = sorted.length - 1; i >= 0; i--) {
        if (sorted[i].parentUUID != null && sorted[lut[sorted[i].parentUUID]]) {
            (!!sorted[lut[sorted[i].parentUUID]].children && sorted[lut[sorted[i].parentUUID]].children.push(sorted.splice(i, 1)[0])) ||
                (sorted[lut[sorted[i].parentUUID]].children = [sorted.splice(i, 1)[0]]);
        }
    }

    return sorted;
}

}
