import { createFeatureSelector, createSelector } from '@ngrx/store';
import { FeedbackReducers } from 'src/app/store/app.reducers';

const getFeedbackState = createFeatureSelector<FeedbackReducers.FeedbackState>(FeedbackReducers.FEEDBACK_TAG);

export const getFeedbackSort = createSelector(getFeedbackState, (state: FeedbackReducers.FeedbackState) => state?.sort);
export const getFeedbackPage = createSelector(getFeedbackState, (state: FeedbackReducers.FeedbackState) => state?.page);
export const getFeedbackSearchInfo = createSelector(getFeedbackState, (state: FeedbackReducers.FeedbackState) => state?.searchInfo);
export const getFeedbackData = createSelector(getFeedbackState, (state: FeedbackReducers.FeedbackState) => state?.data);
export const getFeedbackFilters = createSelector(getFeedbackState, (state: FeedbackReducers.FeedbackState) => state?.filters);
export const getFeedbackMonthsNumber = createSelector(getFeedbackState, (state: FeedbackReducers.FeedbackState) => state?.monthsNumber);
