import { Injectable } from '@angular/core';
import { theme } from 'src/profiles/default/theme.custom';
import { darkTheme, defaultTheme } from 'src/profiles/default/theme.default';
import { Theme } from './theme';

@Injectable({ providedIn: 'root' })
export class ThemeService {
  activeTheme: Theme;

  setTheme(theme: Theme) {
    this.activeTheme = theme;
    Object.keys(theme).forEach((k) => (k !== 'name' ? document.documentElement.style.setProperty(`--${k}`, theme[k]) : ''));
  }

  changeVariable(name, value) {
    document.documentElement.style.setProperty(`--${name}`, value);
  }

  getVariable(name) {
    return this.activeTheme[name];
  }

  getAvailableThemes(): Theme[] {
    const themes = [];
    if (defaultTheme) {
      themes.push(defaultTheme);
    }
    if (darkTheme) {
      themes.push(darkTheme);
    }
    if (theme?.name) {
      themes.push(theme);
    }
    return themes;
  }
}
