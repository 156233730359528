import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { FiltersConstants } from '../enums/filters-constants.enum';
import { Parts } from '../enums/parts.enum';
import { Permission } from '../enums/permission.enum';
import { Community } from '../models/Community';
import { DataleanDataProviderService } from '../provider/datalean-data-provider.service';
import { CommunitySelectActions } from '../store/app.actions';
import { CommunitySelectSelectors } from '../store/app.selectors';
import { CommunitySelectState, FiltersType } from '../store/community-select/community-select.reducer';
import { UserPermissionService } from './user-permission.service';
import { UserSettingsService } from './user-settings-service.service';

@Injectable({
  providedIn: 'root',
})
export class CommunitiesService implements OnDestroy {
  readonly ENV = environment;
  readonly PARTS = [Parts.UPDATE_DATE, Parts.STRUCTURE, Parts.ADMIN, Parts.COMMUNITIES];

  dataList: Community[];
  lastCommunitySelected: string = FiltersConstants.NO_COMMUNITY_SELECT_VALUE; // default/init value
  // showAllCommunityChoice = false;
  showNoCommunityChoice = false;

  communityData: Community;
  canViewAllCommunities: boolean;
  isCommunityAdmin: boolean;
  isDestroyed = new Subject();

  constructor(
    protected dataleanDataService: DataleanDataProviderService,
    private userPermissionService: UserPermissionService,
    private userSettingService: UserSettingsService,
    private store: Store<CommunitySelectState>,
  ) {
    this.init();
  }

  ngOnDestroy() {
    this.isDestroyed.next(true);
    this.isDestroyed.complete();
  }

  init() {
    this.canViewAllCommunities = !!this.userPermissionService.getPermissionByName(Permission.VIEW_ALL_COMMUNITIES.name);
    this.isCommunityAdmin = this.userPermissionService.isCommunityAdmin();

    const filters: FiltersType = {};

    if (!this.canViewAllCommunities || this.isCommunityAdmin) {
      // user is community admin
      filters.communityListAdminUUID = this.userSettingService.getUserUUID();
    }

    if (Object.keys(filters).length) {
      this.store.dispatch(CommunitySelectActions.setFilters({ filters }));
    }

    this.store
      .select(CommunitySelectSelectors.getLastCommunitySelected)
      .pipe(takeUntil(this.isDestroyed))
      .subscribe((lastCommunitySelected) => (this.lastCommunitySelected = lastCommunitySelected));
    this.store
      .select(CommunitySelectSelectors.getFilters)
      .pipe(
        switchMap((filt) => this.dataleanDataService.getDataForList(this.ENV.communityUrl, { ...filt }, [Parts.VALUES])),
        map((data) => {
          const dataList = this.dataleanDataService.mapCommunities(data.result);
          if (this.canViewAllCommunities) {
            this.store.dispatch(CommunitySelectActions.setShowNoCommunityChoice({ showNoCommunityChoice: true }));
          } else if ((this.isCommunityAdmin || this.lastCommunitySelected === undefined) && dataList[0]) {
            this.store.dispatch(CommunitySelectActions.setLastCommunitySelected({ lastCommunitySelected: dataList[0].uuid }));
          }
          this.store.dispatch(CommunitySelectActions.setData({ data: dataList }));
          return dataList;
        }),
        takeUntil(this.isDestroyed),
      )
      .subscribe((data) => {
        this.dataList = structuredClone(data);
      });

    // console.log('CommunitiesService init');
  }

  get List() {
    return this.dataList;
  }

  checkAllCommunitiesSelect(communitySelected?: string) {
    const community = communitySelected || this.lastCommunitySelected;
    return community === FiltersConstants.ALL_COMMUNITIES_SELECT_VALUE;
  }

  checkNoCommunitySelect(communitySelected?: string) {
    const community = communitySelected || this.lastCommunitySelected;
    return community === FiltersConstants.NO_COMMUNITY_SELECT_VALUE;
  }

  checkIfValidCommunitySelected(communitySelected?: string) {
    const community = communitySelected || this.lastCommunitySelected;
    // NOT '' selected and NOT 'allCommunities' selected and community selected(not undefined and not 'null') => VALID COMMUNITY SELECTED
    return !this.checkNoCommunitySelect(community) && !this.checkAllCommunitiesSelect(community) && community && community !== 'null';
  }

  checkIfValidCommunityForFilters(communitySelected?: string) {
    const community = communitySelected || this.lastCommunitySelected;
    // '' selected or NOT 'allCommunities' selected and community selected(not undefined and not 'null') => VALID COMMUNITY SELECTED FOR FILTERS
    return this.checkNoCommunitySelect(community) || (!this.checkAllCommunitiesSelect(community) && community && community !== 'null');
  }

  getLastCommunitySelected() {
    return this.lastCommunitySelected;
  }

  setLastCommunitySelected(lastCommunitySelected: string) {
    this.store.dispatch(CommunitySelectActions.setLastCommunitySelected({ lastCommunitySelected }));
  }

  getDataFromLastCommunitySelected() {
    return this.getCommunityData(this.lastCommunitySelected);
  }

  getCommunityData(communityUUID: string) {
    return this.dataList?.find((community) => {
      return community.uuid === communityUUID;
    });
  }

  cannotAddEntityBasedOnCommunities(communitySelected?: string) {
    const community = communitySelected || this.lastCommunitySelected;
    return this.userPermissionService.isCommunityAdmin() && !this.checkIfValidCommunitySelected(community);
  }

  loadDataForCommunity() {
    this.communityData = this.getDataFromLastCommunitySelected();
  }
}
