import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Parts } from '../enums/parts.enum';
import { StructureType } from '../enums/structure-type.enum';
import ParseLocalizable from '../helpers/localizableValueConverter';
import { LocalizableField } from '../models';
import { ProductAnswer, ProductQuestion } from '../models/product-question';
import { Structure } from '../models/Structure';
import { ToLocalizedValuePipe } from '../pipes/to-localized-value/to-localized-value';
import { DataleanDataProviderService } from '../provider/datalean-data-provider.service';
import { BaseEditorService } from './base-editor.service';
import { LocalizationService } from './localization.service';

@Injectable({
  providedIn: 'root',
})
export class ProductQuestionService extends BaseEditorService {
  flattenedQuestions: (ProductQuestion | ProductAnswer)[];

  constructor(
    dataleanDataProviderService: DataleanDataProviderService,
    private localizationService: LocalizationService,
    private toLocalizedValuePipe: ToLocalizedValuePipe
  ) {
    super(dataleanDataProviderService);
  }

  init() {
    this.objectData = new ProductQuestion();
  }

  initChild(nodeUUID: string, order = 0): ProductAnswer {
    const answer = new ProductAnswer(nodeUUID);
    answer.order = order;
    this.objectData.answers.push(answer);
    return answer;
  }

  create(): Observable<any> {
    delete this.objectData.id;
    return this.dataleanDataService.createEntity(environment.questionUrl, this.objectData, []);
  }

  createData(data: ProductQuestion): Observable<any> {
    return this.dataleanDataService.createEntity(environment.questionUrl, data, [Parts.ALL]);
  }

  update(data: ProductQuestion): Observable<any> {
    return this.dataleanDataService.updateEntity(environment.questionUrl, data, [Parts.ALL]);
  }

  delete(uuid: string): Observable<any> {
    return this.dataleanDataService.deleteEntity(environment.questionUrl, uuid);
  }

  private mapEntity<T extends ProductQuestion | ProductAnswer>(entity: T, payload: T) {
    for (const key of Object.keys(entity)) {
      if (key === 'answers') {
        entity.answers = payload.answers
          .map((a) => this.mapEntity(new ProductAnswer(entity.uuid), a))
          .sort((q1, q2) => q1.order - q2.order);
      } else if (key === 'label') {
        const label = payload.label ?? entity.label;
        entity[key] = ParseLocalizable(label, null, this.localizationService.getActiveEntityLanguage());
      } else {
        entity[key] = payload[key] ?? entity[key];
      }
    }
    return entity;
  }

  getQuestions(): Observable<Array<ProductQuestion>> {
    if (environment.questionUrl) {
      return this.dataleanDataService
        .getAllEntities<ProductQuestion>(environment.questionUrl, [Parts.ALL], { parentUUID: '' })
        .pipe(map((data) => data.result.map((p) => this.mapEntity(new ProductQuestion(), p)).sort((q1, q2) => q1.order - q2.order)));
    }
    return of([]);
  }

  getStructures(): Observable<Array<Structure>> {
    return this.dataleanDataService
      .getDataForList<Structure>(environment.structureUrl, { type: StructureType.PRODUCT }, [Parts.EMPTY])
      .pipe(
        map((data) => {
          if (Array.isArray(data.result)) {
            return data.result;
          }
        })
      );
  }

  private deepChangeRelated(dataset: Array<ProductAnswer>, isRelated: boolean): Array<ProductAnswer> {
    return dataset.map((v) => {
      v.isRelated = isRelated;
      v.answers = this.deepChangeRelated(v.answers, isRelated);
      return v;
    });
  }

  changeParentRelated(dataset: Array<ProductAnswer | ProductQuestion>, child: ProductAnswer | ProductQuestion, isRelated: boolean) {
    let relative: ProductAnswer | ProductQuestion = child;
    while (relative && 'parentUUID' in relative && relative.parentUUID) {
      relative = this.recursiveFind(dataset, relative.parentUUID);
      if (relative && 'isRelated' in relative && relative.isRelated !== isRelated) {
        relative.isRelated = isRelated;
        if (!isRelated) {
          child = relative;
        }
      }
    }
  }

  deepChangeValue<T extends ProductAnswer | ProductQuestion>(
    dataset: Array<T>,
    uuid: string,
    value: ProductAnswer | ProductQuestion,
    newValue?: ProductAnswer | ProductQuestion
  ): Array<T> {
    return dataset.map((v) => {
      if (v.uuid === uuid) {
        if ('isRelated' in newValue && 'isRelated' in v && v.isRelated !== newValue.isRelated) {
          if (newValue.isRelated) {
            v.answers = this.deepChangeRelated(v.answers, newValue.isRelated);
          }
        }
        return this.mapEntity('parentUUID' in v && v.parentUUID ? new ProductAnswer(v.parentUUID) : new ProductQuestion(), {
          ...v,
          ...(newValue ?? value),
        }) as T;
      }
      v.answers = this.deepChangeValue(v.answers, uuid, value, newValue);
      return v;
    });
  }

  recursiveFind(questions: (ProductAnswer | ProductQuestion)[], uuid: string): ProductAnswer | ProductQuestion {
    for (const question of questions) {
      if (question.uuid === uuid) {
        if (!(question.label instanceof LocalizableField)) {
          question.label = new LocalizableField(question.label);
        }
        return question;
      }
      if ('answers' in question && question.answers.length > 0) {
        const found = this.recursiveFind(question.answers, uuid);
        if (found) {
          return found;
        }
      }
    }
    return null;
  }

  deepFindParent(questions: (ProductAnswer | ProductQuestion)[], child: ProductAnswer | ProductQuestion): ProductQuestion {
    if (!('parentUUID' in child) || !child.parentUUID) return child;
    const parent = this.recursiveFind(questions, child.parentUUID);
    if ('parentUUID' in parent && !!parent.parentUUID) {
      return this.deepFindParent(questions, parent);
    }
    return parent;
  }
}
