import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { createAction, props } from '@ngrx/store';
import { SearchInfo } from 'src/app/models/search-info';
import { Structure } from 'src/app/models/Structure';
import { FiltersType, FiltersTypeValues } from '../../../enums';

export const setFilters = createAction('[Communication] set Filters', props<{ filters: FiltersType }>());
export const setFiltersValues = createAction('[Communication] set Filters values', props<{ filters: FiltersTypeValues }>());
export const updateFilters = createAction('[Communication] update Filters', props<{ extFilt: FiltersType }>());
export const setSort = createAction('[Communication] set Sort', props<{ sort: Sort }>());
export const setPage = createAction('[Communication] set Page', props<{ page: PageEvent }>());
export const setSearchInfo = createAction('[Communication] set Search Info', props<{ searchInfo: SearchInfo }>());
export const setData = createAction('[Communication] set Data', props<{ data: any; loadingComplete?: boolean }>());
export const setStructures = createAction('[Communication] set Structures', props<{ structures: Array<Structure> }>());
export const setLoading = createAction('[Communication] Set Loading', props<{ loading: boolean }>());

export const updateTabIndex = createAction('[Communications] Update Tab Index', props<{ activeTabIndex: number }>());
