import { v4 as uuid } from 'uuid';
import { DataleanDataProviderService } from '../provider/datalean-data-provider.service';
import { Injectable } from '@angular/core';
import { BaseEditorService } from './base-editor.service';
import {Observable, of} from 'rxjs';
import { CommunitiesService } from './communities.service';
import { TranslatePipe } from '@ngx-translate/core';

// not an injectable because it is not meant to be reused
@Injectable()
export class CustomizationGroupEditorService extends BaseEditorService {
  constructor(
    protected dataleanDataService: DataleanDataProviderService,
    private translatePipe: TranslatePipe,
    private communitiesService: CommunitiesService
  ) {
    super(dataleanDataService);
  }

  init() {
    this.objectData = {
      uuid: uuid(),
      communityUUID: this.communitiesService.getLastCommunitySelected(),
      name: this.translatePipe.transform('CUSTOMIZATION_GROUP.NEW_CUSTOMIZATION_GROUP'),
      customizationBOList: [],
    };
  }

  initCustomization(type) {
    this.objectData = {
      uuid: uuid(),
      communityUUID: this.communitiesService.getLastCommunitySelected(),
      type,
      name: this.translatePipe.transform('CUSTOMIZATION_GROUP.NEW_CUSTOMIZATION'),
    };
  }

  getCustomizationGroups(additionalParams): Observable<any> {
    //
    return of({ result: [] });
  }

  create(): Observable<any> {
    throw 'customization groups no longer exist';
  }

  update(data): Observable<any> {
    throw 'customization groups no longer exist';
  }

  createData(data): Observable<any> {
    throw 'customization groups no longer exist';
  }

  delete(uuid): Observable<any> {
    throw 'customization groups no longer exist';
  }

}
