<div class="message-container">
  <modal-header [title]="title | translate" (cancel)="onCancel()" (save)="onSave()" [disableSave]="disableSave"></modal-header>
  <div class="main-container">
    <h3 class="subtitle" *ngIf="status">{{status | translate}}</h3>
    <h4 *ngIf="message" [innerHTML]="message | translate"></h4>
    <div *ngIf="statistics" class="statistics">
      <div *ngFor="let statistic of statistics">
        <table>
          <tbody>
          <tr>
            <td class="value">{{statistic.value}}</td>
          </tr>
          <tr>
            <td class="label">{{statistic.label | translate}}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="code" *ngIf="code" [innerHTML]="'<pre>'+code+'</pre>'"></div>
  </div>
</div>
