<div class="permission-container">
  <modal-header
    title="{{ 'MODALS.DISPLAY_PERMISSION.TITLE' | translate | uppercase }}"
    (cancel)="onCancel()"
    (save)="onSave()"></modal-header>
  <div class="spinner-container" *ngIf="isLoading">
    <mat-spinner class="loading-spinner"></mat-spinner>
  </div>
  <div class="table-container">
    <mat-tab-group
      *ngIf="!isLoading"
      animationDuration="0ms"
      class="single-tabbed"
      [class.hide-header]="cards.length <= 1"
      [selectedIndex]="selectedTab"
      (selectedTabChange)="changeTab($event); selectedTab = $event.index">
      <mat-tab>
        <ng-template mat-tab-label>
          <!--ng-container *ngIf="deviceDetectorService.isMobile()"-->
          {{ 'MODALS.DISPLAY_PERMISSION.GROUPS' | translate }}
          <!--/ng-container-->
        </ng-template>
        <ng-template matTabContent>
          <table mat-table [dataSource]="tableDataSource">
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef>{{ 'MODALS.DISPLAY_PERMISSION.NAME' | translate }}</th>
              <td mat-cell *matCellDef="let group">
                <span>{{ group.name }}</span>
              </td>
            </ng-container>

            <ng-container matColumnDef="display-permission">
              <th mat-header-cell *matHeaderCellDef>{{ 'MODALS.DISPLAY_PERMISSION.PERMISSION' | translate }}</th>
              <td mat-cell *matCellDef="let group">
                <mat-checkbox
                  class="permission-checkbox"
                  [checked]="isChecked(group.uuid)"
                  (change)="updateExceptionList($event, group.uuid)"></mat-checkbox>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="['name', 'display-permission']; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: ['name', 'display-permission']"></tr>
          </table>
        </ng-template>
      </mat-tab>
      <mat-tab *ngFor="let card of cards | slice : 1; let i = index; trackBy: trackByFn">
        <ng-template mat-tab-label>
          {{ 'MODALS.DISPLAY_PERMISSION.' + card.title | translate }}
        </ng-template>
        <ng-template matTabContent>
          <table mat-table [dataSource]="tableDataSource">
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef>{{ 'MODALS.DISPLAY_PERMISSION.NAME' | translate }}</th>
              <td mat-cell *matCellDef="let group">
                <span>{{ group.name }}</span>
              </td>
            </ng-container>

            <ng-container matColumnDef="display-permission">
              <th mat-header-cell *matHeaderCellDef>{{ 'MODALS.DISPLAY_PERMISSION.PERMISSION' | translate }}</th>
              <td mat-cell *matCellDef="let group">
                <mat-checkbox
                  class="permission-checkbox"
                  [checked]="isChecked(group.uuid)"
                  (change)="updateExceptionList($event, group.uuid)"></mat-checkbox>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="['name', 'display-permission']; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: ['name', 'display-permission']"></tr>
          </table>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </div>
  <mat-paginator [pageSize]="10" [pageSizeOptions]="[10, 25]" hidePageSize="true"> </mat-paginator>
</div>
