import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Parts } from '../enums/parts.enum';
import { Integration } from '../models';
import { DataleanDataProviderService } from '../provider/datalean-data-provider.service';
import { LocalizationService } from './localization.service';

interface StorageInfo {
  token: string;
  bucket_name: string;
  api_key: string;
}

interface Config {
  uuid: string;
  organizationUUID: string;
  type: string;
  key: string;
  value: any;
}

@Injectable({
  providedIn: 'root',
})
export class OrganizationService implements OnDestroy {
  integrations: string[] = [];
  storageInfo: StorageInfo;
  configurations: Config[] = [];
  private _isReady = new BehaviorSubject(false);
  isReady = this._isReady.asObservable();

  constructor(
    private http: HttpClient,
    private localizationService: LocalizationService,
    private dataleanDataProvider: DataleanDataProviderService,
  ) {}

  init() {
    this.getIntegrations();
    this.getConfigurations();
  }

  ngOnDestroy() {
    this._isReady.complete();
  }

  setSensitiveConfigs() {
    this.dataleanDataProvider
      .getDataForList(environment.configurationUrl, undefined, [Parts.SENSITIVE_CONFIGURATIONS])
      .subscribe((data) => this.mapConfigurations(data.result));
  }

  refreshGStorageToken() {
    const requestStorageInfoUrl =
      environment.organizationUrl + '?key=STORAGE_GOOGLECLOUD_CONFIG&organizationUUID=' + environment.organizationUUID;
    return this.http.get(requestStorageInfoUrl).pipe(
      map((data) => {
        const storageInfo: StorageInfo = data as StorageInfo;
        this.storageInfo = {
          token: 'Bearer ' + storageInfo.token,
          bucket_name: storageInfo.bucket_name,
          api_key: storageInfo.api_key,
        };
      }),
    );
  }

  getConfig(key: string) {
    return this.configurations?.find((config) => config.key === key);
  }

  getConfigValue(key: string) {
    const config = this.getConfig(key);
    if (config) {
      let returnValue = config.value;
      if (config.type === 'json') {
        try {
          returnValue = JSON.parse(config.value);
        } catch {
          console.error('Could not parse config.value -> ', config.value);
        }
      }
      return returnValue;
    }
    return null;
  }

  private getIntegrations() {
    this.dataleanDataProvider.getAllEntities<Integration>(environment.integrationUrl, [Parts.EMPTY]).subscribe(({ result }) => {
      if (result) {
        this.integrations = result.map((integration) => integration.type);
      }
    });
  }

  private getConfigurations() {
    this._isReady.next(false);
    this.dataleanDataProvider
      .getDataForList(environment.configurationUrl, undefined, [])
      .pipe(map((data) => data.result))
      .subscribe((configurations) => {
        this.mapConfigurations(configurations);
        this._isReady.next(true);
      });
  }

  private mapConfigurations(data?) {
    if (data) {
      this.configurations = [...data].map((data) => {
        const { _id, ...d } = data;
        return d;
      });
    }

    for (const config of this.configurations) {
      switch (config.key) {
        case 'activeLocales':
          this.localizationService.setAvailableLanguages([...config.value]);
          break;
        case 'defaultLocale':
          this.localizationService.setDefaultLang(config.value);
          break;
        default:
          break;
      }
    }
  }
}
