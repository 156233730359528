import { ObjectValuesType } from '../helpers/utilities';

export const EntityStatuses = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  DELETED: 'DELETED',
  DISABLED: 'DISABLED',
  ENABLED: 'ENABLED',
  PUBLISHED: 'PUBLISHED',
  DRAFT: 'DRAFT',
  ARCHIVED: 'ARCHIVED',
  NULL: ''
} as const;

export type CoreEntityStatus = ObjectValuesType<Pick<typeof EntityStatuses, 'PUBLISHED' | 'DRAFT' | 'ARCHIVED'>>;
export type EntityStatuses = ObjectValuesType<typeof EntityStatuses>;
