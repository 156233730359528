import { PageEvent } from '@angular/material/paginator';
import { createAction, props } from '@ngrx/store';
import { Sort } from '@angular/material/sort';
import { FeatureValue, SearchInfo } from '../../../../models';
import { LessonFiltersType, LessonFiltersTypeValues } from '../../../../enums';

export const setData = createAction('[Lessons] Set Products Data', props<{ data: object }>());
export const setPage = createAction('[Lessons] Set Page', props<{ page: PageEvent }>());
export const setFilters = createAction('[Lessons] Set Filters', props<{ filters: LessonFiltersType }>());
export const setFeatures = createAction('[Lessons] Set Lesson Features', props<{ features: Array<FeatureValue> }>());
export const setFiltersValues = createAction('[Lessons] set Filters values', props<{ filters: LessonFiltersTypeValues }>());
export const setSort = createAction('[Lessons] Set Sort', props<{ sort: Sort }>());
export const setSearchInfo = createAction('[Lessons] Set Search Info', props<{ searchInfo: SearchInfo }>());
