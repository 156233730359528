import {Injectable} from "@angular/core";
import {map, switchMap} from "rxjs/operators";
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {BulkActions} from "src/app/store/app.actions";
import {DataleanDataProviderService} from "src/app/provider/datalean-data-provider.service";
import {Store} from "@ngrx/store";
import {Parts} from "../../../enums/parts.enum";
import {environment} from "../../../../environments/environment";

@Injectable()
export class BulkEffects {
  constructor(
    private store: Store,
    private actions$: Actions,
    private dataleanServiceProvider: DataleanDataProviderService
  ) {
  }

  multipleUpdate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BulkActions.setMultipleUpdate),
      switchMap(datas => {
        return this.dataleanServiceProvider.multipleUpdate(datas.multipleData['objToSave'], datas.multipleData['communityUUID'] || datas.multipleData['communityUUID'] === '' ? datas.multipleData['communityUUID']:null, datas.multipleData['parts'] ? datas.multipleData['parts'] : [Parts.ALL], datas.multipleData['url']).pipe(map(data => {
            return [datas.multipleData['message'], data];
          }
        ));
      }),
      map((result) => BulkActions.setData({data: result}))
    )
  );
}
