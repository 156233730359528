import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';

@Component({
  selector: 'modal-header',
  templateUrl: './modal-header.component.html',
  styleUrls: ['./modal-header.component.scss'],
})
export class ModalHeaderComponent implements OnInit {
  @Input() title = '';
  @Input('disableSave') disableSave = false;
  @Input() showLanguage = false;
  @Input() languages: string[];
  @Input() defaultLanguage: string;
  @Input() activeLocale: string;

  @Output() changedLanguage = new EventEmitter<string>();
  @Output() cancel = new EventEmitter();
  @Output() save = new EventEmitter();

  onCancel() {
    this.cancel.emit();
  }
  onSave() {
    this.save.emit();
  }

  ngOnInit() {
    this.languages = this.languages?.filter((lang) => lang !== this.defaultLanguage);
  }
}
