
<div class="upload-progress-container">
  <div class="title">{{'MODALS.UPLOAD.WAIT' |translate}}</div>
  <div class="progress">
    <div class="percentage">{{this.uploadService.progress}}%</div>
  <mat-spinner   mode="determinate" [value]="this.uploadService.progress" [ngClass]="{'completed': this.uploadService.progress === 100}">
  </mat-spinner>

  </div>
  <div class="numeric-progress">{{this.uploadService.partialSize |toFileSize}} / {{this.uploadService.totalSize |toFileSize}}</div>

  <div class="button-container">
    <button class="cancel" (click)="this.close()" *ngIf="this.uploadService.progress !== 100">
      {{'MODALS.UPLOAD.UNDO' |translate}}
    </button>
    <button class="close" (click)="this.close('background')">
      {{'MODALS.UPLOAD.CLOSE' |translate}}
    </button>
  </div>
  <div class="bottom-label" *ngIf="this.uploadService.progress !== 100">
    {{'MODALS.UPLOAD.BACKGROUND_MESSAGE' |translate}}Il caricamento continuerà in background</div>
</div>
