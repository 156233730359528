import { createReducer, on } from '@ngrx/store';
import { LessonsAction } from '../../../../store/app.actions';
import { LessonsState } from './lessons-list.state';
import { SearchInfo } from '../../../../models';
import { FiltersVisibility, LessonFiltersType } from '../../../../enums/filter-type.enum';
import DeepCopy from '../../../../helpers/deep_copy';

const defaultFilters = {
  featureValueList: { visibility: FiltersVisibility.VISIBLE, value: undefined }
};

const initialState: LessonsState = {
  data: undefined,
  features: undefined,
  page: { pageIndex: 0, pageSize: 25, length: undefined },
  sort: { active: 'updateDate', direction: 'desc' },
  filters:  DeepCopy(defaultFilters),
  searchInfo: {
    searchFields: 'name',
    searchFieldsParamName: 'searchFields',
    searchValueParamName: 'q',
  } as SearchInfo,
};

export const lessonReducer = createReducer(
  initialState,
  on(LessonsAction.setData, (state: LessonsState, { data }) => ({
    ...state,
    data,
  })),
  on(LessonsAction.setFilters, (state: LessonsState, { filters }) => ({
    ...state,
    filters,
  })),
  on(LessonsAction.setFiltersValues, (state: LessonsState, { filters }) => ({
    ...state,
    filters: Object.entries(filters).reduce((acc, [key, value]) => {
      if (typeof acc[key] === 'object') {
        acc[key] = { ...acc[key], value }
      } else {
        acc[key] = { value, visibility: defaultFilters?.[key]?.visibility ?? FiltersVisibility.VISIBLE };
      }
      return acc;
    }, DeepCopy<LessonFiltersType>(state.filters)),
  })),
  on(LessonsAction.setSort, (state: LessonsState, { sort }) => ({
    ...state,
    sort,
  })),
  on(LessonsAction.setPage, (state: LessonsState, { page }) => ({
    ...state,
    page,
  })),
  on(LessonsAction.setFeatures, (state: LessonsState, { features }) => ({
    ...state,
    features,
  })),
  on(LessonsAction.setSearchInfo, (state: LessonsState, { searchInfo }) => ({
    ...state,
    searchInfo,
  }))
);
