export enum OrderStatusEnum {
  OPENED = "OPENED",
  APPROVING = "APPROVING",
  PROCESSING = "PROCESSING",
  MODIFYING_MERCHANT = "MODIFYING_MERCHANT",
  ACCEPTING = "ACCEPTING",
  ACCEPTED = "ACCEPTED",
  TO_PAY = "TO_PAY",
  PAYED = "PAYED",
  REFUSED = "REFUSED",
  DELIVERED = "DELIVERED",
  APPROVED = "APPROVED",
  EXPIRED = "EXPIRED"
}
