import { createAction, props } from '@ngrx/store';
import { FiltersConstants } from 'src/app/enums/filters-constants.enum';
import { Community } from 'src/app/models/Community';
import { FiltersType } from './community-select.reducer';

export const setFilters = createAction('[Communityselect] Set Filters', props<{ filters: FiltersType }>());
export const updateShowNoCommunityChoice = createAction(
  '[Communityselect] update showNoCommunityChoice',
  props<{ showNoCommunityChoice: boolean }>()
);
export const setShowNoCommunityChoice = createAction(
  '[Communityselect] Set showNoCommunityChoice',
  props<{ showNoCommunityChoice: boolean }>()
);
export const setLastCommunitySelected = createAction(
  '[Communityselect] Set lastCommunitySelected',
  props<{ lastCommunitySelected: FiltersConstants | string }>()
);
export const setData = createAction('[Communityselect] Set data', props<{ data: Array<Community> }>());
