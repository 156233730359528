import { LocalizableField } from '../models/LocalizableField';

const transform = (value: any, forcedLocale?: string, activeLanguage?: string) => {
  if (value === undefined || value === null) {
    return '';
  }
  const activeLan = forcedLocale || activeLanguage;
  if (value instanceof LocalizableField) {
    return value.getLocalizedValue(activeLan);
  }
  if (
    typeof value === 'string' ||
    value instanceof String ||
    typeof value === 'number' ||
    value instanceof Number ||
    typeof value === 'boolean' ||
    value.assetType
  ) {
    return value;
  }
  if (Array.isArray(value)) {
    return value.find((v) => v.language === activeLan)?.value || '';
  }
  return value?.value ?? value?.[activeLan] ?? '';
};

export default function ParseLocalizable(
  value: Array<{ language: string; value: string }> | Record<string, string> | string,
  forcedLocale?: string,
  activeLanguage?: string
): LocalizableField {
  let locField: LocalizableField;
  if (Array.isArray(value)) {
    locField = new LocalizableField(value);
  } else if (typeof value === 'object') {
    locField = new LocalizableField(
      Object.keys(value).reduce((ar, language) => {
        return ar.concat([{ language, value: value[language] }]);
      }, [])
    );
  } else if (!!forcedLocale || !!activeLanguage) {
    locField = LocalizableField.createWithLocalizedValue({
      language: forcedLocale ?? activeLanguage,
      value: transform(value, forcedLocale, activeLanguage),
    });
  } else {
    locField = new LocalizableField();
  }
  return locField;
}
