import {v4 as uuid} from 'uuid';
import {DataleanDataProviderService} from '../provider/datalean-data-provider.service';
import {environment} from 'src/environments/environment';
import {Parts} from '../enums/parts.enum';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {CustomizationTypeEnum} from "../enums/customization-type.enum";
import {LocalizationService} from "./localization.service";
import {ToLocalizedValuePipe} from "../pipes/to-localized-value/to-localized-value";
import {OrderStatusEnum} from "../enums/order-status.enum";
import {OrderTypeEnum} from "../enums/order-type.enum";
import {TranslatePipe} from "@ngx-translate/core";
import { BaseEditorService } from './base-editor.service';


interface MappedRow {
  featureValueName: string;
  rows: any[];
}

// not an injectable because it is not meant to be reused
@Injectable()
export class OrderService extends BaseEditorService {

  orderData;

  // featureValue.name (to localize), row
  rowMap: MappedRow[] = [];

  constructor(protected dataleanDataService: DataleanDataProviderService,
              private localizationService: LocalizationService,
              private translatePipe: TranslatePipe,
              private toLocalizedValuePipe: ToLocalizedValuePipe) {
                super(dataleanDataService)
  }

  init(order?, featureValues?, customizations?) {
    this.rowMap = [];

    if (order) {
      this.orderData = order;
      featureValues?.forEach(featureValue => {
        const rowArray = [];
        this.orderData.orderRowBOS.forEach(orderRow => {
          if (orderRow.product.featureValueList.find((productFeatureValue) => productFeatureValue.uuid === featureValue.uuid)) {
            if (orderRow.customizations && customizations) {
              const customizationList = [];
              if (orderRow.customizations?.find(customUUID => customizations?.find(custom => custom.uuid == customUUID && custom.type == CustomizationTypeEnum.VARIANT))) {
                const baseProductPriceTag = {name: {}};
                baseProductPriceTag['name'][this.localizationService.getActiveEntityLanguage()] = "Prezzo base";
                baseProductPriceTag['basePrice'] = this.toLocalizedValuePipe.transform(orderRow.product.values.find(value => value.structureFieldName == "price").value);
                customizationList.push(baseProductPriceTag);
              }
              orderRow.customizations.map(customization => {
                const found = customizations?.find(custom => custom.uuid == customization);
                if (found) {
                  if (found.type == CustomizationTypeEnum.VARIANT) {
                    found.name[this.localizationService.getActiveEntityLanguage()] = "Variante " + found.name[this.localizationService.getActiveEntityLanguage()];
                    found.basePrice = orderRow.product.customizationList.find(productCustom => productCustom.uuid == found.uuid)?.price;
                  }
                  customizationList.push(found);
                }
              });
              if (customizationList.length > 0) orderRow.customizations = customizationList;
              else orderRow.customizations = null;
            }
            rowArray.push(orderRow);
          }
        });
        this.rowMap.push({featureValueName: featureValue.name, rows: rowArray});
      });
      const customArray = [];
      this.orderData.orderRowBOS.forEach(orderRow => {
        if (environment['customProductStructureUUID'] && orderRow.product.structureUUID == environment['customProductStructureUUID']) {
          customArray.push(orderRow);
        }
      });
      if (customArray.length > 0) this.rowMap.push({
        featureValueName: this.translatePipe.transform('MODALS.PRODUCT_SELECTION.CUSTOM_LIST'),
        rows: customArray
      });
    } else {
      this.orderData = {};
    }
  }

  convertDate(dateString) {
    return this.dataleanDataService.convertDate(dateString);
  }

  update(): Observable<any> {
    delete this.orderData.reports;
    this.orderData.orderRowBOS.forEach((orderRow) => {
      delete orderRow.product.reports;
      orderRow.customizations = orderRow.customizations?.filter(customization => customization.uuid != undefined).map(customization => customization.uuid);
    });
    if (this.orderData.status == "TO_PAY") this.orderData.payed = false;
    if (this.orderData.status == "PAYED") this.orderData.payed = true;
    return this.dataleanDataService.updateEntity(environment.orderUrl, this.orderData, [Parts.ALL]);
  }

  initNew(incrementalNumber, communityUUID) {
    this.orderData = {
      uuid: uuid(),
      incrementalNumber: incrementalNumber ? incrementalNumber + 1 : 1,
      deliveryCost: 0,
      processStatus: OrderStatusEnum.OPENED,
      userUUID: null,
      user: null,
      communityUUID: communityUUID,
      deliveryType: "ONSITE",
      type: OrderTypeEnum.PHONE,
      addressUUID: null,
      orderDate: new Date().toISOString(),
      orderRowBOS: [{
        uuid: uuid(),
        totalItems: 1,
        totalPrice: 0
      }],
      orderValueBOS: [],
      paymentMethod: "at_delivery",
      paymentDetails: "",
      hour: (new Date().getHours()).toString().slice(-2) + ":" + (new Date().getMinutes()).toString().slice(-2),
      payed: false,
      latLng: ""
    }
  }

  addOrderValue(type, value) {
    this.orderData.orderValueBOS.push({
      uuid: uuid(),
      type,
      value
    });
  }

  create() {
    delete this.orderData.reports;
    this.orderData.orderRowBOS = this.orderData.orderRowBOS.filter(row => row.product);
    this.orderData.orderRowBOS.forEach((orderRow) => {
      delete orderRow.product.reports;
      orderRow.customizations = orderRow.customizations?.filter(customization => customization.uuid != undefined).map(customization => customization.uuid);
    });
    return this.dataleanDataService.createEntity(environment.orderUrl, this.orderData, [Parts.ALL]);
  }
}
