import {MatPaginatorIntl} from '@angular/material/paginator';
import {Injectable} from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
@Injectable({
  providedIn: 'root',
})
export class MatPaginatorIntlCustom extends MatPaginatorIntl {
  itemsPerPageLabel = 'Rows per Page:';

  constructor(private translatePipe: TranslatePipe){
    super();
    this.itemsPerPageLabel = translatePipe.transform('REMOTE_DATA_TABLE.PAGINATOR.ROWS_PER_PAGE')
  }
  getRangeLabel = function(page, pageSize, length) {
    if (length === 0 || pageSize === 0) {
      return `0 ${this.translatePipe.transform('REMOTE_DATA_TABLE.PAGINATOR.OF')} ${length}`;
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    // If the start index exceeds the list length, do not try and fix the end index to the end.
    const endIndex = startIndex < length ?
      Math.min(startIndex + pageSize, length) :
      startIndex + pageSize;
    return `${startIndex + 1} - ${endIndex} ${this.translatePipe.transform('REMOTE_DATA_TABLE.PAGINATOR.OF')} ${length}`;
  };

}
