import { BaseEnvironment } from './baseEnvironment.interface';
export const baseEnvironment: BaseEnvironment = {
  production: false,

  authenticationUrl: 'https://auth.datalean-nodejs-dev.catalean.com/',
  usersUrl: 'https://user.datalean-nodejs-dev.catalean.com/user/',
  surveyUrl: 'https://survey.datalean-nodejs-dev.catalean.com/survey/',
  surveyResponseUrl: 'https://survey.datalean-nodejs-dev.catalean.com/response/',
  groupsUrl: 'https://user.datalean-nodejs-dev.catalean.com/user/applicationGroup/',
  smartGroupsUrl: 'https://user.datalean-nodejs-dev.catalean.com/user/applicationSmartGroup/',
  contestUrl: 'https://contest.datalean-nodejs-dev.catalean.com/contest/',
  tableUsersUrl: 'https://user.datalean-nodejs-dev.catalean.com/user/smartcommunity/',
  applicationUsersUrl: 'https://user.datalean-nodejs-dev.catalean.com/user/application/',
  productUrl: 'https://core-entities.datalean-nodejs-dev.catalean.com/product/',
  contentUrl: 'https://core-entities.datalean-nodejs-dev.catalean.com/content/',
  communicationUrl: 'https://core-entities.datalean-nodejs-dev.catalean.com/communication/',
  communityUrl: 'https://community.datalean-nodejs-dev.catalean.com/community/',
  mediaLibraryUrl: 'https://media-library.datalean-nodejs-dev.catalean.com/media-library/',
  structureUrl: 'https://structure.datalean-nodejs-dev.catalean.com/structure/',
  structureFieldUrl: 'https://structure.datalean-nodejs-dev.catalean.com/structureField/',
  filterUrl: 'https://filter.datalean-nodejs-dev.catalean.com/filter/',
  featureValueUrl: 'https://feature.datalean-nodejs-dev.catalean.com/featureValue/',
  organizationUrl: 'https://organization.datalean-nodejs-dev.catalean.com/organization/',
  configurationUrl: 'https://configuration.datalean-nodejs-dev.catalean.com/config/',
  rolesUrl: ' https://user.datalean-nodejs-dev.catalean.com/role/',
  permissionUrl: ' https://user.datalean-nodejs-dev.catalean.com/permission/',
  userStructureUrl: 'https://structure.datalean-nodejs-dev.catalean.com/userstructure/',
  wishlistUrl: 'https://core-entities.datalean-nodejs-dev.catalean.com/wishlist/',
  templateUrl: 'https://translator.datalean-nodejs-dev.catalean.com/template',
  integrationUrl: 'https://configuration.datalean-nodejs-dev.catalean.com/integration/',
  resourceUrl: 'https://reservation.datalean-nodejs-dev.catalean.com/resource/',
  availabilityRuleUrl: 'https://reservation.datalean-nodejs-dev.catalean.com/availability-rule/',
  bookableProductUrl: 'https://reservation.datalean-nodejs-dev.catalean.com/product/',
  reservationsUrl: 'https://reservation.datalean-nodejs-dev.catalean.com/reservation/',
  workflowUrl: 'https://workflow.datalean-nodejs-dev.catalean.com/workflow/',
  workflowEntityUrl: 'https://workflow.datalean-nodejs-dev.catalean.com/workflow-entity/',
  lessonUrl: 'https://lms.datalean-nodejs-dev.catalean.com/lesson/',
  // authenticationUrl: 'http://localhost:3020/',
  // usersUrl: 'http://localhost:3002/user/',
  // surveyUrl: 'http://localhost:3010/survey/',
  // surveyResponseUrl: 'http://localhost:3010/response/',
  // groupsUrl: 'http://localhost:3002/user/applicationGroup/',
  // smartGroupsUrl: 'http://localhost:3002/user/applicationSmartGroup/',
  // contestUrl: 'http://localhost:3012/contest/',
  // tableUsersUrl: 'http://localhost:3002/user/smartcommunity/',
  // applicationUsersUrl: 'http://localhost:3002/user/application/',
  // productUrl: 'http://localhost:3006/product/',
  // contentUrl: 'http://localhost:3006/content/',
  // communicationUrl: 'http://localhost:3006/communication/',
  // communityUrl: 'http://localhost:3009/community/',
  // mediaLibraryUrl: 'http://localhost:3013/media-library/',
  // structureUrl: 'http://localhost:3003/structure/',
  // structureFieldUrl: 'http://localhost:3003/structureField/',
  // filterUrl: 'http://localhost:3008/filter/',
  // featureValueUrl: 'http://localhost:3007/featureValue/',
  // organizationUrl: 'http://localhost:3001/organization/',
  // configurationUrl: 'http://localhost:3004/config/',
  // rolesUrl: ' http://localhost:3002/role/',
  // permissionUrl: ' http://localhost:3016/permission/',
  // userStructureUrl: 'http://localhost:3003/userstructure/',
  // wishlistUrl: 'http://localhost:3006/wishlist/',
  // templateUrl: 'http://localhost:3005/template',
  // integrationUrl: 'http://localhost:3004/integration/',
  // resourceUrl: 'http://localhost:3016/resource/',
  // availabilityRuleUrl: 'http://localhost:3016/availability-rule/',
  // bookableProductUrl: 'http://localhost:3016/product/',
  // reservationsUrl: 'http://localhost:3016/reservation/',
  // workflowUrl: 'http://localhost:3017/workflow/',
  // workflowEntityUrl: 'http://localhost:3017/workflow-entity/',
  // lessonUrl: 'http://localhost:3018/lesson/',

  orderUrl: 'https://dataleandev.catalean.com/order/',
  gstorageUrl: 'https://dataleandev.catalean.com/gstorage/',
  feedbackUrl: 'https://datalean.catalean.com/generalfeedback/feedback/',
  bookableResourceUrl: 'https://dataleandev.catalean.com/booking/',
  changelogUrl: 'https://dataleandev.catalean.com/changelog/',
  entityChangelogUrl: 'https://dataleandev.catalean.com/entityChangelog/',

  privacyUrl: 'https://addiction.it/privacy-policy/',
  conditionTermUrl: 'https://addiction.it/privacy-policy/',
  tableStructureUUID: '',
  cataleanUrl: '',
  orgLogoUrl: '',

  version: '2.2.6',
};
