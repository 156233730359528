import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { createAction, props } from '@ngrx/store';
import { SearchInfo } from 'src/app/models/search-info';
import { FiltersType } from './feedback-detail.reducer';

export const setFeedbackFilters = createAction('[FeedbackDetail] set Filters', props<{ filters: FiltersType }>());
export const setFeedbackSort = createAction('[FeedbackDetail] set Sort', props<{ sort: Sort }>());
export const setFeedbackPage = createAction('[FeedbackDetail] set Page', props<{ page: PageEvent }>());
export const setFeedbackSearchInfo = createAction('[FeedbackDetail] set Search Info', props<{ searchInfo: SearchInfo }>());
export const setFeedbackData = createAction('[FeedbackDetail] set Data', props<{ data: any }>());
export const setFeedbackMonthsNumber = createAction('[FeedbackDetail] set monthsNumber', props<{ monthsNumber: number }>());
export const setMultipleUpdateResult = createAction('[FeedbackDetail] multiple update finished', props<{ multipleData: any }>());
