import { createFeatureSelector, createSelector } from '@ngrx/store';
import { BookableResourcesReducers } from 'src/app/store/app.reducers';

const getBookableResourcesState =
  createFeatureSelector<BookableResourcesReducers.BookableResourcesState>(
    BookableResourcesReducers.TAG
  );

export const getBookableResourcesFilters = createSelector(
  getBookableResourcesState,
  (state) => state?.filters
);

export const getBookableResourcesSort = createSelector(
  getBookableResourcesState,
  (state) => state?.sort
);

export const getBookableResourcesPage = createSelector(
  getBookableResourcesState,
  (state) => state?.page
);

export const getBookableResourcesExtFilt = createSelector(
  getBookableResourcesState,
  (state) => state?.extFilt
);

export const getBookableResourcesSearchInfo = createSelector(
  getBookableResourcesState,
  (state) => state?.searchInfo
);

export const getBookableResourcesData = createSelector(
  getBookableResourcesState,
  (state) => state?.data
);

export const getCustomizationData = createSelector(
  getBookableResourcesState,
  (state) => state?.customizationData
);

export const getBookableResourcesFeatures = createSelector(
  getBookableResourcesState,
  (state) => state?.features
);

export const getBookableResourcesStructures = createSelector(
  getBookableResourcesState,
  (state) => state?.structures
);

export const getGridView = createSelector(
  getBookableResourcesState,
  (state) => state?.gridView
);
