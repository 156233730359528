import * as BulkReducers from '../components/grid-and-table-bulk-actions/store/bulk.reducer';
import * as MediaLibraryModalReducers from '../components/modal/media-library-explorer-multiple/store/media-library-modal.reducer';
import * as VersionSelectionModalReducers from '../components/modal/version-selection/store/version-selection-modal.reducer';
import * as AppContestsReducers from '../pages/app-contests/store/app-contests.reducer';
import * as AppGroupsReducers from '../pages/app-groups/store/app-groups.reducer';
import * as AppUsersReducers from '../pages/app-users/store/app-users.reducer';
import * as BookableResourcesReducers from '../pages/bookable-resources/store/bookable-resources.reducer';
import * as CockpitReducers from '../pages/communications/store/cockpit.reducer';
import * as CommunicationsReducers from '../pages/communications/store/communications.reducer';
import * as CommunitiesReducers from '../pages/communities/store/communities.reducer';
import * as ContentsReducers from '../pages/contents/store/contents.reducer';
import * as FeedbackReducers from '../pages/feedback-detail/store/feedback-detail.reducer';
import * as AuthReducers from '../pages/login/store/auth.reducer';
import * as MediaLibraryReducers from '../pages/media-library/store/media-library.reducer';
import * as OrdersReducers from '../pages/orders/store/orders.reducer';
import * as ProductsReducers from '../pages/products/store/products.reducer';
import * as AvailabilityRulesReducers from '../pages/reservations/tabs/availability-rules/store/availability-rules.reducer';
import * as ResourcesReducers from '../pages/reservations/tabs/resources/store/resources.reducer';
import * as BookableProductsReducers from '../pages/reservations/tabs/bookable-products/store/bookable-products.reducer';
import * as RolesReducers from '../pages/roles/store/roles.reducer';
import * as SurveyReducers from '../pages/survey/store/survey.reducer';
import * as TablesReducers from '../pages/tables/store/tables.reducer';
import * as UsersReducers from '../pages/users/store/users.reducer';
import * as CommunitySelectReducers from './community-select/community-select.reducer';
import * as IntegrationSelectReducers from './integration-select/integration-select.reducer';
import * as ReservationsReducers from '../pages/reservations/tabs/reservations-list/store/reservations-list.reducer';
import * as LessonsReducers from '../pages/learning-management-system/store/lesson-list/lessons-list.reducer';
import * as LmsReducers from '../pages/learning-management-system/store/lms.reducer';
import * as WorkFlowReducers from './workflow/workflow.reducer';

export {
  BulkReducers,
  MediaLibraryModalReducers,
  AppContestsReducers,
  AppGroupsReducers,
  AppUsersReducers,
  BookableResourcesReducers,
  ResourcesReducers,
  CockpitReducers,
  CommunicationsReducers,
  CommunitiesReducers,
  FeedbackReducers,
  AuthReducers,
  MediaLibraryReducers,
  OrdersReducers,
  ProductsReducers,
  RolesReducers,
  SurveyReducers,
  TablesReducers,
  UsersReducers,
  CommunitySelectReducers,
  IntegrationSelectReducers,
  ContentsReducers,
  AvailabilityRulesReducers,
  BookableProductsReducers,
  VersionSelectionModalReducers,
  ReservationsReducers,
  WorkFlowReducers,
  LessonsReducers,
  LmsReducers
};
