import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { Action, createReducer, on } from '@ngrx/store';
import { VersionSelectionModalActions } from 'src/app/store/app.actions';
import { Product } from '../../../../models';
import { DataleanPaginatedEntity } from '../../../../models/DataLeanEntity';

export const TAG = 'versionSelectionModal';

export interface VersionSelectionState {
  sort: Sort;
  page: PageEvent;
  data: DataleanPaginatedEntity<Product>;
  isLoading: boolean;
}

export const initialState: VersionSelectionState = {
  sort: { active: 'entityVersion', direction: 'asc' },
  page: { pageIndex: 0, pageSize: 10, length: undefined },
  data: undefined,
  isLoading: false,
};

const _counterReducer = createReducer(
  initialState,
  on(VersionSelectionModalActions.setSort, (state: VersionSelectionState, { sort }) => ({
    ...state,
    sort,
  })),
  on(VersionSelectionModalActions.setLoading, (state: VersionSelectionState, { isLoading }) => ({
    ...state,
    isLoading,
  })),
  on(VersionSelectionModalActions.setPage, (state: VersionSelectionState, { page }) => ({
    ...state,
    page,
  })),
  on(VersionSelectionModalActions.updatePageIndex, (state: VersionSelectionState, { index }) => ({
    ...state,
    page: {
      ...state.page,
      pageIndex: index,
    },
  })),
  on(VersionSelectionModalActions.setData, (state: VersionSelectionState, { data }) => ({
    ...state,
    data,
  })),
  on(VersionSelectionModalActions.resetState, (state: VersionSelectionState) => ({
    ...initialState,
  }))
);

export function versionSelectionReducer(state: VersionSelectionState, action: Action) {
  return _counterReducer(state, action);
}
