import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { createAction, props } from '@ngrx/store';
import { SearchInfo } from 'src/app/models/search-info';
import { FiltersType } from './bookable-products.reducer';
import {Structure} from "../../../../../models";

export const setFilters = createAction('[BookableProducts] Set Filters', props<{ filters: FiltersType }>());

export const setSort = createAction('[BookableProducts] Set Sort', props<{ sort: Sort }>());

export const setPage = createAction('[BookableProducts] Set Page', props<{ page: PageEvent }>());

export const setExtFilt = createAction('[BookableProducts] Set External Filters', props<{ extFilt: any }>());

export const updateExtFilt = createAction('[BookableProducts] Update External Filters', props<{ extFilt: any }>());

export const setSearchInfo = createAction('[BookableProducts] Set Search Info', props<{ searchInfo: SearchInfo }>());

export const setData = createAction('[BookableProducts] Set BookableProducts Data', props<{ data: object }>());

export const setStructures = createAction('[BookableProducts] Set BookableProducts Structures', props<{ structures: Array<Structure> }>());
