import { ObjectValuesType } from '../helpers/utilities';

export const PermissionCategory = {
  MAIN_NAVIGATION: 'MAIN_NAVIGATION',
  COMMUNITIES: 'COMMUNITIES',
  CONFIGURATIONS: 'CONFIGURATIONS',
  MEDIA_LIBRARY: 'MEDIA_LIBRARY',
  FEATURES: 'FEATURES',
  USERS: 'USERS',
  STRUCTURES: 'STRUCTURES',
  ROLES: 'ROLES',
  APP_USERS: 'APP_USERS',
  PRODUCTS: 'PRODUCTS',
  COMMUNICATIONS: 'COMMUNICATIONS',
  GROUPS: 'GROUPS',
  SURVEYS: 'SURVEYS',
  ORDERS: 'ORDERS',
  INTEGRATIONS: 'INTEGRATIONS',
  TO_REMOVE: 'TO_REMOVE',
  GIFT_CARD: 'GIFT_CARD',
  OTHERS: 'OTHERS',
  CONTENTS: 'CONTENTS',
} as const;

export type PermissionCategory = ObjectValuesType<typeof PermissionCategory>;
