import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppUsersReducers } from 'src/app/store/app.reducers';

const getAppUsersState = createFeatureSelector<AppUsersReducers.AppUsersState>(AppUsersReducers.APP_USERS_TAG);

export const getAppUsersSort = createSelector(getAppUsersState, (state: AppUsersReducers.AppUsersState) => state?.sort);
export const getAppUsersPage = createSelector(getAppUsersState, (state: AppUsersReducers.AppUsersState) => state?.page);
export const getAppUsersSearchInfo = createSelector(getAppUsersState, (state: AppUsersReducers.AppUsersState) => state?.searchInfo);
export const getAppUsersData = createSelector(getAppUsersState, (state: AppUsersReducers.AppUsersState) => state?.data);
export const getAppUsersFilters = createSelector(getAppUsersState, (state: AppUsersReducers.AppUsersState) => state?.filters);
export const getAppUsersUUIDList = createSelector(getAppUsersState, (state: AppUsersReducers.AppUsersState) => state?.bulkData);
export const getAppUsersBulkAction = createSelector(getAppUsersState, (state: AppUsersReducers.AppUsersState) => state?.bulkAction);
