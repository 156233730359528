import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LocalizationService {
  changedEntityLanguage: BehaviorSubject<string>;
  changedAppLanguage: BehaviorSubject<string>;
  isDefaultActiveEntityLanguage: BehaviorSubject<boolean>;

  private entityLanguage: string;

  constructor(private translateService: TranslateService) {
    this.changedAppLanguage = new BehaviorSubject(this.getActiveAppLanguage());
    this.changedEntityLanguage = new BehaviorSubject(this.getActiveEntityLanguage());
    this.isDefaultActiveEntityLanguage = new BehaviorSubject(this.checkIsDefaultActiveEntityLanguage());
  }

  checkIsDefaultActiveEntityLanguage() {
    return this.getActiveEntityLanguage() === this.translateService.getDefaultLang();
  }

  setDefaultLang(language: string) {
    this.translateService.setDefaultLang(language);
    //nel caso non l'avesse preso, è più aggressivo
    if (this.translateService.getDefaultLang() !== language) this.translateService.defaultLang = language;
    this.entityLanguage = language;
  }

  setActiveAppLanguage(language?: string) {
    this.translateService.use(language);
    this.changedAppLanguage.next(language);
  }

  setActiveEntityLanguage(language: string) {
    this.entityLanguage = language;
    this.isDefaultActiveEntityLanguage.next(this.checkIsDefaultActiveEntityLanguage());
    this.changedEntityLanguage.next(language);
  }

  setAvailableLanguages(languages: string[]) {
    this.translateService.addLangs(languages);
  }

  getDefaultLang() {
    return this.translateService.getDefaultLang();
  }

  getActiveAppLanguage() {
    return this.translateService.currentLang || this.translateService.getDefaultLang();
  }

  getActiveEntityLanguage() {
    return this.entityLanguage || this.translateService.getDefaultLang();
  }

  getAvailableLanguages() {
    return this.translateService.getLangs();
  }

  observeisDefaultActiveEntityLanguage() {
    return this.isDefaultActiveEntityLanguage.asObservable();
  }
}
