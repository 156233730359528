import { createFeatureSelector, createSelector } from "@ngrx/store";
import { AuthReducers } from "src/app/store/app.reducers";
import { AuthState } from "./auth.reducer";

const AuthSelector = createFeatureSelector(AuthReducers.TAG)

export const getAuthenticatedUserData = createSelector(
    AuthSelector,
    (state: AuthState) => state.userData
)
export const getAuthError = createSelector(
    AuthSelector,
    (state: AuthState) => state.authError
)
export const getAuthPermissions = createSelector(
    AuthSelector,
    (state: AuthState) => state.userPerm
)
