import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { EventInput } from '@fullcalendar/core';
import { createAction, props } from '@ngrx/store';
import { SearchInfo } from 'src/app/models/search-info';
import { ReservationDateState } from './reservations-list.reducer';
import { FiltersType, FiltersTypeValues } from 'src/app/enums';

export const setReservationFilters = createAction('[Reservations] Set Filters', props<{ filters: FiltersType }>());
//set dei soli valori dei filtri (la struttura rimane invariata)
export const setReservationFiltersValues = createAction('[Reservations] Set FiltersValues', props<{ filters: FiltersTypeValues }>());

export const setShowFiltersReservation = createAction('[Reservations] Set show filters', props<{ show: boolean }>());
export const togglehowFiltersReservation = createAction('[Reservations] toggle show filters');

export const setSort = createAction('[Reservations] Set Sort', props<{ sort: Sort }>());

export const setPage = createAction('[Reservations] Set Page', props<{ page: PageEvent }>());

export const setExtFilt = createAction('[Reservations] Set External Filters', props<{ extFilt: any }>());

export const updateExtFilt = createAction('[Reservations] Update External Filters', props<{ extFilt: any }>());

export const setSearchInfo = createAction('[Reservations] Set Search Info', props<{ searchInfo: SearchInfo }>());

export const setData = createAction('[Reservations] Set Reservations Data', props<{ data: ReservationDateState }>());

export const setReservationEvents = createAction('[Reservations] Set Reservations Events', props<{ events: EventInput[] }>());
