export const Materials = [
  {
    label: 'materialCastIron',
    value: {
      color: 0x343434,
      metalness: 0.5,
      roughness: 0.7,
      reflectivity: 0.7,
    },
  },
  {
    label: 'materialMetalBrushed',
    value: {
      color: 0xffffff,
      metalness: 1,
      roughness: 0.3,
    },
  },
  {
    label: 'materialDrawing',
    value: {
      color: 0xffffff,
      polygonOffset: true,
      polygonOffsetFactor: 1, // positive value pushes polygon further away
      polygonOffsetUnits: 1,
    },
  },
];
