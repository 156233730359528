import { ObjectValuesType } from '../helpers/utilities';

export const DaysArray: Days[] = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

export const Days = {
  SUNDAY: 'sunday',
  MONDAY: 'monday',
  TUESDAY: 'tuesday',
  WEDNESDAY: 'wednesday',
  THURSDAY: 'thursday',
  FRIDAY: 'friday',
  SATURDAY: 'saturday'
} as const;

export type Days = ObjectValuesType<typeof Days>;

export const TimeFormats = {
  HOUR_MINUTE: 'HH:mm',
  DATE: 'dd/MM/yyyy'
} as const;

export type TimeFormats = ObjectValuesType<typeof TimeFormats>;

export const AvailabilityRuleType = {
  SPECIFIC: 'specific',
  GENERIC: 'generic'
} as const;

export type AvailabilityRuleType = ObjectValuesType<typeof AvailabilityRuleType>;
