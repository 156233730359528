import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { SearchInfo } from 'src/app/models/search-info';
import { Action, createReducer, on } from '@ngrx/store';
import { AuthActions, SurveyActions } from 'src/app/store/app.actions';
import { StructureType } from 'src/app/enums/structure-type.enum';

export const SURVEY_TAG = 'survey';
export interface FiltersType {
  structureType: StructureType;
  structureUUID: string;
  featureValueUUID: string;
  communityUUID: string;
  communityAdminUUID?: string;
  template: string;
  active: boolean;
}

export interface SurveyState {
  filters: FiltersType;
  sort: Sort;
  page: PageEvent;
  extFilt: any;
  searchInfo: SearchInfo;
  data: any;
}

const surveyInitialState: SurveyState = {
  filters: undefined,
  sort: { active: 'updateDate', direction: 'asc' },
  page: { pageIndex: 0, pageSize: 25, length: undefined },
  extFilt: undefined,
  searchInfo: {
    searchFields: 'name',
    searchFieldsParamName: 'searchFields',
    searchValueParamName: 'q',
  } as SearchInfo,
  data: undefined,
};

const _commReducer = createReducer(
  surveyInitialState,
  on(SurveyActions.setSurveyFilters, (state: SurveyState, { filters }) => ({
    ...state,
    filters: {
      ...filters,
      communityUUID: filters.communityEntityUUID,
    },
  })),
  on(SurveyActions.setSurveySort, (state: SurveyState, { sort }) => ({
    ...state,
    sort,
  })),
  on(SurveyActions.setSurveyPage, (state: SurveyState, { page }) => ({
    ...state,
    page,
  })),
  on(SurveyActions.updateExtFilt, (state: SurveyState, { extFilt }) => ({
    ...state,
    extFilt,
  })),
  on(SurveyActions.setSurveySearchInfo, (state: SurveyState, { searchInfo }) => ({
    ...state,
    searchInfo,
  })),
  on(SurveyActions.setSurveyData, (state: SurveyState, { data }) => ({
    ...state,
    data,
  })),
  on(AuthActions.logout, () => ({ ...surveyInitialState }))
);

export const SurveyReducer = (state: SurveyState, action: Action) => {
  return _commReducer(state, action);
};
