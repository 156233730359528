import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { createAction, props } from '@ngrx/store';
import { FiltersType, FiltersTypeValues } from '../../../enums';
import { SearchInfo, Structure } from '../../../models';

export const setFilters = createAction('[Cockpit] set Filters', props<{ filters: FiltersType }>());
export const setFiltersValues = createAction('[Cockpit] set Filters values', props<{ filters: FiltersTypeValues }>());
export const updateFilters = createAction('[Cockpit] update Filters', props<{ extFilt: FiltersType }>());
export const setSort = createAction('[Cockpit] set Sort', props<{ sort: Sort }>());
export const setPage = createAction('[Cockpit] set Page', props<{ page: PageEvent }>());
export const setSearchInfo = createAction('[Cockpit] set Search Info', props<{ searchInfo: SearchInfo }>());
export const setData = createAction('[Cockpit] set Data', props<{ data: any; loadingComplete?: boolean }>());
export const setStructures = createAction('[Cockpit] set Structures', props<{ structures: Array<Structure> }>());
export const setLoading = createAction('[Cockpit] Set Loading', props<{ loading: boolean }>());
