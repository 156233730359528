<div class="tree-container">
  <div class="tree-header">
    <search-field *ngIf="hasSearch" (search)="onSearch($event)"></search-field>
    <app-icon name="a" (click)="featureAdd()" *ngIf="canCreate && !createOptions"></app-icon>
    <button mat-mini-fab class="header-button table-add-button" [matMenuTriggerFor]="matMenu" *ngIf="createOptions">
      <app-icon name="a"></app-icon>
    </button>
    <mat-menu #matMenu="matMenu" yPosition="below" xPosition="before" class="option-menu">
      <div class="arrow-container">
        <div class="rectangle" (click)="onAddEntityClick(createOptions[0])"></div>
        <div class="arrow"></div>
      </div>
      <div class="option-container">
        <ng-container *ngFor="let option of createOptions">
          <div class="structure-name option" (click)="onAddEntityClick(option)">
            <span>{{ option | translate }}</span>
          </div>
        </ng-container>
      </div>
    </mat-menu>
  </div>
  <mat-tree
    *ngIf="dataSource.data?.length > 0"
    [dataSource]="dataSource"
    #dropList
    [treeControl]="treeControl"
    [cdkDropListDisabled]="!canDrag"
    cdkDropList
    (cdkDropListDropped)="drop($event)">
    <mat-tree-node
      *matTreeNodeDef="let node"
      matTreeNodePadding
      matTreeNodePaddingIndent="20"
      cdkDrag
      [cdkDragData]="node"
      (mouseenter)="dragHover(node)"
      (mouseleave)="dragHoverEnd()"
      (cdkDragStarted)="dragStart()"
      (cdkDragReleased)="dragEnd()">
      <mat-checkbox
        (change)="selectElement($event, node)"
        *ngIf="manageBulk && node.nodeType == NODE_TYPE.CHILD_TYPE && node.selectable && !onlyFatherSelection"
        [checked]="isSelected(node)">
      </mat-checkbox>
      <span
        class="node-name"
        (click)="toggleExpansion(node, true)"
        [class.active]="node.id == activeNode?.id"
        [ngClass]="{ error: isInError(node), 'contains-error': containsError(node) }"
        >{{ node.name }}
        <span class="alert" *ngIf="needSave(node)">*</span>
      </span>
      <ng-container *ngIf="canEdit">
        <app-icon name="a" class="add" (click)="addChildren(node)" *ngIf="canCreateChild"></app-icon>
        <app-icon name="l" class="remove" (click)="deleteNode(node)" *ngIf="checkIfCanEditNode(node)"></app-icon>
        <grid-options
          *ngIf="options?.length"
          [options]="options"
          [entityUUID]="node.id"
          [activeObj]="node"
          (event)="manageOptionEvent($event, node)">
        </grid-options>
      </ng-container>
      <ng-container *ngIf="!canEdit && canSelectMultiple(node)">
        <app-icon name="a" class="add" (click)="select(node)"></app-icon>
      </ng-container>
    </mat-tree-node>

    <mat-tree-node
      *matTreeNodeDef="let node; when: hasChild"
      matTreeNodePadding
      matTreeNodePaddingIndent="20"
      cdkDrag
      [cdkDragData]="node"
      (mouseenter)="dragHover(node)"
      (mouseleave)="dragHoverEnd()"
      (cdkDragStarted)="dragStart()"
      (cdkDragReleased)="dragEnd()">
      <app-icon
        matTreeNodeToggle
        name="o"
        [ngClass]="{ opened: treeControl.isExpanded(node) }"
        class="arrow"
        (click)="toggleExpansion(node, false)"></app-icon>
      <mat-checkbox
        [checked]="isAllSelected(node)"
        (change)="selectAllElement($event, node)"
        *ngIf="manageBulk && (hasSelectableElements(node) || onlyFatherSelection)">
      </mat-checkbox>
      <span
        class="node-name"
        [class.active]="node.id == activeNode?.id"
        (click)="toggleExpansion(node, true)"
        [ngClass]="{ error: isInError(node), 'contains-error': containsError(node) }"
        >{{ node.name }}
        <span class="alert" *ngIf="needSave(node)">*</span>
      </span>

      <ng-container *ngIf="canEdit">
        <app-icon name="a" class="add" (click)="addChildren(node)" *ngIf="this.canCreateChild"></app-icon>
        <app-icon name="l" class="remove" (click)="deleteNode(node)" *ngIf="checkIfCanEditNode(node)"></app-icon>
        <grid-options
          *ngIf="options?.length"
          [options]="options"
          [entityUUID]="node.id"
          [activeObj]="node"
          (event)="manageOptionEvent($event, node)">
        </grid-options>
      </ng-container>
    </mat-tree-node>
  </mat-tree>
</div>
