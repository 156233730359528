import { SurveyQuestionType } from '../enums/survey-question-type.enum';

export interface SurveyQuestion {
  domainOfAnswers: any[];
  order: number;
  required: boolean;
  type: SurveyQuestionType;
  text: any; // localized string
  surveyCode: number;
  negativeLabel?: any; //  localized string
  postiveLabel?: any; // localized string
  textType?: TextType;
  dateTimeType?: DateTimeType;
  uuid?: string;
}

export enum TextType {
  TEL = 'tel',
  EMAIL = 'email',
  TEXT = 'text',
  PASSWORD = 'password',
  URL = 'url',
  NUMBER = 'number',
  SEARCH = 'search'
}

export enum DateTimeType {
  YEAR = 'YEAR',
  MONTH_YEAR = 'MONTH_YEAR',
  MONTH_DAY_YEAR = 'MONTH_DAY_YEAR', // normal date
  AMPM = 'AMPM',
  MILITAR = 'MILITAR', // normal hour
  DATE_AM_PM = 'DATE_AM_PM',
  DATE_MILITAR = 'DATE_MILITAR'  // normal date with hour
}
