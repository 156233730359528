import { PaginationInfo, SearchInfo, SortInfo } from "../models";

/**
 *
 * @param dataset array di dati nel quale cercare la corrispondenza
 * @param findField parametro di uguaglianza, il valore da trovare
 * @param searchBy chiave nella quale cercare il valore nell'oggetto del dataset
 * @param deepField chiave che permette di andare in modo ricorsivo all'interno del dataset
 * @returns la prima ricorrenza del valore o undefined
 */
export const DeepFind = <T>(dataset: Array<T>, findField: string, searchBy: string, deepField: string): T | undefined => {
  const element = dataset.find((data) => data[searchBy] === findField);
  if (element) {
    return element;
  }
  return dataset
    .map((data) => (data[deepField]?.length ? DeepFind<T>(data[deepField], findField, searchBy, deepField) : undefined))
    .find((data) => !!data);
};

export const DeepParse = (
  dataset: Array<Record<string, any>>,
  deepField: string,
  flatObject: Record<string, any>,
  assignField: string
): Record<string, any> => {
  const result = dataset.reduce((acc, f) => {
    acc[f[assignField]] = flatObject[f[assignField]];
    if (f[deepField]) {
      acc[f[assignField]] = DeepParse(f[deepField], deepField, flatObject, assignField);
    }
    return acc;
  }, {});
  return result;
};

export const DeepFlat = <T>(dataset: Array<T>, deepField: string): Array<T> => {
  const result = dataset.reduce((acc, f) => {
    acc.push(f);
    if (f[deepField]) {
      acc = acc.concat(DeepFlat(f[deepField], deepField));
    }
    return acc;
  }, []);
  return result;
};

export type ObjectValuesType<T> = T[keyof T];

export const ParseEntity = <T>(entity: T, payload: object): T => {
  const keys = Object.keys(structuredClone(entity));
  for (const [key, value] of Object.entries(payload)) {
    if (keys.includes(key)) {
      entity[key] = structuredClone(value);
    }
  }
  return entity;
};

//funzione util per trasformare le chiamate da 'getDataForList' a 'getAllEntities'
//in particolare ti crea gli additional params per chiamare la getAllEntities paginata
export const createPaginationAdditionalParams = (searchInfo?: SearchInfo, pageInfo?: PaginationInfo, sortInfo?: SortInfo) => {
  const additionalParams = {}
  if(pageInfo) additionalParams['numberOfPage'] = pageInfo.numberOfElementsOnPage.toString()
  if(searchInfo?.query && searchInfo?.searchFields) {
    additionalParams[searchInfo.searchValueParamName] = encodeURIComponent(searchInfo.query)
    additionalParams[searchInfo.searchFieldsParamName] = encodeURIComponent(searchInfo.searchFields)
  }
  if(sortInfo && sortInfo.SortBy) additionalParams['sortBy'] = encodeURIComponent(sortInfo.getCompleteSortInfo())
  return additionalParams
}
