export interface DashboardWidgetInfo {

  title;
  rows: DashboardWidgetRowInfo[];
  img;
  path;
  type;
  icon,
  requiredPermissions;

}

export interface DashboardWidgetRowInfo {
  label;
  value;
}
