import { ObjectValuesType } from '../helpers/utilities';

export const FiltersConstants = {
  ALL_COMMUNITIES_FILTER_VALUE: 'all',
  NOT_IN_COMMUNITIES_FILTER_VALUE_SUFFIX: 'not_in',
  NOT_IN_COMMUNITIES_FILTER_VALUE: `all%7Cnot_in`,
  ALL_COMMUNITIES_SELECT_VALUE: 'allCommunities',
  ALL_INTEGRATION_SELECT_VALUE: '',
  NO_COMMUNITY_SELECT_VALUE: '',
  DELIVERY_TIME_FIELD_NAME: 'deliveryTime',
  EASY_ORDER_TIME_FIELD_NAME: 'easyOrderTime',
  PICKUP_TIME_FIELD_NAME: 'pickUpTime',
  DATALEAN_ORDER_DELIVERY_TYPE_AT_RESTAURANT: 'ONSITE',
  DATALEAN_ORDER_DELIVERY_TYPE_TO_ADDRESS: 'HOME',
  DATALEAN_ORDER_DELIVERY_TYPE_EASY_ORDER: 'EASY_ORDER',
} as const;

export type FiltersConstants = ObjectValuesType<typeof FiltersConstants>;
