import { createFeatureSelector, createSelector } from '@ngrx/store';
import { MediaLibraryReducers } from 'src/app/store/app.reducers';

const getMediaLibraryState = createFeatureSelector<MediaLibraryReducers.MediaLibraryState>(MediaLibraryReducers.TAG);

export const getMediaLibraryFilters = createSelector(getMediaLibraryState, (state) => state?.filters);
export const getMediaLibrarySort = createSelector(getMediaLibraryState, (state) => state?.sort);
export const getMediaLibraryPage = createSelector(getMediaLibraryState, (state) => state?.page);
export const getMediaLibrarySearchInfo = createSelector(getMediaLibraryState, (state) => state?.searchInfo);
export const getMediaLibraryData = createSelector(getMediaLibraryState, (state) => state?.data);
export const getGridView = createSelector(getMediaLibraryState, (state) => state?.gridView);
export const getBreadcrumbs = createSelector(getMediaLibraryState, (state) => state?.breadcrumbs);
export const getLoading = createSelector(getMediaLibraryState, (state) => state?.loading);
export const getBulkAction = createSelector(getMediaLibraryState, (state) => state?.bulkAction);
export const getBulkUUIDList = createSelector(getMediaLibraryState, (state) => state?.bulkData);

