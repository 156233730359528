export class Asset {
  constructor(
    public name: string = '',
    public path = '/',
    public url?: string,
    public parentUUID?: string,
    public assetType?: string,
    public format?: string,
    public bytes?: number,
    public communityUUID?: string,
    public order?: number,
    public children?: Asset[],
    public tags?: [],
    public originalAssetUUID?: string,
    public previewUUID?: string,
    public isPreview?: boolean,
    public thumbnailsRefs?: [],
    public uuid?: string, // FROM DataleanEntity
    public updateDate?: string | Date, // FROM DataleanEntity
  ) {}
}
