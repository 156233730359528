import { ObjectValuesType } from '../helpers/utilities';

export const StructureFieldTypes = {
  CONTAINER: 'container',
  ATTACHMENT: 'attachment',
  ATTACHMENT_3: 'attachment_3.0',
  TEXT: 'text',
  TEXT_ADVANCED: 'textAdvanced',
  TEXTAREA: 'textarea',
  TEXTAREA_ADVANCED: 'textareaAdvanced',
  FILE: 'file',
  BOOLEAN: 'boolean',
  HTML: 'html',
  PHONE: 'phone',
  EMAIL: 'email',
  SELECT: 'select',
  PRIVACY: 'privacy',
  QRCODE: 'qrcode',
  HOTSPOTTER: 'hotspotter',
  IMAGE_MAP_CONTAINER: 'imageMapContainer',
  FEATURE_VALUES_CONTAINER: 'featureValuesContainer',
  FEATURE_VALUES_WITH_PARENT: 'featureValuesWithParent',
  RELATED_ENTITIES: 'relatedEntities',
  DATE: 'date',
  TIME: 'time',
  CALCULATED: 'calculated',
  NUMBER: 'number',
  NUMBER_ADVANCED: 'numberAdvanced',
  RELATED_POSITIONING: 'relatedPositioning',
  MATERIAL: 'material',
  URLTITLE: 'urlTitle',
  SUBSTRUCTURE: 'substructure',
  CHIPS: 'chips',
} as const;

export const ContainerType = {
  CONTAINER: 'container',
  ATTACHMENT: 'attachment',
  IMAGE_MAP_CONTAINER: 'imageMapContainer',
} as const;

export type StructureFieldTypes = ObjectValuesType<typeof StructureFieldTypes>;
export type ContainerType = ObjectValuesType<typeof ContainerType>;
