import { ObjectValuesType } from '../helpers/utilities';

export const AssetType = {
  FOLDER: 'folder',
  IMAGE: 'image',
  VIDEO: 'video',
  PDF: 'pdf',
  RAW: 'raw',
  DOCUMENT: 'document',
  NOT_DEFINED: 'not_defined',
} as const;

export type AssetType = ObjectValuesType<typeof AssetType>;
