import { createFeatureSelector, createSelector } from '@ngrx/store';
import { BookableProductsReducers } from 'src/app/store/app.reducers';

const getBookableProductsState = createFeatureSelector<BookableProductsReducers.BookableProductsState>(BookableProductsReducers.TAG);

export const getBookableProductsFilters = createSelector(getBookableProductsState, (state) => state?.filters);

export const getBookableProductsSort = createSelector(getBookableProductsState, (state) => state?.sort);

export const getBookableProductsPage = createSelector(getBookableProductsState, (state) => state?.page);

export const getBookableProductsExtFilt = createSelector(getBookableProductsState, (state) => state?.extFilt);

export const getBookableProductsSearchInfo = createSelector(getBookableProductsState, (state) => state?.searchInfo);

export const getBookableProductsData = createSelector(getBookableProductsState, (state) => state?.data);

export const getCustomizationData = createSelector(getBookableProductsState, (state) => state?.customizationData);

export const getBookableProductsStructures = createSelector(getBookableProductsState, (state) => state?.structures);

export const getGridView = createSelector(getBookableProductsState, (state) => state?.gridView);
