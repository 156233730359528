import { Injectable } from '@angular/core';
import { map, switchMap } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { MediaLibraryActions } from 'src/app/store/app.actions';

@Injectable()
export class MediaLibraryEffects {
  constructor(private actions$: Actions) {}

  // -- RESET DELLA PAGINAZIONE SE CAMBIANO FILTRI\RICERCHE 
  resetPageOnFilterChange$ = createEffect(() => 
    this.actions$.pipe(
      ofType(MediaLibraryActions.setFilters, MediaLibraryActions.setSearchInfo),
      map(() => MediaLibraryActions.resetPaging())
    )
  )

  resetPaginationAndSearchInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MediaLibraryActions.setFilters, MediaLibraryActions.updateParentFilter, MediaLibraryActions.setSearchInfo),
      switchMap((action: any) => {
        const returnArray: any[] = [MediaLibraryActions.updatePageIndex({ index: 0 })];
        if (!action.searchInfo) {
          returnArray.push(MediaLibraryActions.updateSearchInfo({ searchValue: '' }));
        }
        return returnArray;
      })
    )
  );

  setParenUUIDafterChangingFolder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MediaLibraryActions.addBreadcrumbItem, MediaLibraryActions.setBreadcrumbs),
      map((action: any) => {
        if (action.breadcrumbs) {
          return MediaLibraryActions.updateParentFilter({ parentUUID: action.breadcrumbs[action.breadcrumbs?.length - 1]?.parentUUID });
        }
        if (action.breadcrumbItem) {
          return MediaLibraryActions.updateParentFilter({ parentUUID: action.breadcrumbItem.parentUUID });
        }
      })
    )
  );
}
