export class PaginationInfo {
  constructor(public numberOfElementsOnPage?: number, public numberOfPage?: number, public totalNumberOfElements?: number) {}

  restart() {
    this.numberOfPage = 0;
    this.totalNumberOfElements = 0;
  }

  static empty() {
    return new PaginationInfo(0, 0, 0);
  }

  get nextPage(): number {
    return this.numberOfPage + 1;
  }
}
