import { Injectable } from '@angular/core';
import { Survey } from '../interfaces/survey';

@Injectable({
  providedIn: 'root',
})
export class SurveyDetailService {
  survey: Survey;
  communication: any;
}
