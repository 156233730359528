import { MatTreeNestedDataSource } from '@angular/material/tree';
import { NestedTreeControl } from '@angular/cdk/tree';
import { FeatureValue } from 'src/app/models/FeatureValue';
import { LocalizableField } from './LocalizableField';
import { LocalizationService } from '../services/localization.service';

interface AvailableConfigurations {
  name: string;
  order: number;
  label?: string;
  uuid?: string;
  featureValues?: Array<FeatureValue>;
  periodicity?: string;
}

export class TreeDataSource extends MatTreeNestedDataSource<FeatureValue> {
  constructor(
    private treeControl: NestedTreeControl<FeatureValue>,
    intialData: FeatureValue[] | AvailableConfigurations[],
    localizationService?: LocalizationService
  ) {
    super();
    this.data = intialData.map((v) => {
      let label = v.label;

      if (typeof label == 'string') {
        label = LocalizableField.createWithLocalizedValue({
          language: localizationService.getActiveEntityLanguage(),
          value: label,
        });
      }

      return !v.periodicity
        ? {
            name: v.name,
            label,
            uuid: v.uuid,
            featureValues: v.featureValues ?? [],
            order: v.order,
          }
        : {
            ...v,
            label,
          };
    });
  }

  /** Add node as child of parent */
  public add(node: FeatureValue, parent: FeatureValue) {
    // add dummy root so we only have to deal with `FeatureValue`s
    const newTreeData = { name: 'Dummy Root', label: new LocalizableField(), featureValues: this.data };
    this._add(node, parent, newTreeData);
    this.data = newTreeData.featureValues;
  }

  /** Remove node from tree */
  public remove(node: FeatureValue) {
    const newTreeData = { name: 'Dummy Root', label: new LocalizableField(), featureValues: this.data };
    this._remove(node, newTreeData);
    this.data = newTreeData.featureValues;
  }

  /* Search and return node from tree */

  public search(name: string, child?) {
    if (child) {
      this.data = child;
    }
    return this.data.filter((obj) => {
      if (obj.name.toLocaleLowerCase().indexOf(name.toLocaleLowerCase()) > -1) {
        return true;
      } else if (obj.featureValues?.length) {
        //  figlio
        obj.featureValues = this.search(name, obj.featureValues);
        return obj.featureValues?.length > 0;
      } else {
        return false;
      }
    });
  }
  private deepReduce(featureValues: FeatureValue[]) {
    return featureValues.reduce((arr, fv) => {
      arr.push(fv);
      return arr.concat(this.deepReduce(fv.featureValues ?? []));
    }, []);
  }

  public findByUUID(uuid: string) {
    const flatData = this.deepReduce(this.data ?? []);
    return flatData.find((fv) => fv.uuid === uuid);
  }

  protected _add(newNode: FeatureValue, parent: FeatureValue, tree: FeatureValue) {
    if (tree === parent) {
      // console.log(
      //   `replacing featureValues array of '${parent.name}', adding ${newNode.name}`
      // );
      tree.featureValues = [...tree.featureValues, newNode];
      this.treeControl.expand(tree);
      return true;
    }
    if (!tree.featureValues) {
      // console.log(`reached leaf node '${tree.name}', backing out`);
      return false;
    }
    return this.update(tree, this._add.bind(this, newNode, parent));
  }

  _remove(node: FeatureValue, tree: FeatureValue): boolean {
    if (!tree.featureValues) {
      return false;
    }
    const i = tree.featureValues.findIndex((feature) => feature.uuid === node.uuid);
    if (i > -1) {
      tree.featureValues = [...tree.featureValues.slice(0, i), ...tree.featureValues.slice(i + 1)];
      this.treeControl.collapse(node);
      // console.log(`found ${node.name}, removing it from`, tree);
      return true;
    }
    return this.update(tree, this._remove.bind(this, node));
  }

  protected update(tree: FeatureValue, predicate: (n: FeatureValue) => boolean) {
    let updatedTree: FeatureValue;
    let updatedIndex: number;

    tree.featureValues.find((node, i) => {
      if (predicate(node)) {
        // console.log(`creating new node for '${node.name}'`);
        updatedTree = { ...node };
        updatedIndex = i;
        this.restoreExpansionState(node, updatedTree);
        return true;
      }
      return false;
    });

    if (updatedTree) {
      // console.log(`replacing node '${tree.featureValues![updatedIndex!].name}'`);
      tree.featureValues[updatedIndex] = updatedTree;
      return true;
    }
    return false;
  }

  restoreExpansionState(from: FeatureValue, to: FeatureValue) {
    if (this.treeControl.isExpanded(from)) {
      this.treeControl.collapse(from);
      this.treeControl.expand(to);
    }
  }
}
