import { Theme } from 'src/app/theme/theme';

export const defaultTheme: Theme = {
  name: 'Datalean',
  'checkbox-border-color': 'var(--lighter-blue)',
  'drag-drop-background': 'transparent',
  'checkbox-fill-color': 'var(--primary-color)',
  'checkbox-ripple-color': 'var(--primary-color-semi-transparent)',
  'primary-color-dark': 'rgba(0, 43, 122, 1.0)',
  'modal-background':'var(--white-color)',
  'primary-color': 'rgba(0, 72, 205, 1.0)',
  'tree-background':'var(--white-color)',
  'primary-color-light': 'rgba(51, 123, 255, 1.0)',
  'primary-color-semi-transparent': 'rgba(0, 72, 205, 0.25)',
  'secondary-color': 'var(--brand-color-07)',
  'background-color': 'white',
  'form-background': '#f0f0f0',
  'form-text': 'var(--medium-dark-grey)',
  'tree-form-field-container':'var(--form-background)',
  'hamburger-menu-background':'var(--primary-color)',
  'hamburger-menu-text':'white',
  'hamburger-menu-inactive-text': 'white',
  'datalean-logo-login': 'url(./assets/images/logo-datalean.png)',
  'datalean-logo-login-error': 'url(./assets/images/logo-datalean.png)',
  'login-background': 'var(--primary-color)',
  'login-field-line-color': 'var(--primary-color-light)',
  'login-link-color': 'var(--primary-color-light)',
  'login-link-color-hover': 'white',
  'datalean-logo-nav-bar': 'url(./assets/images/nav-menu/datalean-logo.png)',
  'navbar-bg-color-top': 'var(--primary-color-dark)',
  'navbar-bg-color-bottom': 'var(--primary-color)',
  'navbar-round-top': './assets/images/nav-menu/round-top.png',
  'navbar-round-bottom': './assets/images/nav-menu/round-bottom.png',
  'navbar-item-color': 'var(--primary-color-light)',
  'navbar-item-color-hover': 'white',
  'navbar-item-color-active': 'white',
  'select-label-color':'var(--black-color)',
  'option-menu-extrusion-color': 'var(--brand-color-02)',
  'option-menu-item-color': 'var(--primary-color-light)',
  'option-menu-item-color-erase': 'var(--red-color)',
  'table-title-color': 'var(--primary-color-dark)',
  'standard-button-color': 'var(--primary-color-light)',
  'standard-button-color-active': 'var(--primary-color)',
  'important-button-color': 'var(--secondary-color)',
  'search-icon-color': 'var(--primary-color)',
  'table-row-name-color': 'var(--primary-color)',
  'table-row-option-button-color': 'var(--primary-color)',
  'table-option-bg-color': 'rgba(228, 236, 243)',
  'table-option-title-color': 'var(--primary-color)',
  'button-confirm-color': 'var(--primary-color)',
  'button-deny-color': 'var(--red-color)',
  'modal-bg-color': 'var(--primary-color)',
  'role-panel-background':'var(--white-color)',
  'role-panel-text-color':'var(--black-color)',
  'role-panel-inner-background-color': 'var(--form-background)',
  'breadcrumb-current-folder-color': 'var(--primary-color)',
  'breadcrumb-separator-color': 'var(--primary-color-light)',
  'media-library-folder-img': 'url(./assets/images/media-library/folder.png)',
  'media-library-file-selected-bg-color': 'var(--primary-color-semi-transparent)',
  'badge-bg-color': 'var(--secondary-color)',
  'dashboard-widget-background': 'linear-gradient(92deg, rgba(242,242,242,1) 0%, rgba(255,255,255,1) 100%)',
  'dashboard-widget-divider-color': 'rgba(215,215,215,1.0)',
  'dashboard-widget-label-color': 'var(--secondary-color)',
  'dashboard-widget-value-color': 'var(--primary-color)',
  'dashboard-widget-title-color': 'var(--primary-color-dark)',
  'tooltip-background': 'var(--secondary-color)',
  'tree-form-field-background': 'var(--white-color)',
  'tree-form-field-text': 'var(--form-text)',
  'tooltip-text-color': 'white',
  'mat-option-background-color': 'var(--white-color)',
  'mat-option-text-color': 'var(--form-text)',
  'grid-option-text-color': 'var(--option-menu-item-color)',
  'radio-background-color': 'white',
  'chip-background-color': 'var(--white-color)',
  'chip-text-color': 'var(--black-color)',
  'radio-border-color': 'var(--primary-color)',
  'rotate-device-image': 'icn-rotatedevice.gif',
  'primary-toggle-color': 'var(--primary-color-dark)'
};

export const darkTheme: Theme = {
  name: 'DataleanDark',
  'checkbox-border-color': 'var(--lighter-blue)',
  'drag-drop-background': 'var(--form-background)',
  'checkbox-fill-color': 'var(--primary-color)',
  'tree-background':'var(--form-background)',
  'tree-form-field-container':'var(--black-color)',
  'tree-form-field-background': 'var(--form-background)',
  'tree-form-field-text': 'var(--white-color)',
  'modal-background':'var(--form-background)',
  'checkbox-ripple-color': 'var(--primary-color-semi-transparent)',
  'primary-color-dark': 'rgba(0, 43, 122, 1.0)',
  'primary-color': 'rgba(0, 72, 205, 1.0)',
  'primary-color-light': 'rgba(51, 123, 255, 1.0)',
  'primary-color-semi-transparent': 'rgba(0, 72, 205, 0.3)',
  'secondary-color': 'var(--brand-color-07)',
  'background-color': '#1a1a1a',
  'select-label-color':'var(--light-gray)',
  'form-background': 'rgb(32, 35, 37)',
  'form-text': 'var(--light-gray)',
  'datalean-logo-login': 'url(./assets/images/logo-datalean.png)',
  'datalean-logo-login-error': 'url(./assets/images/logo-datalean.png)',
  'login-background': 'var(--primary-color)',
  'login-field-line-color': 'var(--primary-color-light)',
  'login-link-color': 'var(--primary-color-light)',
  'hamburger-menu-inactive-text': 'var(--primary-color-light)',
  'login-link-color-hover': 'white',
  'hamburger-menu-background':'rgb(32, 35, 37)',
  'hamburger-menu-text':'white',
  'datalean-logo-nav-bar': 'url(./assets/images/nav-menu/datalean-logo.png)',
  'navbar-bg-color-top': 'var(--primary-color-dark)',
  'navbar-bg-color-bottom': 'var(--primary-color)',
  'navbar-round-top': './assets/images/nav-menu/round-top.png',
  'navbar-round-bottom': './assets/images/nav-menu/round-bottom.png',
  'navbar-item-color': 'var(--primary-color-light)',
  'navbar-item-color-hover': 'white',
  'navbar-item-color-active': 'white',
  'option-menu-extrusion-color': 'var(--brand-color-02)',
  'mat-option-text-color': 'var(--form-text)',
  'grid-option-text-color': 'var(--form-text)',
  'mat-option-background-color': 'var(--form-background)',
  'chip-background-color': 'var(--black-color)',
  'chip-text-color': 'var(--white-color)',
  'option-menu-item-color': 'var(--primary-color-light)',
  'option-menu-item-color-erase': 'var(--red-color)',
  'table-title-color': 'var(--primary-color-light)',
  'standard-button-color': 'var(--primary-color-light)',
  'standard-button-color-active': 'var(--primary-color)',
  'important-button-color': 'var(--secondary-color)',
  'search-icon-color': 'var(--primary-color)',
  'table-row-name-color': 'var(--primary-color)',
  'table-row-option-button-color': 'var(--primary-color)',
  'table-option-bg-color': 'rgba(228, 236, 243)',
  'table-option-title-color': 'var(--primary-color)',
  'button-confirm-color': 'var(--primary-color)',
  'button-deny-color': 'var(--red-color)',
  'modal-bg-color': '#1a1a1a',
  'breadcrumb-current-folder-color': 'var(--primary-color)',
  'breadcrumb-separator-color': 'var(--primary-color-light)',
  'media-library-folder-img': 'url(./assets/images/media-library/folder.png)',
  'media-library-file-selected-bg-color': 'var(--primary-color-semi-transparent)',
  'badge-bg-color': 'var(--secondary-color)',
  'dashboard-widget-background': 'linear-gradient(92deg, rgb(32, 35, 37) 55%, var(--navbar-bg-color-bottom) 100%)',
  'dashboard-widget-divider-color': 'rgba(215,215,215,1.0)',
  'dashboard-widget-label-color': 'var(--dashboard-widget-divider-color)',
  'dashboard-widget-value-color': 'var(--light-gray)',
  'dashboard-widget-title-color': 'var(--light-gray)',
  'tooltip-background': 'var(--primary-color)',
  'tooltip-text-color': 'white',
  'role-panel-text-color':'var(--light-gray)',
  'role-panel-background':'var(--form-background)',
  'role-panel-inner-background-color': 'var(--black-color)',
  'radio-background-color': '#1a1a1a',
  'radio-border-color': 'var(--primary-color)',
  'rotate-device-image': 'icn-rotatedevice.gif',
  'primary-toggle-color': 'var(--primary-color)'
};
