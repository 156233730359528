import { ObjectValuesType } from '../helpers/utilities';

export const SubjectType = {
  ADMIN: 'admin',
  APPLICATION_GROUP: 'applicationGroup',
  APPLICATION_SMART_GROUP: 'applicationSmartGroup',
  APPLICATION_USER: 'applicationUser',
  DATALEAN_USER: 'user',
} as const;

export type SubjectType = ObjectValuesType<typeof SubjectType>;
