export class Message {

  constructor(public title: string | MessageTitle, public message: string, public status?: string, public code?: string) {
  }

}

export enum MessageTitle{
  WARNING = "WARNING",
  ERROR = "ERROR",
  INFO = "INFO"
}
