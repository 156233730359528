import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { Action, createReducer, on } from '@ngrx/store';
import { SearchInfo } from 'src/app/models/search-info';
import { AuthActions, UsersActions } from 'src/app/store/app.actions';

export const TAG = 'users';

export interface FiltersType {
  isAdminCommunityUUID: string;
}

export interface UsersState {
  filters: FiltersType;
  sort: Sort;
  page: PageEvent;
  extFilt: any;
  searchInfo: SearchInfo;
  data: any;
  tab: any;
}

const initialState: UsersState = {
  filters: {
    isAdminCommunityUUID: '',
  },
  sort: { active: 'updateDate', direction: 'desc' },
  page: { pageIndex: 0, pageSize: 25, length: undefined },
  extFilt: undefined,
  searchInfo: {
    searchFields: 'firstName,lastName,email,username',
    searchFieldsParamName: 'searchFields',
    searchValueParamName: 'q',
  } as SearchInfo,
  data: undefined,
  tab: undefined,
};

const _counterReducer = createReducer(
  initialState,
  on(UsersActions.setFilters, (state: UsersState, { filters }) => ({
    ...state,
    filters: {
      ...filters,
      isAdminCommunityUUID: (filters as any).communityEntityUUID,
    },
  })),
  on(UsersActions.setSort, (state: UsersState, { sort }) => ({
    ...state,
    sort,
  })),
  on(UsersActions.setPage, (state: UsersState, { page }) => ({
    ...state,
    page,
  })),
  on(UsersActions.setExtFilt, (state: UsersState, { extFilt }) => ({
    ...state,
    extFilt,
  })),
  on(UsersActions.updateExtFilt, (state: UsersState, { extFilt }) => ({
    ...state,
    extFilt: { ...extFilt },
  })),
  on(UsersActions.setSearchInfo, (state: UsersState, { searchInfo }) => ({
    ...state,
    searchInfo,
  })),
  on(UsersActions.setData, (state: UsersState, { data }) => ({
    ...state,
    data,
  })),
  on(UsersActions.setTab, (state: UsersState, { tab }) => ({
    ...state,
    tab,
  })),
  on(AuthActions.logout, () => ({ ...initialState }))
);

export function usersReducer(state: UsersState, action: Action) {
  return _counterReducer(state, action);
}
