import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {AuthenticationGuard} from './guards/authentication.guard';
import { ReRouteGuard } from './guards/reroute-guard.service';

const routes: Routes = [
  { path: '', canActivate: [AuthenticationGuard], loadChildren: () => import('./pages/main/main.module').then(m => m.MainModule) },
  { path: 'restore-password', loadChildren: () => import('./pages/restore-password/restore-password.module').then(m => m.RestorePasswordModule) },
  { path: 'login', canActivate:[ReRouteGuard], loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule) },
  { path: 'activate-user', loadChildren: () => import('./pages/activate-user/activate-user.module').then(m => m.ActivateUserModule) },
  /*{ path: 'unauthorized', loadChildren: () => import('./pages/unauthorized/unauthorized.module').then(m => m.UnauthorizedModule) },*/
  { path: '**', redirectTo: ''}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: false })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
