import { GlobalPositionStrategy } from '@angular/cdk/overlay';
import { Injectable } from '@angular/core';
import { DataTableSMComponent } from '../components/data-table-sm/data-table-sm.component';
import { MessageComponent } from '../components/modal/message/message.component';
import { ModalService } from '../components/modal/modal.service';
import { MessageTitle } from '../models/Message';

@Injectable({ providedIn: 'root' })
export class ErrorHandlerService {
  constructor(private modalService: ModalService) {}

  checkLoading(table?: DataTableSMComponent, stop = false): void {
    if (table) {
      table.isLoadingResults = !stop;
      table.changeDetectionRef.detectChanges();
    }
  }

  openErrorModal(error: any, showBackendError = false) {
    const err = error.error ?? error;
    if (error.status === 404) {
      error.message = 'Could not fullfill request';
    }
    this.modalService.open({
      component: MessageComponent,
      class: 'message-modal',
      position: new GlobalPositionStrategy().centerHorizontally().top(),
      data: {
        message: error.message,
        status: error.status,
        code: this.getErrorCode(err, showBackendError),
        title: MessageTitle.ERROR
      }
    });
  }

  private getErrorCode(error: any, showBackendError: boolean) {
    if (showBackendError) {
      if (!error) return '';

      if (error.Errors) {
        return (
          '<ul>' +
          error.Errors.map(
            (singleError) =>
              '<li>' + singleError.message + (singleError.detail ? '(*<div>' + singleError.detail + '</div>)' : '') + '</li>'
          )?.join('') +
          '</ul>'
        );
      }
    }

    return error?.exception ?? error?.[0]?.message;
  }
}
