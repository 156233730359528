import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { Action, createReducer, on } from '@ngrx/store';
import { StructureType } from 'src/app/enums/structure-type.enum';
import { SearchInfo } from 'src/app/models/search-info';
import { Structure } from 'src/app/models/Structure';
import { AuthActions, ResourcesActions } from 'src/app/store/app.actions';

export const TAG = 'resources';

export interface FiltersType {
  structureType: StructureType;
  structureUUID?: string;
  featureValueUUID: string;
  communityEntityUUID: string;
  communityAdminUUID?: string;
  template: string;
  active: boolean;
}

export interface ResourcesState {
  filters: FiltersType;
  sort: Sort;
  page: PageEvent;
  extFilt: any;
  searchInfo: SearchInfo;
  data: any;
  customizationData: any;
  structures: Array<Structure>;
  gridView: boolean;
}

const initialState: ResourcesState = {
  filters: {
    structureType: StructureType.RESOURCE,
    structureUUID: undefined,
    featureValueUUID: undefined,
    communityEntityUUID: undefined,
    communityAdminUUID: undefined,
    template: undefined,
    active: true,
  },
  sort: { active: 'updateDate', direction: 'desc' },
  page: { pageIndex: 0, pageSize: 25, length: undefined },
  extFilt: undefined,
  searchInfo: {
    searchFields: 'name',
    searchFieldsParamName: 'searchFields',
    searchValueParamName: 'q',
  } as SearchInfo,
  data: undefined,
  customizationData: undefined,
  structures: [],
  gridView: false,
};

const _reducer = createReducer(
  initialState,
  on(ResourcesActions.setFilters, (state: ResourcesState, { filters }) => ({
    ...state,
    filters,
  })),
  on(ResourcesActions.setSort, (state: ResourcesState, { sort }) => ({
    ...state,
    sort,
  })),
  on(ResourcesActions.setPage, (state: ResourcesState, { page }) => ({
    ...state,
    page,
  })),
  on(ResourcesActions.setExtFilt, (state: ResourcesState, { extFilt }) => ({
    ...state,
    extFilt,
  })),
  on(ResourcesActions.updateExtFilt, (state: ResourcesState, { extFilt }) => ({
    ...state,
    extFilt: { ...extFilt },
  })),
  on(ResourcesActions.setSearchInfo, (state: ResourcesState, { searchInfo }) => ({
    ...state,
    searchInfo,
  })),
  on(ResourcesActions.setData, (state: ResourcesState, { data }) => ({
    ...state,
    data,
  })),
  on(ResourcesActions.setStructures, (state: ResourcesState, { structures }) => ({
    ...state,
    structures,
  })),
  on(AuthActions.logout, () => ({ ...initialState }))
);

export function resourcesReducer(state: ResourcesState, action: Action) {
  return _reducer(state, action);
}
