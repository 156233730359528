<div class="title">{{ this.title | translate }}</div>
<div class="select-container" *ngIf="showLanguage">
  <div class="language">
    <label>{{ 'LANGUAGE' | translate }}</label>
    <mat-select [value]="activeLocale" (selectionChange)="changedLanguage.emit($event)" [disableRipple]="true">
      <mat-option [value]="defaultLanguage">
        {{ (defaultLanguage | translate) + ' (default)' }}
      </mat-option>
      <ng-container *ngIf="languages.length">
        <mat-option [value]="language" *ngFor="let language of languages">
          {{ language | translate }}
        </mat-option>
      </ng-container>
    </mat-select>
  </div>
</div>
<div class="action-container">
  <app-icon class="confirm" name="d" (click)="onSave()" *ngIf="!disableSave"></app-icon>
  <app-icon class="cancel" name="g" (click)="onCancel()"></app-icon>
</div>
