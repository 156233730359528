import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { createAction, props } from '@ngrx/store';
import { SearchInfo } from 'src/app/models/search-info';
import { FiltersType, TabType } from './orders.reducer';

export const setFilters = createAction('[Orders] Set Filters', props<{ filters: FiltersType }>());
export const setSort = createAction('[Orders] Set Sort', props<{ sort: Sort }>());
export const setPage = createAction('[Orders] Set Page', props<{ page: PageEvent }>());
export const updateFilters = createAction('[Orders] Update Filters', props<{ filters: any }>());
export const setSearchInfo = createAction('[Orders] Set Search Info', props<{ searchInfo: SearchInfo }>());
export const setData = createAction('[Orders] Set Orders Data', props<{ data: object }>());
export const setOrdersUUIDListBulk = createAction('[Orders] toggle bulk', props<{ bulkData: any }>());
export const setTab = createAction('[Orders] set tab', props<{ tab: TabType }>());
