import { DataleanDataProviderService } from '../provider/datalean-data-provider.service';
import { environment } from 'src/environments/environment';
import { Parts } from '../enums/parts.enum';
import { Injectable, OnDestroy } from '@angular/core';
import { Observable, of, Subject, Subscription } from 'rxjs';
import { BaseEditorService } from './base-editor.service';
import { LocalizationService } from './localization.service';
import { Structure } from '../models/Structure';
import { map, switchMap } from 'rxjs/operators';
import { Communication } from '../models/Communication';
import { CommunitiesService } from './communities.service';
import DeepCopy from '../helpers/deep_copy';
import {ParseEntity} from "../helpers/utilities";

// not singleton because it is not meant to be reused
@Injectable()
export class CommunicationEditorService extends BaseEditorService implements OnDestroy {
  structure: Structure;
  objectDataMap: { [locale: string]: Communication } = {};
  usersRecipients: any[];
  groupsRecipients: any[];
  languageSubscription: Subscription;
  loadingLanguageData = false;
  loadingLanguageSubject = new Subject();

  constructor(
    protected dataleanDataService: DataleanDataProviderService,
    private communitiesService: CommunitiesService,
    protected localizationService: LocalizationService
  ) {
    super(dataleanDataService);

    this.activeLanguage = this.localizationService.getActiveEntityLanguage() ?? this.localizationService.getDefaultLang();
    this.languageSubscription = this.localizationService.changedEntityLanguage
      .pipe(
        switchMap((language) => {
          if (language) {
            this.loadingLanguageData = true;
            this.loadingLanguageSubject.next(this.loadingLanguageData);
            if (!this.objectDataMap[language]?.name && this.objectData?.uuid) {
              return this.dataleanDataService
                .getEntity(environment.communicationUrl, this.objectData.uuid, [Parts.ALL], { locale: language })
                .pipe(map((communication) => ({ communication: { ...communication, locale: language }, language })));
            }
            return of({ communication: DeepCopy(this.objectDataMap[language]), language });
          }
        })
      )
      .subscribe({
        next: ({ communication, language }) => {
          if (this.activeLanguage) {
            this.objectDataMap[this.activeLanguage] = this.objectData;
          }
          this.activeLanguage = language;
          if (communication?.name !== undefined) {
            this.objectDataMap[language] = DeepCopy(communication) as Communication;
          } else {
            if (this.objectData?.structureUUID) {
              this.objectDataMap[language] = this.createNewCommunication(this.objectData.structureUUID, language) as Communication;
            }
          }
          this.objectData = DeepCopy(this.objectDataMap[language]);

          this.syncNonLocalizableValue(this.objectDataMap[this.localizationService.getDefaultLang()], this.objectData),
            (this.loadingLanguageData = false);
          this.loadingLanguageSubject.next(this.loadingLanguageData);
        },
      });
  }

  private createNewCommunication(structureUUID, locale: string) {
    let communication: Partial<Communication>;
    if (locale !== this.localizationService.getDefaultLang()) {
      communication = DeepCopy(this.objectDataMap[this.localizationService.getDefaultLang()]) as Communication;
      communication.locale = locale;
      return communication;
    } else {
      communication = {
        name: '',
        structureUUID: structureUUID,
        locale: locale,
        active: true,
        featureValueList: [],
      };
    }
    if (this.communitiesService.getLastCommunitySelected()?.trim().length) {
      communication.communityUUID = this.communitiesService.getLastCommunitySelected();
    }
    return communication;
  }

  init(structure: Structure) {
    if (!this.objectData?.structureUUID) {
      this.objectData = this.createNewCommunication(structure.uuid, this.localizationService.getDefaultLang());
      this.objectDataMap[this.activeLanguage ?? this.localizationService.getActiveEntityLanguage()] = this.objectData;
    } else {
      const localizedValues = this.objectData.localizedValues ? structuredClone(this.objectData.localizedValues) : [];
      delete this.objectData.localizedValues;
      for (const lv of localizedValues) {
        this.objectDataMap[lv.locale] = { ...this.objectData, ...lv };
      }
      this.objectData = this.objectDataMap[this.localizationService.getActiveEntityLanguage()];
    }

    this.structure = structure;
  }

  create(): Observable<any> {
    const parsedFinalCommunication = this.getFinalCommunication();
    return this.dataleanDataService.createEntity(environment.communicationUrl, parsedFinalCommunication, [Parts.ALL]).pipe(
      switchMap((result) => {
        this.objectDataMap[this.localizationService.getDefaultLang()].uuid = result.uuid;
        return this.addRecipients(false);
      })
    );
  }

  update(): Observable<any> {
    const parsedFinalCommunication = this.getFinalCommunication();
    return this.dataleanDataService.updateEntity(environment.communicationUrl, parsedFinalCommunication, [Parts.EMPTY]).pipe(
      switchMap(() => {
        return this.addRecipients(true);
      })
    );
  }

  addRecipients(updateMode: boolean) {
    const defaultLanguage = this.localizationService.getDefaultLang();
    let entries = [];
    if (!this.objectData.isPublic) {
      entries = this.usersRecipients
        ?.map((user) => ({
          entityUUID: this.objectDataMap[defaultLanguage].uuid,
          entityType: 'communication',
          canView: true,
          subjectUUID: user.uuid,
          subjectType: 'applicationUser',
        }))
        .concat(
          this.groupsRecipients?.map((group) => ({
            entityUUID: this.objectDataMap[defaultLanguage].uuid,
            entityType: 'communication',
            canView: true,
            subjectUUID: group.uuid,
            subjectType: group.smartGroupCriteria ? 'applicationSmartGroup' : 'applicationGroup',
          }))
        );
    }
    const recipientUrl = environment.communicationUrl + this.objectDataMap[defaultLanguage].uuid + '/permission';

    if (entries?.length || updateMode === true) {
      return this.dataleanDataService.createEntity(recipientUrl, entries, [Parts.EMPTY]);
    }
    return of(true);
  }

  prepareToCloneCommunication() {
    Object.keys(this.objectDataMap).forEach((locale) => {
      delete this.objectDataMap[locale].uuid;
    });
    delete this.objectData.uuid;
  }

  setUsersRecipients(users) {
    this.usersRecipients = users.map((u) => ({ ...u, locale: this.activeLanguage }));
  }

  setGroupsRecipients(groups) {
    this.groupsRecipients = groups.map((u) => ({ ...u, locale: this.activeLanguage }));
  }

  ngOnDestroy(): void {
    this.languageSubscription?.unsubscribe();
  }

  private prepareEntityToSend(communication): Communication {
    const parsedCommunication = ParseEntity(new Communication(), communication);
    const rootNotLocalizableStructureFields = this.structure?.structureFields.filter((sf) => !sf.localizable);

    if (rootNotLocalizableStructureFields && rootNotLocalizableStructureFields.length > 0) {
      for (const structureField of rootNotLocalizableStructureFields) {
        if (communication[structureField.name] !== undefined) {
          parsedCommunication[structureField.name] = communication[structureField.name];
        }
      }
    }

    return parsedCommunication;
  }

  getFinalCommunication() {
    const finalCommunication = DeepCopy(this.objectDataMap[this.localizationService.getActiveEntityLanguage()]) as Communication;
    const localizableRootStructureFields = this.structure.structureFields.filter((sf) => sf.localizable).map((sf) => sf.name);

    // PROCESS LOCALIZED FIELDS
    if (localizableRootStructureFields.length) {
      finalCommunication.localizedValues = Object.keys(this.objectDataMap).reduce((acc, locale) => {
        let localizedValues = {
          name: this.objectDataMap[locale].name,
          locale,
        };
        if (this.activeLanguage === locale) {
          localizedValues = { name: this.objectData.name, locale };
        }

        localizedValues = localizableRootStructureFields.reduce((acc, localizableSfName) => {
          if (this.activeLanguage === locale) {
            if (this.objectData[localizableSfName]) {
              acc[localizableSfName] = this.objectData[localizableSfName];
            }
            return acc;
          }
          if (this.objectDataMap[locale][localizableSfName]) {
            acc[localizableSfName] = this.objectDataMap[locale][localizableSfName];
          }
          return acc;
        }, localizedValues);

        acc.push(localizedValues);
        return acc;
      }, []);
    }

    for (const localizableStructureFieldName of localizableRootStructureFields) {
      delete finalCommunication[localizableStructureFieldName];
    }

    // PROCESS FEATURE VALUES
    if (finalCommunication.featureValueList?.length) {
      finalCommunication.featureValueList = [...finalCommunication.featureValueList]
        .filter((featureValue) => ((typeof featureValue === 'object' && featureValue?.uuid?.length) || (typeof featureValue === 'string' && featureValue?.length)))
        .map((value) => (typeof value === 'object' ? value.uuid : value));
    }

    delete finalCommunication.name;
    delete finalCommunication.locale;

    // PARSE/CLEAN ENTITY
    const parsedFinalCommunication = this.prepareEntityToSend(finalCommunication);

    return parsedFinalCommunication;
  }

  private syncNonLocalizableValue(from: Communication, to): void {
    this.structure?.structureFields.forEach((field) => {
      if (!field.localizable) {
        to[field.name] = from[field.name];
      }
    });
  }
}
