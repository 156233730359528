export class Integration {
  static readonly UUID_FIELD_KEY = 'uuid';
  static readonly NAME_FIELD_KEY = 'name';
  static readonly TYPE_FIELD_KEY = 'type';

  constructor(
    public uuid: string,
    public name: string,
    public type: string,
    public lastConsolidationDate: string
  ) {}
}
