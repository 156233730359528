import {City} from "./City";

export class ProvinceBuilder {

  private cities : City[] = [];
  constructor(private name: string, private code: string) {
  }

  get Name() {
    return this.name;
  }

  get Code() {
    return this.code;
  }

  get Cities() {
    return this.cities;
  }

  withCities(cities: City[]) {
    this.cities = cities;
    return this;
  }

  build() {
    return new Province(this);
  }
}

export class Province {
  static readonly NAME_FIELD_KEY = 'name';
  static readonly CODE_FIELD_KEY = 'code';
  static readonly CITY_FIELD_KEY = 'cities';

  private readonly name: string;
  private readonly code: string;
  private readonly cities: City[] = [];

  constructor(provinceBuilder: ProvinceBuilder) {

    this.name = provinceBuilder.Name;
    this.code = provinceBuilder.Code;
    this.cities = provinceBuilder.Cities;

  }

  get Name() {
    return this.name;
  }

  get Code() {
    return this.code;
  }

  get Cities() {
    return this.cities;
  }

  addCity(city:City) {
    this.cities.push(city);
  }
}
