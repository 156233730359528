export class UserMultiActionDetailBuilder {
  private status: string;
  private delete: boolean;

  constructor(private uuidList: any) {
  }

  get UserUUIDList() {
    return this.uuidList;
  }

  get Status() {
    return this.status;
  }

  get isDelete() {
    return this.delete;
  }

  withStatus(status: string): UserMultiActionDetailBuilder {
    this.status = status;
    return this;
  }

  withDelete(deleteOption: boolean): UserMultiActionDetailBuilder {
    this.delete = deleteOption;
    return this;
  }

  build() {
    return new UserMultiActionDetail(this);
  }
}

export class UserMultiActionDetail {
  static readonly USER_UUID_LIST_FIELD_KEY = 'uuidList';
  static readonly STATUS_FIELD_KEY = 'status';
  static readonly DELETE_FIELD_KEY = 'delete';

  private readonly delete: boolean;
  private readonly status: string;
  private readonly uuidList: any;

  constructor(userMultiActionDetailBuilder: UserMultiActionDetailBuilder) {
    this.delete = userMultiActionDetailBuilder.isDelete;
    this.status = userMultiActionDetailBuilder.Status;
    this.uuidList = userMultiActionDetailBuilder.UserUUIDList;
  }

  get UserUUIDList() {
    return this.uuidList;
  }

  get Status() {
    return this.status;
  }

  get isDelete() {
    return this.delete;
  }
}
