export class CommunityMultiActionDetailBuilder {
  private active: boolean;
  private delete: boolean;

  constructor(private uuidList: any) {
  }

  get UUIDList() {
    return this.uuidList;
  }

  get isActive() {
    return this.active;
  }

  get isDelete() {
    return this.delete;
  }

  withActive(active: boolean): CommunityMultiActionDetailBuilder {
    this.active = active;
    return this;
  }

  withDelete(deleteOption: boolean): CommunityMultiActionDetailBuilder {
    this.delete = deleteOption;
    return this;
  }

  build() {
    return new CommunityMultiActionDetail(this);
  }
}

export class CommunityMultiActionDetail {
  static readonly COMMUNITY_UUID_LIST_FIELD_KEY = 'uuidList';
  static readonly ACTIVE_FIELD_KEY = 'active';
  static readonly DELETE_FIELD_KEY = 'delete';

  private readonly delete: boolean;
  private readonly active: boolean;
  private readonly uuidList: any;

  constructor(userMultiActionDetailBuilder: CommunityMultiActionDetailBuilder) {
    this.delete = userMultiActionDetailBuilder.isDelete;
    this.active = userMultiActionDetailBuilder.isActive;
    this.uuidList = userMultiActionDetailBuilder.UUIDList;
  }

  get UUIDList() {
    return this.uuidList;
  }

  get isActive() {
    return this.active;
  }

  get isDelete() {
    return this.delete;
  }
}
