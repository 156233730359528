import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CommunitiesReducers } from 'src/app/store/app.reducers';

const getCommunitiesState = createFeatureSelector<CommunitiesReducers.CommunitiesState>(CommunitiesReducers.TAG);

export const getData = createSelector(getCommunitiesState, (state) => state?.data);
export const getSearchInfo = createSelector(getCommunitiesState, (state) => state?.searchInfo);
export const getFilters = createSelector(getCommunitiesState, (state) => state?.filters);
export const getSort = createSelector(getCommunitiesState, (state) => state?.sort);
export const getPage = createSelector(getCommunitiesState, (state) => state?.page);
export const getBulk = createSelector(getCommunitiesState, (state) => state?.bulkData);
export const getBulkAction = createSelector(getCommunitiesState, (state) => state?.bulkAction);
