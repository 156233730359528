import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { createAction, props } from '@ngrx/store';
import { BulkActions } from 'src/app/models/Bulk';
import { Content } from 'src/app/models/Content';
import { SearchInfo } from 'src/app/models/search-info';
import { Structure } from 'src/app/models/Structure';
import { FiltersType, FiltersTypeValues } from '../../../enums';
import { FeatureValue } from 'src/app/models/FeatureValue';

export const setFilters = createAction('[Contents] Set Filters', props<{ filters: FiltersType }>());
export const setFiltersValues = createAction('[Contents] Set Filters', props<{ filters: FiltersTypeValues }>());
export const setSort = createAction('[Contents] Set Sort', props<{ sort: Sort }>());
export const setPage = createAction('[Contents] Set Page', props<{ page: PageEvent }>());
export const setSearchInfo = createAction('[Contents] Set Search Info', props<{ searchInfo: SearchInfo }>());
export const setData = createAction(
  '[Contents] Set Contents Data',
  props<{
    data: {
      result: Content[];
      paginationInfo?: PageEvent;
    };
  }>()
);
export const setFeatures = createAction('[Contents] Set Contents Features', props<{ features: Array<FeatureValue> }>());
export const setContentsStructures = createAction('[Contents] Set Contents Structures', props<{ structures: Array<Structure> }>());
export const setContentsBulkAction = createAction(
  '[Contents] toggle bulk action',
  props<{
    bulkAction: {
      bulkActionCustomization?: BulkActions;
      bulkAction?: BulkActions;
    };
  }>()
);
export const setContentsUUIDListBulk = createAction('[Contents] toggle bulk', props<{ bulkData: any }>());
