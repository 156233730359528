import { ActionReducerMap } from '@ngrx/store';
import { BulkEffects } from './components/grid-and-table-bulk-actions/store/bulk.effects';
import { MediaLibraryModalEffects } from './components/modal/media-library-explorer-multiple/store/media-library-modal.effects';
import { AuthEffects } from './pages/login/store/auth.effects';
import { MediaLibraryEffects } from './pages/media-library/store/media-library.effects';
import {
  AppContestsReducers,
  AppGroupsReducers,
  AppUsersReducers,
  AuthReducers,
  AvailabilityRulesReducers,
  BookableProductsReducers,
  BookableResourcesReducers,
  BulkReducers,
  CockpitReducers,
  CommunicationsReducers,
  CommunitiesReducers,
  CommunitySelectReducers,
  ContentsReducers,
  FeedbackReducers,
  IntegrationSelectReducers,
  LessonsReducers,
  LmsReducers,
  MediaLibraryModalReducers,
  MediaLibraryReducers,
  OrdersReducers,
  ProductsReducers,
  ReservationsReducers,
  ResourcesReducers,
  RolesReducers,
  SurveyReducers,
  TablesReducers,
  UsersReducers,
  VersionSelectionModalReducers,
  WorkFlowReducers,
} from './store/app.reducers';
import { CommunitySelectEffects } from './store/community-select/community-select.effects';
import { LessonsState } from './pages/learning-management-system/store/lesson-list/lessons-list.state';

export interface AppState {
  auth: AuthReducers.AuthState;
  products: ProductsReducers.ProductsState;
  contents: ContentsReducers.ContentsState;
  wishlist: ProductsReducers.WishlistState;
  communications: CommunicationsReducers.CommunicationsState;
  appUsers: AppUsersReducers.AppUsersState;
  appGroups: AppGroupsReducers.AppGroupsState;
  feedback: FeedbackReducers.FeedbackState;
  cockpit: CockpitReducers.CockpitState;
  bulk: BulkReducers.BulkState;
  mediaLibrary: MediaLibraryReducers.MediaLibraryState;
  mediaLibraryModal: MediaLibraryModalReducers.MediaLibraryState;
  appContests: AppContestsReducers.AppContestsState;
  survey: SurveyReducers.SurveyState;
  orders: OrdersReducers.OrdersState;
  tables: TablesReducers.TablesState;
  users: UsersReducers.UsersState;
  bookableResources: BookableResourcesReducers.BookableResourcesState;
  roles: RolesReducers.RolesState;
  communities: CommunitiesReducers.CommunitiesState;
  communitySelect: CommunitySelectReducers.CommunitySelectState;
  integrationSelect: IntegrationSelectReducers.IntegrationSelectState;
  resources: ResourcesReducers.ResourcesState;
  bookableProducts: BookableProductsReducers.BookableProductsState;
  availabilityRules: AvailabilityRulesReducers.AvailabilityRulesState;
  versionSelectionModal: VersionSelectionModalReducers.VersionSelectionState;
  reservations: ReservationsReducers.ReservationsState;
  workflow: WorkFlowReducers.WorkFlowState;
  lessons: LessonsState;
  lms: LmsReducers.LmsState
}

export const AppReducers: ActionReducerMap<AppState> = {
  products: ProductsReducers.productsReducer,
  contents: ContentsReducers.contentsReducer,
  wishlist: ProductsReducers.wishlistReducer,
  communications: CommunicationsReducers.communicationsReducer,
  appUsers: AppUsersReducers.appUsersReducer,
  appGroups: AppGroupsReducers.appGroupsReducer,
  feedback: FeedbackReducers.feedbackReducer,
  cockpit: CockpitReducers.cockpitReducer,
  auth: AuthReducers.AuthReducer,
  bulk: BulkReducers.BulkReducer,
  mediaLibrary: MediaLibraryReducers.mediaLibraryReducer,
  mediaLibraryModal: MediaLibraryModalReducers.mediaLibraryReducer,
  appContests: AppContestsReducers.appContestsReducer,
  survey: SurveyReducers.SurveyReducer,
  orders: OrdersReducers.ordersReducer,
  tables: TablesReducers.tablesReducer,
  users: UsersReducers.usersReducer,
  bookableResources: BookableResourcesReducers.bookableResourcesReducer,
  roles: RolesReducers.rolesReducer,
  communities: CommunitiesReducers.communitiesReducer,
  communitySelect: CommunitySelectReducers.communitySelectReducer,
  integrationSelect: IntegrationSelectReducers.IntegrationSelectReducer,
  resources: ResourcesReducers.resourcesReducer,
  bookableProducts: BookableProductsReducers.bookableProductsReducer,
  availabilityRules: AvailabilityRulesReducers.availabilityRulesReducer,
  versionSelectionModal: VersionSelectionModalReducers.versionSelectionReducer,
  reservations: ReservationsReducers.reservationsReducer,
  workflow: WorkFlowReducers.WorkFlowReducer,
  lessons: LessonsReducers.lessonReducer,
  lms: LmsReducers.lmsReducer
};
export const AppEffects = [AuthEffects, BulkEffects, MediaLibraryEffects, MediaLibraryModalEffects, CommunitySelectEffects];
