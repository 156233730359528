import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { Action, createReducer, on } from '@ngrx/store';
import DeepCopy from 'src/app/helpers/deep_copy';
import { AuthActions, CockpitActions } from 'src/app/store/app.actions';
import { FiltersType, FiltersVisibility, StructureType } from '../../../enums';
import { SearchInfo, Structure } from '../../../models';

export const COCKPIT_TAG = 'cockpit';

export interface CockpitState {
  filters: FiltersType;
  sort: Sort;
  page: PageEvent;
  searchInfo: SearchInfo;
  data: any;
  structures: Array<Structure>;
  loading: boolean;
}

const cockpitInitialState: CockpitState = {
  filters: {
    'structure.type': { visibility: FiltersVisibility.HIDDEN, value: StructureType.COCKPIT },
    structureUUID: { visibility: FiltersVisibility.VISIBLE, value: undefined },
    communityUUID: { visibility: FiltersVisibility.HIDDEN, value: undefined },
    communityAdminUUID: { visibility: FiltersVisibility.HIDDEN, value: undefined },
    active: { visibility: FiltersVisibility.VISIBLE, value: undefined },
  },
  sort: { active: 'publicationDate', direction: 'desc' },
  page: { pageIndex: 0, pageSize: 25, length: undefined },
  searchInfo: {
    searchFields: 'name',
    searchFieldsParamName: 'searchFields',
    searchValueParamName: 'q',
  } as SearchInfo,
  data: undefined,
  structures: [],
  loading: false,
};

const _cockpitReducer = createReducer(
  cockpitInitialState,
  on(CockpitActions.setFilters, (state: CockpitState, { filters }) => ({
    ...state,
    filters,
  })),
  on(CockpitActions.setFiltersValues, (state: CockpitState, { filters }) => ({
    ...state,
    filters: Object.entries(filters).reduce((acc, [key, value]) => {
      if (typeof acc[key] === 'object') {
        acc[key] = { ...acc[key], value } as FiltersType;
      } else {
        acc[key] = { value, visibility: acc[key] ?? FiltersVisibility.VISIBLE };
      }
      return acc;
    }, DeepCopy<FiltersType>(state.filters)),
  })),
  on(CockpitActions.updateFilters, (state: CockpitState, { extFilt }) => ({
    ...state,
    filters: {
      ...state.filters,
      ...extFilt,
    },
  })),
  on(CockpitActions.setSort, (state: CockpitState, { sort }) => ({
    ...state,
    sort,
  })),
  on(CockpitActions.setPage, (state: CockpitState, { page }) => ({
    ...state,
    page,
  })),
  on(CockpitActions.setSearchInfo, (state: CockpitState, { searchInfo }) => ({
    ...state,
    searchInfo,
  })),
  on(CockpitActions.setData, (state: CockpitState, { data, loadingComplete }) => ({
    ...state,
    data,
    loading: loadingComplete === true ? false : state.loading,
  })),
  on(CockpitActions.setStructures, (state: CockpitState, { structures }) => ({
    ...state,
    structures,
  })),
  on(CockpitActions.setLoading, (state, { loading }) => ({
    ...state,
    loading,
  })),
  on(AuthActions.logout, () => ({ ...cockpitInitialState }))
);

export const cockpitReducer = (state: CockpitState, action: Action) => {
  return _cockpitReducer(state, action);
};
