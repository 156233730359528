import { ObjectValuesType } from "../helpers/utilities";

export const FeatureType = {
  COMMUNICATION: 'communication',
  PRODUCT: 'product',
  MEDIA_LIBRARY: 'mediaLibrary',
  CONTENT: 'content',
  BOOKABLE_PRODUCT: 'bookableProduct',
  LMS: 'lms'
} as const;


export type FeatureType = ObjectValuesType<typeof FeatureType>;
