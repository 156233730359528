import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { EventInput } from '@fullcalendar/core';
import { Action, createReducer, on } from '@ngrx/store';
import { FiltersType, FiltersVisibility, StructureType } from '../../../../../enums';
import { SearchInfo } from '../../../../../models';
import { ReservationsActions, AuthActions } from '../../../../../store/app.actions';
import { CompleteReservation } from 'src/app/models/Reservations';

export const TAG = 'reservations';

export interface ReservationDateState {
  result: CompleteReservation[];
  paginationInfo: PageEvent;
}

export interface ReservationsState {
  showFilters: boolean;
  filters: FiltersType;
  sort: Sort;
  page: PageEvent;
  extFilt: any;
  searchInfo: SearchInfo;
  data: ReservationDateState;
  customizationData: any;
  events: EventInput[];
  gridView: boolean;
}

const initialState: ReservationsState = {
  showFilters: false,
  filters: {
    'structure.type': { visibility: FiltersVisibility.HIDDEN, value: StructureType.RESERVATION },
    structureUUID: { visibility: FiltersVisibility.HIDDEN, value: undefined },
    featureValue: { visibility: FiltersVisibility.HIDDEN, value: undefined },
    communityUUID: { visibility: FiltersVisibility.HIDDEN, value: undefined },
    communityAdminUUID: { visibility: FiltersVisibility.HIDDEN, value: undefined },
    template: { visibility: FiltersVisibility.HIDDEN, value: undefined },
    active: { visibility: FiltersVisibility.HIDDEN, value: undefined },
    status: { visibility: FiltersVisibility.VISIBLE, value: undefined },
    productId: { visibility: FiltersVisibility.VISIBLE, value: undefined },
    timezoneOffset: { visibility: FiltersVisibility.HIDDEN, value: new Date().getTimezoneOffset() },
    userId: { visibility: FiltersVisibility.VISIBLE, value: undefined },
  },
  sort: { active: 'updateDate', direction: 'desc' },
  page: { pageIndex: 0, pageSize: 25, length: 0 },
  extFilt: undefined,
  searchInfo: {
    searchFields: 'name',
    searchFieldsParamName: 'searchFields',
    searchValueParamName: 'q',
  } as SearchInfo,
  data: undefined,
  customizationData: undefined,
  events: [],
  gridView: false,
};

const _reducer = createReducer(
  initialState,
  on(ReservationsActions.setReservationFilters, (state: ReservationsState, { filters }) => ({
    ...state,
    filters,
  })),
  on(ReservationsActions.setReservationFiltersValues, (state: ReservationsState, { filters }) => ({
    ...state,
    filters: Object.entries(filters).reduce((acc, [key, value]) => {
      if (typeof acc[key] === 'object') {
        acc[key] = { ...acc[key], value } as FiltersType;
      } else {
        acc[key] = { value, visibility: initialState.filters?.[key]?.visibility ?? FiltersVisibility.HIDDEN };
      }
      return acc;
    }, structuredClone<FiltersType>(state.filters)),
  })),
  on(ReservationsActions.setSort, (state: ReservationsState, { sort }) => ({
    ...state,
    sort,
  })),
  on(ReservationsActions.setPage, (state: ReservationsState, { page }) => ({
    ...state,
    page,
  })),
  on(ReservationsActions.setExtFilt, (state: ReservationsState, { extFilt }) => ({
    ...state,
    extFilt,
  })),
  on(ReservationsActions.updateExtFilt, (state: ReservationsState, { extFilt }) => ({
    ...state,
    extFilt: { ...extFilt },
  })),
  on(ReservationsActions.setSearchInfo, (state: ReservationsState, { searchInfo }) => ({
    ...state,
    searchInfo,
  })),
  on(ReservationsActions.setData, (state: ReservationsState, { data }) => ({
    ...state,
    data,
  })),
  on(ReservationsActions.setReservationEvents, (state: ReservationsState, { events }) => ({
    ...state,
    events,
  })),
  on(ReservationsActions.setShowFiltersReservation, (state: ReservationsState, { show }) => ({
    ...state,
    showFilters: show,
  })),
  on(ReservationsActions.togglehowFiltersReservation, (state: ReservationsState) => ({
    ...state,
    showFilters: !state.showFilters,
  })),
  on(AuthActions.logout, () => ({ ...initialState }))
);

export function reservationsReducer(state: ReservationsState, action: Action) {
  return _reducer(state, action);
}
