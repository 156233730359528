export class AddressBuilder {
  private province: string;
  private provinceCode: string;
  private zipCode: string;
  private phoneNumber: string;
  private extra: string;

  constructor(private id: string, private userUUID: string, private name: string, private address: string, private town: string) {
  }

  get Id() {
    return this.id;
  }

  get UserUUID() {
    return this.userUUID;
  }

  get Name() {
    return this.name;
  }

  get Address() {
    return this.address;
  }

  get Town() {
    return this.town;
  }

  get Province() {
    return this.province;
  }

  get ProvinceCode() {
    return this.provinceCode;
  }

  get ZipCode() {
    return this.zipCode;
  }

  get PhoneNumber() {
    return this.phoneNumber;
  }

  get Extra() {
    return this.extra;
  }

  withProvince(province: string): AddressBuilder {
    this.province = province;
    return this;
  }

  withProvinceCode(provinceCode: string): AddressBuilder {
    this.provinceCode = provinceCode;
    return this;
  }

  withZipCode(zipCode: string): AddressBuilder {
    this.zipCode = zipCode;
    return this;
  }

  withPhoneNumber(phoneNumber: string): AddressBuilder {
    this.extra = phoneNumber;
    return this;
  }

  withExtra(extra: string): AddressBuilder {
    this.extra = extra;
    return this;
  }

  build() {
    return new Address(this);
  }
}

export class Address {
  static readonly UUID_FIELD_KEY = 'uuid';
  static readonly USER_UUID_FIELD_KEY = 'userUUID';
  static readonly NAME_FIELD_KEY = 'name';
  static readonly ADDRESS_FIELD_KEY = 'address';
  static readonly EXTRA_FIELD_KEY = 'extra';
  static readonly PROVINCE_FIELD_KEY = 'province';
  static readonly PROVINCECODE_FIELD_KEY = 'provinceCode';
  static readonly TOWN_FIELD_KEY = 'town';
  static readonly ZIPCODE_FIELD_KEY = 'zipCode';
  static readonly PHONENUMBER_FIELD_KEY = 'phoneNumber';

  private readonly id: string;
  private readonly userUUID: string;
  private readonly name: string;
  private readonly address: string;
  private readonly town: string;
  private readonly province: string;
  private readonly provinceCode: string;
  private readonly zipCode: string;
  private readonly phoneNumber: string;
  private readonly extra: string;

  constructor(addressBuilder: AddressBuilder) {
    this.id = addressBuilder.Id;
    this.userUUID = addressBuilder.UserUUID;
    this.name = addressBuilder.Name;
    this.address = addressBuilder.Address;
    this.town = addressBuilder.Town;
    this.province = addressBuilder.Province;
    this.provinceCode = addressBuilder.ProvinceCode;
    this.zipCode = addressBuilder.ZipCode;
    this.phoneNumber = addressBuilder.PhoneNumber;
    this.extra = addressBuilder.Extra;
  }

  get Id() {
    return this.id;
  }

  get UserUUID() {
    return this.userUUID;
  }

  get Name() {
    return this.name;
  }

  get Address() {
    return this.address;
  }

  get Town() {
    return this.town;
  }

  get Province() {
    return this.province;
  }

  get ProvinceCode() {
    return this.provinceCode;
  }

  get ZipCode() {
    return this.zipCode;
  }

  get PhoneNumber() {
    return this.phoneNumber;
  }

  get Extra() {
    return this.extra;
  }
}
