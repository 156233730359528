import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';
import { MediaLibraryState } from '../pages/media-library/store/media-library.reducer';
import { MediaLibraryModalSelectors, MediaLibrarySelectors } from '../store/app.selectors';

@Injectable({
  providedIn: 'root',
})
export class MediaLibraryService {
  public readonly PATH_DEPENDENT_PREFIX_FOLDER_NAME_SEPARATOR = '->';
  readonly uploadUrl = 'https://www.googleapis.com/upload/storage/v1/b/';
  readonly baseUrl = 'https://storage.googleapis.com/storage/v1/b/';

  breadcrumbsInfo = [];
  mediaType = '';

  constructor(private store: Store<MediaLibraryState>) {
    this.store.select(MediaLibrarySelectors.getBreadcrumbs).subscribe((breadcrumbs) => {
      this.breadcrumbsInfo = [...breadcrumbs];
    });
    this.store.select(MediaLibraryModalSelectors.getBreadcrumbs).subscribe((breadcrumbs) => {
      this.breadcrumbsInfo = [...breadcrumbs];
    });
  }

  cleanBreadcrumbs(action: TypedAction<any>) {
    this.store.dispatch(action);
  }

  getCompletePathFromBreadCrumb() {
    return this.breadcrumbsInfo.map((el) => el.label).join('/');
  }

  public getParentUUID() {
    return this.breadcrumbsInfo[this.breadcrumbsInfo.length - 1]?.parentUUID;
  }
}
