import { v4 as uuid } from 'uuid';
import { DataleanDataProviderService } from '../provider/datalean-data-provider.service';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { StructureField } from '../interfaces/structure-field';
import { StructureFieldTypes } from '../enums/structure-field-types.enum';
import { IsContainer } from '../helpers/structures';
import { BaseValues } from '../models/base-values';

@Injectable()
export abstract class BaseEditorService<T = any> {
  valuesUpdated = new BehaviorSubject(false);
  objectData: T & {
    values?: BaseValues[];
  };
  warnings: { fieldName: string; message: string }[] = [];
  supportsLanguages = false;
  protected activeLanguage: string;

  protected readonly ORDER_MAX_VALUE = 2147483647;

  uiProperties: Record<string, unknown> = {};

  constructor(protected dataleanDataService: DataleanDataProviderService) {}

  isJSON(stringToCheck: string) {
    try {
      const JSONString = typeof stringToCheck === 'string' ? JSON.parse(stringToCheck) : stringToCheck;
      if (typeof JSONString === 'object' || Array.isArray(JSONString)) return JSONString;
      if (typeof JSONString === 'string' && this.isJSON(JSONString)) return this.isJSON(JSONString);
    } catch (e) {}
    return false;
  }

  setUIProperty(key: string, value: Record<string, unknown>) {
    this.uiProperties[key] = value;
  }

  getUIProperty(key: string): unknown {
    return this.uiProperties[key];
  }

  resetUIProperties() {
    this.uiProperties = {};
  }

  protected isContainerOrAttachment(type) {
    return [StructureFieldTypes.CONTAINER, StructureFieldTypes.ATTACHMENT].includes(type);
  }

  removeUnnecessaryFields(structureFields: StructureField[]) {
    this.objectData.values = this.objectData?.values?.filter((value) => this.checkIfHasField(value, structureFields));
  }

  resolveAsset(item) {
    if (item) {
      if (typeof item === 'string') {
        if (!this.isJSON(item)) {
          return { uuid: item };
        }
        return { uuid: JSON.parse(item).uuid };
      }
      if (this.isJSON(item)) {
        return { uuid: item.uuid };
      }
    }
    return item;
  }

  private processResolveAsset(structureField: StructureField, toProcessElem: any) {
    if (structureField.type === StructureFieldTypes.FILE) {
      if (structureField.repeatable) {
        const arItems = [];
        for (const itemVal of toProcessElem) {
          arItems.push(this.resolveAsset(itemVal));
        }
        return arItems;
      }
      return this.resolveAsset(toProcessElem);
    }
    if (
      toProcessElem &&
      (structureField.type === StructureFieldTypes.CONTAINER ||
        structureField.type === StructureFieldTypes.ATTACHMENT ||
        structureField.type === StructureFieldTypes.IMAGE_MAP_CONTAINER)
    ) {
      if (!structureField.repeatable || !Array.isArray(toProcessElem)) {
        return this.mapFileFields(toProcessElem, structureField.fields);
      }
      for (let singleElem of toProcessElem) {
        singleElem = this.mapFileFields(singleElem, structureField.fields);
      }
    }
    return toProcessElem;
  }

  mapFileFields(item: T, structureFields: StructureField[]): T {
    for (const structureField of structureFields) {
      if (item[structureField.name]) {
        item[structureField.name] = this.processResolveAsset(structureField, item[structureField.name]);
      }
    }
    return item;
  }

  checkIfHasField(value, structureFields: StructureField[]) {
    return structureFields.find((structureField) => {
      const result = value.structureFieldUUID === structureField.uuid;
      if (!result && IsContainer(structureField.type)) {
        return this.checkIfHasField(value, structureField.fields);
      }
      return result;
    });
  }

  init?(param: any);

  generateUUID() {
    return uuid();
  }

  abstract create(param?): Observable<any>;

  abstract update(param?): Observable<any>;

  convertDate(dateString: string) {
    return this.dataleanDataService.convertDate(dateString);
  }

  addValue(value, structureField: StructureField, containerId = ''): BaseValues {
    if (!this.objectData?.values || !Array.isArray(this.objectData?.values)) {
      this.objectData.values = [];
    }
    const obj = {
      uuid: uuid(),
      value,
      structureFieldUUID: structureField.uuid,
      structureFieldName: structureField.name,
      structureFieldType: structureField.type,
      structureField,
      containerId,
    };

    this.objectData.values.push(obj);
    return obj as BaseValues;
  }

  deleteValue(valueUUID?: string) {
    if (this.objectData?.values?.length > 0) {
      let valueIndex = -1;
      if (valueUUID) {
        valueIndex = this.objectData.values.findIndex((el) => el.uuid === valueUUID);
      }
      if (valueIndex !== -1) {
        this.objectData.values.splice(valueIndex, 1);
      }
    }
  }

  addWarning(structureFieldName: string, message?: string) {
    if (this.warnings && this.warnings.findIndex((el) => el.fieldName === structureFieldName) === -1) {
      this.warnings.push({
        fieldName: structureFieldName,
        message: message,
      });
    }
  }

  deleteWarning(structureFieldName: string) {
    if (this.warnings?.length > 0) {
      const warningIndex = this.warnings.findIndex((el) => el.fieldName === structureFieldName);

      if (warningIndex > -1) {
        this.warnings.splice(warningIndex, 1);
      }
    }
  }
}
