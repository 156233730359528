import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ProductsReducers } from 'src/app/store/app.reducers';
import { FiltersTypeValues } from '../../../enums';

const getProductsState = createFeatureSelector<ProductsReducers.ProductsState>(ProductsReducers.TAG);
const getWishlistState = createFeatureSelector<ProductsReducers.WishlistState>(ProductsReducers.WISHLIST_TAG);

export const getProductsFilters = createSelector(getProductsState, (state) => state?.filters);
export const getProductsFiltersValues = createSelector(getProductsFilters, (filters) =>
  Object.entries(filters).reduce((acc, [key, value]) => ({ ...acc, [key]: value?.value }), {} as FiltersTypeValues)
);
export const getProductsSort = createSelector(getProductsState, (state) => state?.sort);
export const getProductsPage = createSelector(getProductsState, (state) => state?.page);
export const getProductsExtFilt = createSelector(getProductsState, (state) => state?.extFilt);
export const getProductsSearchInfo = createSelector(getProductsState, (state) => state?.searchInfo);
export const getProductsData = createSelector(getProductsState, (state) => state?.data);
export const getCustomizationData = createSelector(getProductsState, (state) => state?.customizationData);
export const getProductsFeatures = createSelector(getProductsState, (state) => state?.features);
export const getProductsStructures = createSelector(getProductsState, (state) => state?.structures);
export const getGridView = createSelector(getProductsState, (state) => state?.gridView);
export const getProductsUUIDList = createSelector(getProductsState, (state) => state?.bulkData);
export const getCustomizationUUIDList = createSelector(getProductsState, (state) => state?.bulkDataCustomization);
export const getBulkAction = createSelector(getProductsState, (state) => state?.bulkAction);
export const getBulkActionCustomization = createSelector(getProductsState, (state) => state?.bulkActionCustomization);
export const getTab = createSelector(getProductsState, (state) => state?.tab);
export const getProductsTabIndex = createSelector(getProductsState, (state) => state?.activeTabIndex);

export const getWishlistFilters = createSelector(getWishlistState, (state) => state?.filters);
export const getWishlistFiltersValues = createSelector(getWishlistState, (state) =>{
  return Object.entries(state?.filters ?? []).reduce((acc, [key, value]) => ({ ...acc, [key]: value?.value }), {} as FiltersTypeValues)
}
);
export const getWishlistSort = createSelector(getWishlistState, (state) => state?.sort);
export const getWishlistPage = createSelector(getWishlistState, (state) => state?.page);
export const getWishlistSearchInfo = createSelector(getWishlistState, (state) => state?.searchInfo);
export const getWishlistStructures = createSelector(getWishlistState, (state) => state?.structures);
export const getWishlistData = createSelector(getWishlistState, (state) => state?.data);
export const getWishUUIDList = createSelector(getWishlistState, (state) => state?.bulkData);
export const getWishBulkAction = createSelector(getWishlistState, (state) => state?.bulkAction);
