import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UploadService {
  progress = 0;
  totalSize = 0;
  partialSize = 0;

  reset() {
    this.progress = 0;
    this.totalSize = 0;
    this.partialSize = 0;
  }
}
