import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ContentsReducers } from 'src/app/store/app.reducers';
import { FiltersTypeValues } from '../../../enums';

const getContentsState = createFeatureSelector<ContentsReducers.ContentsState>(ContentsReducers.TAG);

export const getContentsFilters = createSelector(getContentsState, (state) => state?.filters);
export const getContentsFiltersValues = createSelector(getContentsState, (state) =>
  Object.entries(state?.filters ?? {}).reduce((acc, [key, value]) => ({ ...acc, [key]: value?.value }), {} as FiltersTypeValues)
);
export const getContentsSort = createSelector(getContentsState, (state) => state?.sort);
export const getContentsPage = createSelector(getContentsState, (state) => state?.page);
export const getContentsSearchInfo = createSelector(getContentsState, (state) => state?.searchInfo);
export const getContentsData = createSelector(getContentsState, (state) => state?.data);
export const getContentsFeatures = createSelector(getContentsState, (state) => state?.features);
export const getContentsStructures = createSelector(getContentsState, (state) => state?.structures);
export const getBulkAction = createSelector(getContentsState, (state) => state?.bulkAction);
export const getContentsUUIDListBulk = createSelector(getContentsState, (state) => state?.bulkData);
