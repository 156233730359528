import { ContestConfiguration } from "./ContestConfiguration"
import { DataleanEntity } from "./DataLeanEntity"
import { LocalizableField } from "./LocalizableField"
import { ScoreAction } from "./ScoreAction"

export class Contest extends DataleanEntity{

  //mantenere i campi all'interno del costruttore per poter permettere
  //a ParseEntity di funzionare (altrimenti i campi opzionali senza default non vengono rilevati)
  constructor(
    public name: LocalizableField = new LocalizableField(),
    public description: LocalizableField = new LocalizableField(),
    public limitScore = 0,
    public totalScore = 0,
    public deleted?: boolean,
    public configurations: Array<ContestConfiguration> = [],
    public scoreActions: Array<ScoreAction> = [],
    public targets: Array<unknown> = [],
    public communityUUID: string | null = null,
    public startDate: Date = new Date(),
    public endDate: Date = new Date(),
  ){
    super()
  }

}
