import { v4 } from 'uuid';
import { ResourceAssigningMethod, UnitFormat, UnitMode } from '../enums/bookable-products.enum';
import { EntityStatuses } from '../enums/entity-statuses.enum';
import { Days } from '../enums/resources.enum';
import { Asset } from '../models/Asset';
import { AvailabilityOption, AvailabilityRule } from './Resource';
import { Structure } from './Structure';
import { FeatureValue } from './FeatureValue';
import { ReservationTabEntity } from './RevervationTabEntity';

export class BookableProduct implements ReservationTabEntity {
  _id?: string;
  name: string;
  active: boolean;
  locale?: string;
  [key: string]: unknown;

  constructor(
    public uuid = v4(),
    public structureUUID: string = '',
    public image?: Partial<Asset>,
    public status: EntityStatuses = EntityStatuses.ACTIVE,
    public bookingOptions?: BookingOption,
    public bookingTerms?: BookingTerms,
    public featureValueList?: Array<string | FeatureValue>,
    public defaultAvailabilityOptions: AvailabilityOption[] = [],
    public availabilityRules: AvailabilityRule[] = [],
    public resourceAssigningMethod?: ResourceAssigningMethod,
    public resources?: BookableResource[],
    public structure?: Structure,
    public localizedValues: Array<{ locale: string; [key: string]: unknown }> = []
  ) {}
}

export interface BookingDuration {
  unitMode: UnitMode;
  unitQuantity: number;
  unitFormat: UnitFormat;
}

export interface BookableResource {
  quantity: number;
  resource: { uuid: string; name: string };
}

export class BookingOption {
  enableMaxBookingPerUser: boolean;
  maxBookingsPerUser?: number;
  duration: BookingDuration;
  defaultStartDate: string;
  defaultStartTime?: string | Date;
  availableStartDays?: (Days | null | boolean)[];
}

export interface TimeBlock {
  quantity: number;
  unitFormat: UnitFormat;
}

export class BookingTerms {
  maxBookings: number;
  deletable: boolean;
  requestConfirmation: boolean;
  preOrderRules: {
    min: TimeBlock;
    max: TimeBlock;
  };
  isPublic: boolean;
  bufferHours: number;
  incrementWithBuffer: boolean;
  incrementWithDuration: boolean;
  deletableTimeRange: TimeBlock;
}
