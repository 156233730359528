export enum SurveyQuestionType {
  /*SINGLE_CHOICE*/ singleChoice = 'singleChoice',
  /*MULTIPLE_CHOICE*/ multipleChoice = 'multipleChoice',
  /*TEXT*/  text = 'text',
  /*TEXT_AREA*/ textArea = 'textArea',
  /*DATE_TIME*/ dateTime = 'dateTime',
  /*IMAGE_PICKER*/  imagePicker = 'imagePicker',
  /*RATING_SCALE*/  ratingScale = 'ratingScale',
  /*MATRIX*/  matrix = 'matrix',
  /*SELECT*/  select = 'select',
  /*FILE*/  file = 'file',
  // /*REVIEW*/ review = 99
}

export class SurveyQuestionTypeUtil {
  private static codeMap = {
    '1': SurveyQuestionType.singleChoice,
    '2': SurveyQuestionType.multipleChoice,
    '3': SurveyQuestionType.text,
    '4': SurveyQuestionType.textArea,
    '5': SurveyQuestionType.dateTime,
    '6': SurveyQuestionType.imagePicker,
    '7': SurveyQuestionType.ratingScale,
    '8': SurveyQuestionType.matrix,
    '9': SurveyQuestionType.select,
    '10': SurveyQuestionType.file

  }
  static fromCode(surveyQuestionCode: number): SurveyQuestionType | string{
    if (surveyQuestionCode) {
      return this.codeMap[surveyQuestionCode.toString()];
    } else {
      return undefined;
    }
  }

  static getCode(surveyQuestionType: string): number {
    if (surveyQuestionType) {
      return Number(Object.entries(this.codeMap).find(([index, value]) => value === surveyQuestionType)[0]);
    } else {
      return undefined;
    }
  }
}
