import { v4 as uuid } from 'uuid';
import { DataleanDataProviderService } from '../provider/datalean-data-provider.service';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Parts } from '../enums';

// not an injectable because it is not meant to be reused
@Injectable()
export class TableEditorService {
  tableData;

  constructor(private dataleanDataService: DataleanDataProviderService) {}

  init() {
    this.tableData = {
      firstName: '',
      lastName: '',
      email: '',
      username: '',
      status: 'ENABLED',
      avatarImageURL: '',
      authenticationMethods: [],
      values: [],
      responsible: [],
      isPrivate: true,
      communityUUIDList: [],
      structureUUID: '',
    };
  }

  addUsersToTable(number: number, data) {
    const addTableSubject = new Subject();
    this.dataleanDataService
      .getEntity(environment.userStructureUrl, environment.tableStructureUUID, [Parts.STRUCTURE_FIELDS])
      .pipe(map((rawStructure) => this.dataleanDataService.mapStructure(rawStructure)))
      .subscribe((userStructure) => {
        const structureField = userStructure.structureFields.find((sf) => sf.name == 'enabledUser');
        if (structureField) {
          while (number > 0) {
            if (!data.values) data.values = [];
            data.values.push({
              structureFieldUUID: structureField.uuid,
              uuid: uuid(),
              value: '',
            });
            number = number - 1;
          }
          data.status = 'ENABLED';
          this.dataleanDataService.updateEntity(environment.tableUsersUrl, data, [Parts.ALL]).subscribe(() => {
            addTableSubject.next(true);
          });
        }
      });

    return addTableSubject.asObservable();
  }

  removeUsersFromTable(data) {
    const addTableSubject = new Subject();

    data.values = data.values.filter((value) => value.structureFieldName != 'enabledUser');
    data.status = 'DISABLED';
    this.dataleanDataService.updateEntity(environment.tableUsersUrl, data, [Parts.VALUES]).subscribe(() => {
      addTableSubject.next(true);
    });

    return addTableSubject.asObservable();
  }

  addTableToCommunity(tableName: string, communityUUID: string, communityName: string): Observable<any> {
    this.init();
    const addTableSubject = new Subject();

    this.dataleanDataService
      .getEntity(environment.userStructureUrl, environment.tableStructureUUID, [Parts.STRUCTURE_FIELDS])
      .pipe(map((rawStructure) => this.dataleanDataService.mapStructure(rawStructure)))
      .subscribe((userStructure) => {
        const typeStructureField = userStructure.structureFields.find((field) => field.name === 'type');
        const username = (tableName + '_' + communityName).replace(/ /g, () => '_').toLowerCase();
        const password = Math.random().toString(36).substring(2, 15);

        this.tableData.firstName = tableName;
        this.tableData.lastName = communityName;
        this.tableData.email = '';
        this.tableData.username = username;
        this.tableData.status = 'DISABLED';
        this.tableData.authenticationMethods = [
          {
            username: username,
            password: password,
            type: 'USERNAME_PASSWORD',
          },
        ];
        this.tableData.values = [
          {
            value: 'table',
            structureFieldUUID: typeStructureField.uuid,
          },
        ];
        this.tableData.communityUUIDList = [communityUUID];
        this.tableData.structureUUID = environment.tableStructureUUID;

        return this.create().subscribe(() => {
          addTableSubject.next(true);
        });
      });

    return addTableSubject.asObservable();
  }

  create(): Observable<any> {
    const parts: Parts[] = [Parts.COMMUNITY];
    if (this.tableData.values && this.tableData.values.length > 0) {
      parts.push(Parts.VALUES);
    }
    if (this.tableData.authenticationMethods && this.tableData.authenticationMethods.length > 0) {
      parts.push(Parts.AUTHENTICATION_METHODS);
    }

    return this.dataleanDataService.createEntity(environment.tableUsersUrl, this.tableData, parts);
  }

  update(): Observable<any> {
    return this.dataleanDataService.updateEntity(environment.tableUsersUrl, this.tableData, []);
  }
}
