import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from 'src/app/auth.service';

@Injectable({
  providedIn: 'root',
})
export class ReRouteGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(): Promise<boolean> | boolean {
    // console.log(this.authService.hasValidToken());
    if (this.authService.hasValidToken()) {
      return this.router.navigate(['']);
    }
    return true;
  }
}
