export interface ISortInfo {
  getCompleteSortInfo(): string;
  isActive(): boolean;
}
export class SortInfo implements ISortInfo {
  private sortBy = '';
  private sortDirection = '';

  constructor(sortBy: string, sortDirection?: string) {
    this.sortBy = sortBy;

    if (sortDirection) {
      this.sortDirection = sortDirection;
    }
  }

  get SortBy(): string {
    return this.sortBy;
  }
  set SortBy(value: string) {
    this.sortBy = value;
  }

  get SortDirection(): string {
    return this.sortDirection;
  }

  set SortDirection(value: string) {
    this.sortDirection = value;
  }

  get CompleteSortInfo() {
    if (this.SortDirection) {
      return this.SortBy + '#' + this.SortDirection;
    } else {
      return this.SortBy;
    }
  }

  getCompleteSortInfo(): string {
      return this.CompleteSortInfo;
  }

  isActive(): boolean {
      return this.sortBy != undefined;
  }

  getSortKey() {
    return this.sortBy;
  }
}

export class CombinedSortInfo implements ISortInfo {

  constructor(
    private sorts:Record<string, SortInfo> = {}
  ) {}

  private getAll(){
    return Object.entries(this.sorts).map(([key, sort]) => sort);
  }

  hasSort(sortKey:string){
    return !!this.sorts[sortKey];
  }

  addSort(sort:SortInfo){
    this.sorts[sort.getSortKey()] = sort;
  }
  /** Adds a sorting rule only if it doesn't already exist */
  coalesceSort(sortKey:string, sortDir: string) {
    if (!this.hasSort(sortKey))
      this.addSort(new SortInfo(sortKey, sortDir));
  }
  getCompleteSortInfo(): string {
    return this.getAll().filter(s => s.isActive()).map(s => s.getCompleteSortInfo()).join(',');
  }

  isActive(): boolean {
      return this.getAll().filter(s => s.isActive()).length > 0;
  }


}