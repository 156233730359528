import { v4 } from 'uuid';
import { EntityStatuses } from '../enums/entity-statuses.enum';
import { AvailabilityRuleType, Days } from '../enums/resources.enum';
import { ObjectValuesType } from '../helpers/utilities';
import { Asset } from './Asset';
import { Structure } from './Structure';
import { environment } from 'src/environments/environment';
import { ReservationTabEntity } from './RevervationTabEntity';

export class Resource implements ReservationTabEntity {
  _id?: string;
  name: string;
  description: string;
  locale? = environment.activeLanguage;
  [key: string]: unknown;

  constructor(
    public uuid = v4(),
    public status: ResourceStatus = EntityStatuses.ACTIVE,
    public quantity = 1,
    public structureUUID: string = '',
    public image: Partial<Asset> = undefined,
    public defaultAvailabilityOptions: Array<AvailabilityOption> = [],
    public availabilityRules: AvailabilityRule[] = [],
    public localizedValues: Array<{ locale: string; [key: string]: unknown }> = [],
    public structure?: Structure
  ) {}
}

export class AvailabilityRule {
  uuid = v4();
  name?: string;
  type: AvailabilityRuleType = AvailabilityRuleType.GENERIC;
  isActive = true;
  dates: TimeRange[] = [];
  availabilityOptions: AvailabilityOption[] = [];
}

export interface AvailabilityOption {
  day: Days | 'ALL';
  type: 'CAN_RESERVE' | 'CAN_NOT_RESERVE';
  hourControl: 'FULL_DAY' | 'CUSTOM';
  workshifts?: TimeRange[];
}

export interface TimeRange {
  from: Date | string;
  to: Date | string;
}

export type ResourceStatus = ObjectValuesType<Pick<typeof EntityStatuses, 'ACTIVE' | 'DISABLED'>>;
