import { Permissions } from '../interfaces/permissions';

export class Role {
  static readonly UUID_FIELD_KEY = 'uuid';
  static readonly NAME_FIELD_KEY = 'name';
  static readonly PERMISSION_KEY = 'permissions';
  audit;
  organizationUUID: string;

  constructor(public name: string = '', public permissions: Permissions[] = [], public uuid?: string, public availableLanguages?:string[]) {}
}
