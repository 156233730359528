import { Injectable, Injector, Type } from '@angular/core';
import { ComponentPortal} from '@angular/cdk/portal';
import { ModalComponent } from './modal.component';
import { ModalRef } from './modalRef';
import { Overlay, OverlayConfig, PositionStrategy, ScrollStrategy } from '@angular/cdk/overlay';

export interface ModalOptions {
  component: Type<any>;
  data?: any;
  class?: string;
  position?: PositionStrategy;
  scrolling?: ScrollStrategy;
  canExitThroughBackdropClick?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  constructor(private overlay: Overlay, private injector: Injector) {}

  open<R = any, T = any>(options: ModalOptions): ModalRef<R> {
    const configs = new OverlayConfig({
      hasBackdrop: true,
      panelClass: ['modal', 'is-active', options.class],
      backdropClass: ['modal-background'],
      disposeOnNavigation: true,
      positionStrategy: options.position,
      scrollStrategy: options.scrolling
    });

    const materialModalRef = this.overlay.create(configs);

    const modalRef = new ModalRef<R, T>(materialModalRef, options.component, options.data, options.canExitThroughBackdropClick);

    const injector = this.createInjector(modalRef, this.injector);
    materialModalRef.attach(
      new ComponentPortal(ModalComponent, null, injector)
    );

    return modalRef;
  }

  private createInjector(ref: ModalRef, inj: Injector) {
    return Injector.create({providers: [{provide: ModalRef, useValue: ref}], parent: inj});
  }

}


