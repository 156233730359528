import {FeatureValue} from "./FeatureValue";

export class Communication {
  static readonly IMAGE_FIELD_KEY = 'image';
  static readonly DATE_FIELD_KEY = 'date';
  static readonly TITLE_FIELD_KEY = 'title';
  static readonly TEXT_FIELD_KEY = 'text';
  static readonly ATTACHMENT_FIELD_KEY = 'text';
  static readonly CONFIRMATION_MESSAGE_FIELD_KEY = 'confirmation';
  static readonly GALLERY_FIELD_KEY = 'gallery';
  static readonly PHONE_FIELD_KEY = 'phone';
  static readonly EMAIL_FIELD_KEY = 'email';
  static readonly CONFIRMATION_FIELD_KEY = 'requestConfirmation';
  [key: string]: any;

  constructor(
    public uuid?: string, // FROM DataleanEntity
    public active?: boolean,
    public structureUUID = '',
    public featureValueList?: Array<string | FeatureValue>,
    public communityUUID?: string,
    public publicationDate?: Date | null,
    public expireDate?: Date | null,
    public pushNotification = false,
    public surveyUUID?: string,
    public isPublic?: boolean,
    public entityVersion?,  // = 1,
    public isLatestVersion?,  // = true,
    public status?: string,
    public structure?: {uuid: string, name: string, type: string},
    public updateDate?: string | Date, // FROM DataleanEntity
    public localizedValues?: Array<Partial<{ locale: string; name: string }>>,
  ) {}
}
